<template>
    <div>
        <div class="mod-dial" @click="PAYMENT_STATUS(false)">
            <div class="mod-cont mod-payment-status" @click.stop>
                <span class="close" @click="PAYMENT_STATUS(false)"><i class="bi bi-x-lg"></i></span>
                <div class="status-wrapper">
                    <!--success-->
                    <div class="status-circle success">
                        <span></span>
                    </div>
                    <span class="status-message">{{ $t('PAY_SUC') }}</span>
                    <p class="status-text">
                        {{ $t('PAY_SUC_TXT') }}
                    </p>
                    <button class="close-modal btn-st-tr">{{ $t('PAY_MAIN') }}</button>
                    <!--end success-->
                    <!--fail-->
                    <div class="status-circle fail">
                        <span></span>
                    </div>
                    <span class="status-message">{{ $t('PAY_FAIL') }}</span>
                    <p class="status-text">
                        <!--можно текст ошибки сюда-->
                        {{ $t('PAY_FAIL_TXT') }}
                    </p>
                    <button class="close-modal btn-st-tr">{{ $t('PAY_PRIC') }}</button>
                    <!--end fail-->
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { mapState, mapMutations } from "vuex";
import { PAYMENT_STATUS } from "../store/actions/pay";
export default {
  name: "PayStatus",
  mounted() {},
  methods: {
    ...mapMutations([PAYMENT_STATUS]),
  }
}
</script>
