<template>
  <div ref="top">
    <Dash-posts-feed-sort-panel :pageSocials="pageSocials" />
    <div class="one-page">
      <pagination :total="totalPostsPages" :callback="setPage"></pagination>

      <div class="panel">
        <ul
          class="posts"
          v-infinite-scroll="loadMore"
          :infinite-scroll-disabled="busy"
          :infinite-scroll-distance="10"
          :infinite-scroll-immediate-check="false"
          :infinite-scroll-listen-for-event="false"
        >
          <Dash-post-card
            :key="`${post.local_id}${page.owner_id}`"
            :post="post"
            :page="page"
            :index="i"
            v-for="(post, i) in paginationPosts"
          />
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import DashPostsFeedSortPanel from "./Dash-posts-feed-sort-panel";
import DashPostCard from "./Dash-posts-feed-card";
import Pagination from "../../../components/Pagination";
import infiniteScroll from "vue-infinite-scroll";
import { DASH_SET_PAGINATION_POSTS } from "../../../store/actions/dash";

export default {
  name: "Dash-posts-feed-one",
  components: {
    DashPostsFeedSortPanel,
    DashPostCard,
    Pagination,
  },
  directives: { infiniteScroll },

  computed: {
    ...mapGetters(["getAnalizedPages", "getPostsPageNum"]),
    posts() {
      return this.getAnalizedPages[0].posts;
    },
    page() {
      return this.getAnalizedPages[0];
    },
    totalPostsPages() {
      return Math.ceil(this.posts.length / 10);
    },
    paginationPosts() {
      return this.posts.slice(this.start, this.end);
    },
    pageSocials() {
      return this.getAnalizedPages.map((item) => ({
        social: item.social,
        isAdmin: item.is_admin,
      }));
    },
  },
  data() {
    return {
      currentPage: 1,
      busy: false,

      start: 0,
      end: 10,
    };
  },
  methods: {
    ...mapMutations([DASH_SET_PAGINATION_POSTS]),
    loadMore() {
      this.busy = true;
      this.end = this.end + 10;
      this.busy = false;
      this.DASH_SET_PAGINATION_POSTS(this.getPostsPageNum + 1);
    },
    setPage(num) {
      const el = this.$refs.top;
      el.scrollIntoView();

      this.$nextTick(() => {
        this.start = num * 10 - 10;
        this.end = num * 10;
      });
    },
  },
  beforeDestroy() {
    this.DASH_SET_PAGINATION_POSTS(1);
  },
};
</script>

<style>
</style>