<template>
  <li>
    <div class="post-body">
      <div class="image" v-if="post.social === 'FB'">
        <iframe
          v-if="post.hasVideo"
          :src="
            'https://www.facebook.com/plugins/video.php?href=' +
            post.attach[0].video_url +
            '&amp;width=500&amp;show_text=false&amp;appId=227160864129868&amp;height=281'
          "
          width="100%"
          height="auto"
          style="border: none; overflow: hidden"
          scrolling="no"
          frameborder="0"
          allowtransparency="true"
        ></iframe>

          <collage
            :items="imageAttach"
            :className="'paper-collage'"
            :padding="5"
            :tuid="randomHash"
            :befLoad="onBeforeLoad(post)"
            v-if="imageAttach.length >= 1"
          />
      </div>      
      <div class="image" v-if="post.social === 'VK'">
        <collage
          :items="imageAttach"
          :className="'paper-collage'"
          :padding="5"
          :tuid="randomHash"
          :befLoad="onBeforeLoad(post)"
          v-if="imageAttach.length >= 1"
        />

        <iframe v-if="videoAttach.length == 1" :src="`https://vk.com/video_ext.php?oid=${videoAttach[0].owner_id}&id=${videoAttach[0].video_id}&hd=2`" width="853" height="480" allow="autoplay; encrypted-media; fullscreen; picture-in-picture;" frameborder="0" allowfullscreen></iframe>
      </div>
      <div class="image" v-if="post.social === 'IG'">
        <collage
          :items="imageAttach"
          :className="'paper-collage'"
          :padding="5"
          :tuid="randomHash"
          :befLoad="onBeforeLoad(post)"
          v-if="imageAttach.length >= 1"
        />
      </div>
      <div class="text">
        <a class="date" :href="post.link" target="_blank">
          {{ getTime }}
          <mod>{{ getDate }}</mod>
        </a>
        <p class="post-text">
          {{ showText }}
          <span
            class="more"
            v-if="post.textCanShowMore && !getPostTextShowMap[post.post_id]"
            @click="DASH_POST_SHOW_TEXT(post.post_id)"
            >{{ $t('POST_MORE') }}</span
          >
          <span
            class="more"
            v-if="post.textCanShowMore && getPostTextShowMap[post.post_id]"
            @click="DASH_POST_SHOW_TEXT(post.post_id)"
            >{{ $t('POST_LESS') }}</span
          >
        </p>
      </div>
    </div>
    
    <div class="m-tech">
      <div class="reactions">
        <div class="react-metric"
          :key="`${value}${post.local_id / 100}${post.page_id}${key}`"
          v-for="(value, key) in post.formatedStats.total"
        >
          <span>
            <i :class="`bi ${reactionIcons[key]}`"></i>
          </span>

          {{ value }}
        </div>
      </div>

      <div class="engagement">
        <div class="react-metric"
          :key="`${post.local_id}${value}${key}`"
          v-for="(value, key) in post.formatedStats.engagement"
        >
          <span>{{ $t("POSTS_FEED_" + key.toUpperCase()) }}</span>
          {{ value }}
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
// import "@/models/jquery.collagePlus";
import fancybox from "@fancyapps/fancybox";
import "@fancyapps/fancybox/dist/jquery.fancybox.css";

import Collage from "./Collage";

import "glightbox/dist/css/glightbox.css";

import {
  DASH_UPDATE_ATTACHES,
  DASH_POST_SHOW_TEXT,
} from "@/store/actions/dash";

export default {
  name: "Dash-post-card",
  components: {
    Collage,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    page: {
      type: Object,
      required: true,
    },
    post: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["getPostTextShowMap"]),
    showText() {
      if (this.post.hasText) {
        if (
          this.post.textCanShowMore &&
          this.getPostTextShowMap[this.post.post_id]
        )
          return this.post.text;
        else if (
          this.post.textCanShowMore &&
          !this.getPostTextShowMap[this.post.post_id]
        )
          return this.post.small_text;
        else return this.post.text;
      } else {
        return "";
      }
    },
    imageAttach() {
      return this.post?.attach?.filter((item) => item.type == "photo") || [];
    },
    videoAttach() {
      return this.post?.attach?.filter((item) => item.type == "video") || [];
    },
    getTime() {
      return `${this.post.date.getHours()}:${
        (this.post.date.getMinutes() < 10 ? "0" : "") +
        this.post.date.getMinutes()
      }`;
    },
    getDate() {
      return this.moment(this.post.date).format("DD/MM/YYYY");
    },
    reactionIcons() {
      return {
        likes: "bi-heart",
        shares: "bi-arrow-return-left",
        comments: "bi-chat-right-dots",
        views: "bi-eye",
        er: "bi-heart",
        er_views: "bi-heart",
        ir: "bi-heart",
      };
    },
    randomHash() {
      return Math.random().toString(36).substr(2, 8);
    },
  },
  methods: {
    ...mapActions([DASH_UPDATE_ATTACHES]),
    ...mapMutations([DASH_POST_SHOW_TEXT]),
    async onError(event, post, attachNum) {
      event.target.src = await this.DASH_UPDATE_ATTACHES({ post, attachNum });
    },
    onBeforeLoad: (post, slide) => {
      // if (!slide) return;
      // debugger
      // const attach = post.attaches[slide.index];
      // slide.slideConfig.href = attach.url;
    }

  },
};
</script>
