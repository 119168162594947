// columnsDirective.js
import Columns from 'columns.js';
import _ from 'lodash'; // Assuming lodash is available for debouncing

export default {
  inserted(el, binding) {
    const options = binding.value || {};
    Object.assign(options, { algorithm: 'chronological' });
    el.grid = new Columns(el, options);

    el._handleResize = _.debounce(() => {
      if (el.grid) {
        el.grid.render();
      }
    }, 100); // Adjust the debounce delay as necessary

    window.addEventListener('resize', el._handleResize);
  },
  unbind(el) {
    if (el._handleResize) {
      window.removeEventListener('resize', el._handleResize);
    }

    if (el.grid && typeof el.grid.dispose === 'function') {
      el.grid.dispose();
    }
    el.grid = null;
  }
};