import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import intro from './modules/intro'
import reports from './modules/reports'
import admin from './modules/admin'
import dash from './modules/dash'
import utils from '../models/page-utils'
import _ from 'lodash'


Vuex.Store.prototype.$Axios = utils.Axios

Vue.use(Vuex)
const debug = process.env.NODE_ENV !== 'production'

export const initialStoreModules = {
  auth,
  dash,
  intro,
  reports,
  admin
};

export default new Vuex.Store({
  modules: _.cloneDeep(initialStoreModules),
  mutations: {
    // reset default state modules by looping around the initialStoreModules
    RESET_STORE(state) {
      _.forOwn(initialStoreModules, (value, key) => {
        state[key] = _.cloneDeep(value.state);
        if (key == 'auth') state.auth.token = ''
      });
    },
  },
  strict: debug
})