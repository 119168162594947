import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { stylingTooltip } from "../chart-utils";
//import am4themes_animated from "@amcharts/amcharts4/themes/animated";
// am4core.useTheme(am4themes_animated);

import i18n from '@/i18nSetup';

const localeMap = {
    "likes": "ALL_LIKES",
    "reposts": "ALL_REPOSTS",
    "comments": "ALL_COMMENTS",
    "views": "ALL_VIEWS",
    "posts": "ALL_RECORDS",
    "stories": 'STORIES',
    "interactions": "INTERACTIONS",

}

const agregate = (pages, names, locale) => {
    const data = []

    for (const name of names) {
        const value = {}
        for (const [index, page] of pages.entries()) {
            if (page.stats.total[name]) {
                value['value' + index] = parseInt(page.stats.total[name].value.replace(/\s+/g, ""))
            }
        }
        // 
        let arr = Object.values(value);
        let max = Math.max(...arr);
        for (let key of Object.keys(value)) {
            value[key + '_percent'] = value[key] / max;
        }
        value.category = i18n.t('STATS_TABLE_TOTAL_' + localeMap[name]);
        data.push(value);
    }
    return data.reverse()

}



const create = ({ pages, ref, names, data, locale }) => {
    return new Promise((resolve, rejects) => {
        // if (!ref) return

        // Create chart instance
        const chart = am4core.create(ref, am4charts.XYChart);
        chart.paddingTop = 0
        chart.paddingLeft = 0
        chart.paddingRight = 0



        let category, value
        if (names) {
            chart.data = agregate(pages, names, locale);
            category = 'category'
            value = (index) => `value${index}_percent`
            // pages = pages.filter(item => Object.keys(item.stats.total).length)
        } else {
            chart.data = data
            category = 'age'
            value = (index) => `proc_${index}`
        }
        // 

        let innerTooltipHtml = '';

        pages.forEach((page, index) => {
            if (names) innerTooltipHtml += `<li data-value="{${value(index)}}">
          <div class="soc-sea ${page.colorName}" >
            <span class="soc-ico soc-${page.social.toLowerCase()}"></span>
            ${page.owner_name_medium}
          </div>{value${index}}</li>`
            else innerTooltipHtml += `<li data-value="{${value(index)}}">
          <div class="soc-sea ${page.colorName
                }"><span class="soc-ico soc-${page.social.toLowerCase()}"></span>${page.owner_name_medium}</div>{proc_${index}}% ({value_${index}})
      </li>`;
        })



        chart.colors.list = [];
        for (const [index, page] of pages.entries()) {
            chart.colors.list.push(am4core.color(page.colorCode));
        }
        const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = category;
        categoryAxis.renderer.grid.template.location = 0;
        // categoryAxis.renderer.minGridDistance = 20;
        categoryAxis.renderer.labels.template.disabled = true;
        categoryAxis.renderer.grid.template.disabled = true;

        // categoryAxis.cursorTooltipEnabled = false;
        // categoryAxis.tooltip.disabled = true
        categoryAxis.renderer.cellStartLocation = 0.2;
        categoryAxis.renderer.cellEndLocation = 0.8;
        categoryAxis.renderer.labels.template.events.on("over", function (ev) {
            var point = categoryAxis.categoryToPoint(ev.target.dataItem.category);
            chart.cursor.triggerMove(point, "soft");
        });

        categoryAxis.renderer.labels.template.events.on("out", function (ev) {
            var point = categoryAxis.categoryToPoint(ev.target.dataItem.category);
            chart.cursor.triggerMove(point, "none");
        });
        categoryAxis.cursorTooltipEnabled = false;


        const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.grid.template.disabled = false;
        valueAxis.renderer.grid.template.strokeDasharray= "1,8";
        valueAxis.renderer.labels.template.disabled = true
        valueAxis.tooltip.disabled = true;
        valueAxis.cursorTooltipEnabled = false;
        valueAxis.calculateTotals = true
        valueAxis.renderer.grid.template.strokeOpacity = 0;
        valueAxis.renderer.baseGrid.strokeOpacity = 0;
        valueAxis.renderer.labels.template.fontSize = 8
        valueAxis.renderer.labels.template.fontWeight = '700'


        let series
        // const x = pages.filter(item => Object.keys(item.stats.total).length)
        pages.forEach((page, index) => {
            series = chart.series.push(new am4charts.ColumnSeries());
            // if (Object)
            series.tooltip.getFillFromObject = false;
            series.dataFields.categoryY = category
            series.dataFields.valueX = value(index)
            //   series.dataFields.valueXShow = 'totalPercent';
            series.name = index
            series.stacked = false;
            const columnTemplate = series.columns.template;
            columnTemplate.height = 5
            columnTemplate.column.cornerRadius(3, 3, 3, 3);

            columnTemplate.column.padding(1, 0, 1, 0)

            columnTemplate.strokeOpacity = 0;
            columnTemplate.fill = am4core.color(page.colorCode);

            series.tooltip.animationDuration = 400;
            series.tooltip.defaultState.transitionDuration = 400;
            series.tooltip.hiddenState.transitionDuration = 400;

        })


    
        chart.series.each((series) => {
            stylingTooltip(series)

            series.adapter.add("tooltipHTML", (tooltipHTML, target) => {
              if (target.tooltipDataItem && target.tooltipDataItem.dataContext) {
                const { category, age, ...values } = target.tooltipDataItem.dataContext
                if (Object.entries(values).length == 2) {
                    
                  tooltipHTML = "<div class='tooltip'><span class='date'>{categoryY}</span><ul class='tooltip-list'>" +
                  innerTooltipHtml +
                  "</ul></div>";
                  return tooltipHTML
                } else if (Object.entries(values).length > 2) {
                  const firstKey = Object.keys(values).filter(key => key.startsWith('value')).sort()[0]
                  
                  if (`value${target.name}` === firstKey || `value_${target.name}` === firstKey) {
                    tooltipHTML = "<div class='tooltip'><span class='date'>{categoryY}</span><ul class='tooltip-list'>" +
                    innerTooltipHtml +
                    "</ul></div>";
                  } 
                  return tooltipHTML 
                }
              }
  
              return tooltipHTML;
            });
          });
        am4core.options.autoSetClassName = true;
        am4core.options.classNamePrefix = "lucmus-";
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.yAxis = categoryAxis;
        chart.cursor.fullWidthLineY = true;
        chart.cursor.lineY.strokeWidth = 0;
        chart.cursor.lineY.fill = am4core.color("#F6F8FB");
        chart.cursor.lineY.fillOpacity = 1;
        // chart.cursor.lineY.disabled = true;
        chart.cursor.lineX.disabled = true;
        chart.cursor.zIndex = -1;
        
        chart.cursor.id = "myCursor";

        // Customize series zIndex if needed to ensure they are above the cursor
        chart.series.each(function(series) {
          series.zIndex = 0;  // Adjust this value if needed
        });
        chart.cursor.events.on("cursorpositionchanged", function (ev) {
            chart.cursor.triggerMove(ev.target.point, "soft");
        });
        chart.events.on("ready", () => {
            resolve(chart)
        });

    })


}

export default create;