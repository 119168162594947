<template>
  <div class="dashboard-template">
    <Header :showInput="false" />
    <Dash-body />
    <Footer v-if="getSelectedPages.length > 1" />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import DashBody from "./Dash-body";
import {
  DASH_LOAD_DATA_ACTION,
  DASH_CLEAR_STATE,
} from "../../store/actions/dash";
import { AUTH_SOCIAL_TOKENS } from "@/store/actions/auth";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { DASH_SET_ACTIVE_TAB_ACTION } from "@/store/actions/dash";

export default {
  name: "Dashboard",
  components: {
    Header,
    DashBody,
    Footer,
  },
  computed: {
    ...mapGetters([
      "getSelectedPages",
      "getAnalizedAdminPages",
      "getActiveTab",
      "getPosts",
    ]),
  },
  methods: {
    ...mapMutations([DASH_CLEAR_STATE]),
    ...mapActions([
      AUTH_SOCIAL_TOKENS,
      DASH_LOAD_DATA_ACTION,
      DASH_SET_ACTIVE_TAB_ACTION,
    ]),
  },
  watch: {
    getAnalizedAdminPages(newState, oldState) {
      if (oldState.length != 0 && newState.length === 0) {
        if (
          this.getActiveTab === "Reach" ||
          this.getActiveTab === "Audience" ||
          this.getActiveTab === "Stories Feed"
        ) {
          this.DASH_SET_ACTIVE_TAB_ACTION("Overview");
        }
      }
    },
    getPosts(newState, oldState) {
      const isByPostsTab =
        this.getActiveTab === "Activity" ||
        this.getActiveTab === "Posts stats" ||
        this.getActiveTab === "Reach" ||
        this.getActiveTab === "Posts Feed";

      if (newState.length === 0 && isByPostsTab) {
        this.DASH_SET_ACTIVE_TAB_ACTION("Overview");
      }
    },
  },

  data() {
    return {};
  },
  mounted() {
    setTimeout(() => {
      this.DASH_LOAD_DATA_ACTION();
    }, 0);
  },
  beforeDestroy() {
    this.DASH_CLEAR_STATE();
  },
};
</script>

<style>
</style>