export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_GOOGLE = 'AUTH_GOOGLE'
export const AUTH_REGISTER = 'AUTH_REGISTER'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_FORGOT = 'AUTH_FORGOT'
export const AUTH_CHANGEPASS = 'AUTH_CHANGEPASS'
export const AUTH_REQUEST_COMPLETE = 'AUTH_REQUEST_COMPLETE'
export const AUTH_SOCIAL_TOKENS = 'AUTH_SOCIAL_TOKENS'
export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN'
export const AUTH_REMOVE_TOKEN = 'AUTH_REMOVE_TOKEN'
export const AUTH_ACTIVATE = 'AUTH_ACTIVATE'