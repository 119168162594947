<template>
  <div>
    <!-- <scroll-loader class="dash-loader" :loader-method="()=> {}" :loader-enable="!loaded"></scroll-loader> -->
    <div v-columns="{ columns: 1, breakpoints: { 780: 2 } }" class="audience columns-list">
      <div class="panel">
        <h2>{{ $t("DASHBOARD_AUDIENCE_CHARTS_COUNTRIES") }}</h2>
        <div class="countries">
          <div
            class="one-country"
            :key="index"
            v-for="(country, index) in topCountries"
          >
            <div class="m-chart">
              <div class="chart" :ref="`country${index}`"></div>
            </div>
            <span class="country-code" :title="country.name">{{
              country.name
            }}</span>
          </div>
        </div>
      </div>
      <div class="panel" v-if="filtrateByAgesCharts.VK.length">
        <div class="aud-header">
          <h2>{{ $t("DASHBOARD_AUDIENCE_CHARTS_AGES") }}</h2>
          <div
            :class="`soc-sea ${filtrateByAgesCharts.VK[0].colorName}`"
            v-if="filtrateByAgesCharts.VK.length == 1"
          >
            <span
              :class="`soc-ico soc-${filtrateByAgesCharts.VK[0].social.toLowerCase()}`"
            ></span>
            {{ filtrateByAgesCharts.VK[0].owner_name_medium }}
          </div>
          <div class="settings">
            <button
              @click="setByAgeSelector(type)"
              :class="isActiveByAgeClass(type)"
              :key="index"
              v-for="(type, index) in typesList"
            >
              {{ type }}
            </button>
          </div>
        </div>
        <div class="cont">
          <div class="metrics-list">
            <div>to 18</div>
            <div>18-21</div>
            <div>21-24</div>
            <div>24-27</div>
            <div>27-30</div>
            <div>30-35</div>
            <div>35-45</div>
            <div>45+</div>
          </div>
          <div class="m-chart">
            <div
              class="chart"
              :ref="`agesVK`"
              style="width: 100%; height: 400px"
            ></div>
          </div>
        </div>
      </div>
      <div class="panel" v-if="filtrateByAgesCharts.IGFB.length">
        <div class="aud-header">
          <h2>{{ $t("DASHBOARD_AUDIENCE_CHARTS_AGES") }}</h2>
          <div
            :class="`soc-sea ${filtrateByAgesCharts.IGFB[0].colorName}`"
            v-if="filtrateByAgesCharts.IGFB.length == 1"
          >
            <span
              :class="`soc-ico soc-${filtrateByAgesCharts.IGFB[0].social.toLowerCase()}`"
            ></span>
            {{ filtrateByAgesCharts.IGFB[0].owner_name_medium }}
          </div>
          <div class="settings">
            <button
              @click="setByAgeSelector(type)"
              :class="isActiveByAgeClass(type)"
              :key="index"
              v-for="(type, index) in typesList"
            >
              {{ type }}
            </button>
          </div>
        </div>
        <div class="cont">
          <div class="metrics-list">
            <div>13-17</div>
            <div>18-24</div>
            <div>25-34</div>
            <div>35-44</div>
            <div>45-54</div>
            <div>55-64</div>
            <div>65+</div>
          </div>
          <div class="m-chart">
            <div
              class="chart"
              :ref="`agesIGFB`"
              style="width: 100%; height: 350px"
            ></div>
          </div>
        </div>
      </div>
      <div
        class="panel heatmap"
        :key="index"
        v-for="(page, index) in filtrateByOnlineCharts"
      >
        <div class="aud-header">
          <h2>{{ $t("DASHBOARD_AUDIENCE_CHARTS_ONLINE") }}</h2>
          <div :class="`soc-sea ${page.colorName}`">
            <span :class="`soc-ico soc-${page.social.toLowerCase()}`"></span>
            {{ page.owner_name_medium }}
          </div>
        </div>
        <div class="m-chart">
          <div class="chart" :ref="`online${index}`"></div>
          <div
            class="heat-legend"
            :ref="`online-legend${index}`"
            :data-color-code="page.colorCode"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
import { mapGetters, mapMutations, mapActions } from "vuex";
import pageCharts from "@/models/page-charts";
import HeatMap from "@/models/charts/heatMap";
import gorizontalColumns from "@/models/charts/gorizontal-columns";
import RadarOne from "@/models/charts/radar-countries";
import * as am4core from "@amcharts/amcharts4/core";
import columnsDirective from "@/directives/columnsDirective.js";

export default {
  name: "Dash-audience-many",
  components: {},
  directives: {
    columns: columnsDirective
  },    
  computed: {
    ...mapGetters([
      "getAnalizedPages",
      "getUnionPagesData",
      "getSocialOfAdminPageInAnalize",
      "getAnalizedAdminPages",
    ]),
    locale() {
      return this.$locale.DASHBOARD.AUDIENCE_CHARTS;
    },
    filtrateByCountriesCharts() {
      return this.getAnalizedPages.filter(
        (page) => page.is_admin && page.audienceStats.countries
      );
    },
    filtrateByOnlineCharts() {
      return this.getAnalizedPages.filter(
        (page) =>
          page.social != "VK" &&
          page.is_admin &&
          page.audienceStats.online_followers
      );
    },
    filtrateByAgesCharts() {
      return this.getAnalizedPages.reduce(
        (acc, page) => {
          const isVkAdminPageHasAgeStats =
            page.social == "VK" && page.is_admin && page.audienceStats.sex_age;
          const isIgFbAdminPageHasAgeStats =
            (page.social == "IG" || page.social == "FB") &&
            page.is_admin &&
            page.audienceStats.page_fans_gender_age;
          if (isVkAdminPageHasAgeStats) acc.VK.push(page);
          else if (isIgFbAdminPageHasAgeStats) acc.IGFB.push(page);
          return acc;
        },
        { VK: [], IGFB: [] }
      );
    },
    topCountries() {
      const pages = this.getAnalizedAdminPages.filter(
        (page) => page.audienceStats.countries
      );
      const data = pageCharts.countries(pages).map((d) => {
        if (d.code == "RU") return { ...d, name: "Russia" };
        if (d.code == "US") return { ...d, name: "USA" };
        if (d.code == "A1") return { ...d, name: "Anonymous" };
        if (d.code == "EU") return { ...d, name: "European Union" };

        const name = countries.getName(d.code, "en");
        if (name.length > 10) return { ...d, name: name.slice(0, 10) + "..." };
        return { ...d, name };
      });
      return data;
    },
  },
  data() {
    return {
      loaded: false,
      byCountrySelector: "All",
      byAgeSelector: "All",
      typesList: ["All", "Men", "Women"],
      charts: {
        countries: [],
        ages: [],
        online: [],
      },
    };
  },
  methods: {
    isActiveByCountryClass(type) {
      if (this.byCountrySelector == type) return "active";
      return "";
    },
    setByCountrySelector(type) {
      this.byCountrySelector = type;
    },
    isActiveByAgeClass(type) {
      if (this.byAgeSelector == type) return "active";
      return "";
    },
    setByAgeSelector(type) {
      this.byAgeSelector = type;
      setTimeout(this.renderAgesCharts, 0);
    },
    async renderRadarCountriesCharts() {
      const pages = this.getAnalizedAdminPages;
      const data = pageCharts.countries(pages);
    },
    async renderOnlineCharts() {
      for (const [index, page] of this.filtrateByOnlineCharts.entries()) {
        const chartRef = this.$refs[`online${index}`][0];
        const legendRef = chartRef.nextSibling;

        await HeatMap({
          ref: chartRef,
          page: page,
          refLegend: legendRef,
        });
      }
    },
    async renderAgesCharts() {
      const { pages, datesRangePosts } = this.getUnionPagesData;
      if (this.charts.ages.length)
        this.charts.ages.map((chart) => chart.dispose());
      const page = pages[0];

      const { VK, IGFB } = this.filtrateByAgesCharts;
      if (VK.length) {
        const data = pageCharts.ages(VK, this.byAgeSelector);
        this.charts.ages.push(
          await gorizontalColumns({
            ref: this.$refs.agesVK,
            data,
            pages: VK,
          })
        );
      }
      if (IGFB.length) {
        const data = pageCharts.ages(IGFB, this.byAgeSelector);
        this.charts.ages.push(
          await gorizontalColumns({
            ref: this.$refs.agesIGFB,
            data,
            pages: IGFB,
          })
        );
      }
    },
    async renderRadarCountriesCharts() {
      const pages = this.getAnalizedAdminPages.filter(
        (page) => page.audienceStats.countries
      );

      const calculateTotal = this.topCountries.reduce((acc, val) => {
        pages.forEach((_, i) => {
          acc[`count_${i}`]
            ? (acc[`count_${i}`] += val[`count_${i}`])
            : (acc[`count_${i}`] = val[`count_${i}`]);
        });
        return acc;
      }, {});

      const chartPromises = this.topCountries.map((country, index) => 
        RadarOne({
          ref: this.$refs[`country${index}`],
          maxValue: calculateTotal,
          country,
          pages,
        })
      );

      this.charts.countries = await Promise.all(chartPromises);
    },
    async renderCharts() {
      await Promise.all([
        this.renderAgesCharts(),
        this.renderOnlineCharts(),
        this.renderRadarCountriesCharts(),
      ]);
    },
  },
  mounted() {
    setTimeout(this.renderCharts, 0);
  },
  beforeDestroy() {
    setTimeout(am4core.disposeAllCharts, 0);
  },
};
</script>

<style></style>
