<template>
  <div>
    <div class="header">
      <div class="logo">
        <img src="assets/images/Logo_ze.svg" alt="" />
      </div>
      <span class="dev"></span>
      <div class="search-panel">
        <input
          type="text"
          class="search-panel"
          placeholder="Search..."
          size="auto"
        />
      </div>
      <div class="profile-panel">
        <div class="drpdown">
          <input type="checkbox" name="" id="z1" />
          <label for="z1">michael@mail.com</label>
          <span class="expand"></span>
          <ul>
            <li>Access rights manager</li>
            <li>Manage reports</li>
            <li>Log out</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="pricing_block">
      <div class="container">
        <div class="pricing_header">
          <h2 class="header_sublvl">{{ $t('PRC_HEADER') }}</h2>
          <div class="types_change">
            <span class="active">
              {{ $t('PRC_YEAR') }}
              <u>{{ $t('PRC_DSCNT') }} 15%</u>
            </span>
            <span>
              {{ $t('PRC_MNTH') }}
            </span>
          </div>
        </div>
        <div class="pricing_body">
          <div class="pricing_plan">
            <h4>{{ $t('PRC_FREE') }}</h4>
            <div class="pricing_plan_price">
              <span>$0</span>/{{ $t('PRC_PRD') }}
            </div>
            <div class="btn-container">
              <button type="button" class="btn-st pay-button">{{ $t('PRC_ACT') }}</button>
            </div>
            <ul class="feat_list">
              <li>{{ $t('PRC_USR') }} <span>1</span></li>
              <li>{{ $t('PRC_SMP') }} <span>2</span></li>
              <li>{{ $t('PRC_DEPTH') }} <span>{{ $t('PRC_14') }}</span></li>
              <li class="inactive">{{ $t('PRC_SPP') }} <span>{{ $t('PRC_NO') }}</span></li>
            </ul>
            <ul class="feat_list">
              <li>{{ $t('PRC_CMPR') }}</li>
              <li>{{ $t('PRC_STR') }}</li>
              <li>{{ $t('PRC_INN') }}</li>
              <li>{{ $t('PRC_EXPORT') }} PDF, XLSX, PPTX</li>
              <li class="inactive">{{ $t('PRC_AREML') }}</li>
              <li class="inactive">{{ $t('PRC_API') }}</li>
            </ul>
          </div>
          <div class="pricing_plan">
            <h4>{{ $t('PRC_CMPN') }}</h4>
            <div class="pricing_plan_price">
              <span>$9.99</span>/{{ $t('PRC_PRD') }}
            </div>

            <div class="btn-container">
              <button type="button" class="btn-st pay-button">{{ $t('PRC_ACT') }}</button>
              <ul class="pay-list">
                <li>{{ $t('PRC_RC') }}</li>
                <li>{{ $t('PRC_FC') }}</li>
              </ul>
            </div>
            <ul class="feat_list">
              <li>{{ $t('PRC_USR') }} <span>1</span></li>
              <li>{{ $t('PRC_SMP') }} <span>2</span></li>
              <li>{{ $t('PRC_DEPTH') }} <span>{{ $t('PRC_14') }}</span></li>
              <li>{{ $t('PRC_SPP') }} <span>{{ $t('PRC_NO') }}</span></li>
            </ul>
            <ul class="feat_list">
              <li>{{ $t('PRC_CMPR') }}</li>
              <li>{{ $t('PRC_STR') }}</li>
              <li>{{ $t('PRC_INN') }}</li>
              <li>{{ $t('PRC_EXPORT') }} PDF, XLSX, PPTX</li>
              <li class="inactive">{{ $t('PRC_AREML') }}</li>
              <li class="inactive">{{ $t('PRC_API') }}</li>
            </ul>
          </div>
          <div class="pricing_plan highlight">
            <h4>{{ $t('PRC_AGNC') }}</h4>
            <div class="pricing_plan_price">
              <span>$29.99</span>/{{ $t('PRC_PRD') }}
            </div>
            <div class="btn-container">
              <button type="button" class="btn-st pay-button">{{ $t('PRC_ACT') }}</button>
            </div>
            <ul class="feat_list">
              <li>{{ $t('PRC_USR') }} <span>1</span></li>
              <li>{{ $t('PRC_SMP') }} <span>2</span></li>
              <li>{{ $t('PRC_DEPTH') }} <span>{{ $t('PRC_14') }}</span></li>
              <li>{{ $t('PRC_SPP') }} <span>{{ $t('PRC_NO') }}</span></li>
            </ul>
            <ul class="feat_list">
              <li>{{ $t('PRC_CMPR') }}</li>
              <li>{{ $t('PRC_STR') }}</li>
              <li>{{ $t('PRC_INN') }}</li>
              <li>{{ $t('PRC_EXPORT') }} PDF, XLSX, PPTX</li>
              <li>{{ $t('PRC_AREML') }}</li>
              <li>{{ $t('PRC_API') }}</li>
            </ul>
          </div>
          <div class="pricing_plan">
            <h4>{{ $t('PRC_EXC') }}</h4>
            <div class="pricing_plan_price">
              <span>$29.99</span>/{{ $t('PRC_PRD') }}
            </div>
            <div class="btn-container">
              <button type="button" class="btn-st pay-button">{{ $t('PRC_ACT') }}</button>
            </div>
            <ul class="feat_list">
              <li>{{ $t('PRC_USR') }} <span>1</span></li>
              <li>{{ $t('PRC_SMP') }} <span>2</span></li>
              <li>{{ $t('PRC_DEPTH') }} <span>{{ $t('PRC_14') }}</span></li>
              <li>{{ $t('PRC_SPP') }} <span>{{ $t('PRC_NO') }}</span></li>
            </ul>
            <ul class="feat_list">
              <li>{{ $t('PRC_CMPR') }}</li>
              <li>{{ $t('PRC_STR') }}</li>
              <li>{{ $t('PRC_INN') }}</li>
              <li>{{ $t('PRC_EXPORT') }} PDF, XLSX, PPTX</li>
              <li>{{ $t('PRC_AREML') }}</li>
              <li>{{ $t('PRC_API') }}</li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      plans: [
        { name: "Free", popular: false, api: "--", accessCount: "0", pageCount: 2, retrospective: "last 14 days", autoReports: "--", price: "$0" },
        { name: "Small", popular: false, api: "--", accessCount: "0", pageCount: 3, retrospective: "365 days",
        autoReports: "--", price: "$7" },
        { name: "Middle", popular: true, api: "--", accessCount: "2", pageCount: 3, retrospective: "5 years", autoReports: "Yes", price: "$15" },
        { name: "Large", popular: false, api: "Yes", accessCount: "2", pageCount: 3, retrospective: "5 years", 
        autoReports: "Yes", price: "$90" }
      ]
    }
  }
}
</script>
