import {
  DASH_SET_ACTIVE_TAB_ACTION,
  DASH_LOAD_DATA_ACTION,
  DASH_SET_DATA_MUTATION,
  DASH_SET_LOADING_MUTATION,
  DASH_RELOAD_DATA_MUTATION,
  DASH_CLEAR_STATE,
  DASH_SET_POSTS_SORT_TYPE,
  DASH_SET_STORIES_SORT_TYPE,
  DASH_UPDATE_ATTACHES,
  DASH_POST_SHOW_TEXT,
  DASH_LOAD_STORIES_ATTACHMENTS,
  DASH_SET_PAGINATION_POSTS
} from '../actions/dash'
import { INTRO_SET_PAGES_RELOAD, INTRO_SET_PERIOD } from '../actions/intro'
import module from '../../models/page-utils'
const axios = module.Axios
import PageStat from '../../models/page-stat'
import PageUtils from '../../models/page-utils'
import Vue from 'vue'


const state = {
  activeTab: 'Overview',
  pages: [],
  posts: [],
  stories: [],
  datesRangePosts: [],
  datesRangeStories: [],
  dates: undefined,
  disabledDataPosts: {
    minDate: '',
    maxDate: ''
  },
  rawData: undefined,
  loading: {
    data: false,
    storiesAttachments: false
  },
  postsSortActiveType: 'date',
  postsSort: {
    date: 0,
    likes: 0,
    shares: 0,
    comments: 0
  },
  storiesSortActiveType: 'date',
  storiesSort: {
    date: 0
  },
  postCache: {},
  postTextShowMap: {},
  postsPageNum: 1
}



const getters = {
  getPostsPageNum: state => state.postsPageNum,
  getPostTextShowMap: state => state.postTextShowMap,
  getPostCache: state => state.postCache,
  getActiveTab: state => state.activeTab,
  loadingStatus: state => state.loading,
  getAnalizedPages: (state, getters) => {
    return state.pages.filter(p => {
      return getters.getSelectedPages.filter(sp => sp.hash_id == p.hash_id).length
    })
  },
  getAnalizedAdminPages: (state, getters) => {
    return getters.getAnalizedPages.filter(page => page.is_admin)
  },
  getUnionPagesData: (state, getters) => {
    return {
      pages: state.pages,
      posts: getters.getPosts.flat(),
      stories: getters.getStories.flat(),
      datesRangePosts: state.datesRangePosts,
      datesRangeStories: state.datesRangeStories,
    }
  },
  getPosts: state => state.pages.map(page => page.posts),
  getStories: state => state.pages.map(page => page.stories),
  getPostsSortStatus: state => state.postsSort[state.postsSortActiveType],
  getPostsSortActiveType: state => state.postsSortActiveType,
  getStoriesSortStatus: state => state.storiesSort[state.storiesSortActiveType],
  getStoriesSortActiveType: state => state.storiesSortActiveType,  
  getSocialOfPageInAnalize: (state, getters) => {
    return getters.getAnalizedPages.reduce((acc, page) => {
      acc[page.social] = true
      return acc
    }, {})
  },
  getSocialOfAdminPageInAnalize: (state, getters) => {
    return getters.getAnalizedPages.reduce((acc, page) => {
      page.is_admin && (acc[page.social] = true)
      return acc
    }, {})
  },
  getDisabledDatesPosts: ({ disabledDataPosts: { minDate, maxDate } }) => date => {
    date.setHours(0)

    return date < new Date(minDate) || date > new Date(maxDate)
  },



}

const actions = {
  [DASH_SET_ACTIVE_TAB_ACTION]: ({ commit }, activeTab) => {
    commit(DASH_SET_ACTIVE_TAB_ACTION, activeTab)
  },
  [DASH_LOAD_DATA_ACTION]: async ({ commit, rootGetters }) => {

    commit(DASH_SET_LOADING_MUTATION, { key: 'data', status: false })
    let hash_ids, period
    if (rootGetters.getSelectedPages.length) {
      period = rootGetters.getPeriod
      hash_ids = rootGetters.getSelectedPages.map(p => p.hash_id).join()
      localStorage.setItem('selectedPages', JSON.stringify(rootGetters.getSelectedPages))
      localStorage.setItem('period', JSON.stringify(period))
      localStorage.setItem('activeTabDash', 'Overview')
    }
    else {
      const pages = JSON.parse(localStorage.getItem('selectedPages'))
      const pagesByHash = pages.reduce((prev, cur, index) => {
        prev[cur.hash_id] = index;
        return prev
      }, {})
      hash_ids = pages.map(p => p.hash_id).join()
      period = JSON.parse(localStorage.getItem('period'))

      commit(INTRO_SET_PERIOD, period)
      commit(INTRO_SET_PAGES_RELOAD, { pages, pagesByHash })
      commit(DASH_SET_ACTIVE_TAB_ACTION, localStorage.getItem('activeTabDash'))
    }


    const { data: rawData } = await axios.post('get-pages-stat', { hash_ids, ...period })
    const dayCount = new Date(period.end).getDate() - new Date(period.start).getDate() + 1;
    rawData.pages = rawData.pages.map(p => {
      const selected = rootGetters.getSelectedPages.filter(s => p.hash_id == s.hash_id)[0]
      if (selected) {
        p.colorName = selected.colorName
        p.colorCode = selected.colorCode
      }
      return p
    })
    const data = await PageStat.calculateRawData(rawData, dayCount, window.locale.STATS) //*Archi


    commit(DASH_SET_DATA_MUTATION, { ...data, rawData })
    commit(DASH_SET_LOADING_MUTATION, { key: 'data', status: true })

  },
  [DASH_RELOAD_DATA_MUTATION]: async ({ commit, state, rootGetters }) => {
    commit(DASH_SET_LOADING_MUTATION, { key: 'data', status: false })
    const period = rootGetters.getPeriod
    const dayCount = new Date(period.end).getDate() - new Date(period.start).getDate() + 1;
    const rawData = JSON.parse(JSON.stringify(state.rawData))
    rawData.pages = rawData.pages.filter(p => {
      return rootGetters.getSelectedPages.filter(sp => sp.hash_id == p.hash_id).length
    })
    const data = await PageStat.calculateRawData(rawData, dayCount, window.locale.STATS) //*Archi
    localStorage.setItem('selectedPages', JSON.stringify(rootGetters.getSelectedPages))
    commit(DASH_SET_DATA_MUTATION, { ...data, rawData })
    setTimeout(() => commit(DASH_SET_LOADING_MUTATION, { key: 'data', status: true }), 0)
    // commit(DASH_SET_LOADING_MUTATION, { key: 'data', status: true })

  },
  [DASH_UPDATE_ATTACHES]: async ({ commit, rootGetters }, { post, attachNum }) => {
    const cache = rootGetters.getPostCache

    if (cache[post.post_id]) {
      return cache[post.post_id][attachNum]
    }

    const tokens = rootGetters.getMySocialTokens
    const attaches = await PageUtils.updateExpiredImg(post, tokens)
    commit(DASH_UPDATE_ATTACHES, attaches)
    return attaches[post.post_id][attachNum]
  },
  [DASH_LOAD_STORIES_ATTACHMENTS]: async ({ commit, rootGetters }) => {
    const period = rootGetters.getPeriod
    const hash_ids = rootGetters.getSelectedPages.map(p => p.hash_id).join()
    const { data: { storiesAttachments } } = await axios.post('get-stories-attachments',
      { hash_ids, ...period, email: rootGetters.getProfile.email }
    )
    commit(DASH_LOAD_STORIES_ATTACHMENTS, storiesAttachments)

  }
}

const mutations = {
  [DASH_SET_ACTIVE_TAB_ACTION]: (state, activeTab) => {
    if (state.activeTab == activeTab) return
    state.activeTab = activeTab
    localStorage.setItem('activeTabDash', activeTab)
  },
  [DASH_SET_DATA_MUTATION]: (state, { dates, pages, posts, stories, datesRangePosts, datesRangeStories, rawData }) => {
    state.pages = pages
    state.posts = posts
    state.stories = stories
    state.datesRangePosts = datesRangePosts
    state.datesRangeStories = datesRangeStories
    state.disabledDataPosts = {
      minDate: dates.minDate,
      maxDate: dates.maxDate
    }
    if (rawData) state.rawData = rawData
  },
  [DASH_SET_LOADING_MUTATION]: (state, { key, status }) => {
    state.loading[key] = status
    state.loading.storiesAttachments = false
  },
  [DASH_CLEAR_STATE]: (state) => {
    state.pages = []
    state.post = []
    state.datesRangePosts = []
    state.datesRangeStories = []
    state.loading.data = false
    state.loading.storiesAttachments = false
    state.rawData = undefined
    state.activeTab = 'Overview'
    state.postsSort = {
      date: 0,
      likes: 0,
      reposts: 0,
      comments: 0
    }
  },
  [DASH_SET_POSTS_SORT_TYPE]: (state, type) => {

    const current = state.postsSort[state.postsSortActiveType]

    if (type == state.postsSortActiveType) {
      state.postsSort = {
        date: 0,
        likes: 0,
        reposts: 0,
        comments: 0,
        views: 0,
        er: 0,
        er_views: 0,
        ir: 0,
        er_progressive: 0,
        reach_viral: 0,
        reach_subscribers: 0,
        reach_total: 0,
        [type]: (current + 1) % 2
      }
    } else {
      state.postsSort = {
        date: 0,
        likes: 0,
        reposts: 0,
        comments: 0,
        views: 0,
        er: 0,
        er_views: 0,
        ir: 0,
        er_progressive: 0,
        reach_viral: 0,
        reach_subscribers: 0,
        reach_total: 0
      }
    }
    state.postsSortActiveType = type
    state.pages = state.pages.map(page => {
      return {
        ...page,
        posts: page.posts.sort((a, b) => {
          const sortType = state.postsSortActiveType;
          if (!state.postsSort[state.postsSortActiveType]) return b[sortType] - a[sortType];
          return a[sortType] - b[sortType];
        })
      }
    })
  },
  [DASH_SET_STORIES_SORT_TYPE]: (state, type) => {
    const current = state.storiesSort[state.storiesSortActiveType];
  
    if (type === state.storiesSortActiveType) {
      state.storiesSort = {
        date: 0,
        views: 0,
        [type]: (current + 1) % 2,
      };
    } else {
      state.storiesSort = {
        date: 0,
        views: 0,
      };
    }
    state.storiesSortActiveType = type;
  
    state.pages = state.pages.map((page) => {
      return {
        ...page,
        stories: page.stories.sort((a, b) => {
          const sortType = state.storiesSortActiveType;
          
          const aValue = sortType === 'date' ? a.stat.date : a.stat.stats.views;
          const bValue = sortType === 'date' ? b.stat.date : b.stat.stats.views;
  
          if (!state.storiesSort[sortType]) return bValue - aValue;
          return aValue - bValue;
        }),
      };
    });
  },  
  [DASH_UPDATE_ATTACHES]: (state, attaches) => {
    state.postCache = { ...state.postCache, ...attaches }
  },
  [DASH_POST_SHOW_TEXT]: (state, postId) => {
    const newStatus = !state.postTextShowMap[postId]
    Vue.set(state.postTextShowMap, postId, newStatus);
  },
  [DASH_LOAD_STORIES_ATTACHMENTS]: (state, attachments) => {
    state.pages = state.pages.map(page => {
      page.stories = PageUtils.mergeStoriesAttachments(page.stories, attachments)
      return page
    })
    state.loading.storiesAttachments = true
  },
  [DASH_SET_PAGINATION_POSTS]: (state, num) => {
    state.postsPageNum = num
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}