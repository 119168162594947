<template>
  <div>
    <Dash-posts-stats-one v-if="getSelectedPages.length == 1 && loadingStatus.data" />
    <Dash-posts-stats-many v-if="getSelectedPages.length > 1 && loadingStatus.data" />
    <scroll-loader
      class="dash-loader"
      :loader-method="()=> {}"
      :loader-enable="!loadingStatus.data"
    ></scroll-loader>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import DashPostsStatsOne from "./Dash-posts-stats-one";
import DashPostsStatsMany from "./Dash-posts-stats-many";

export default {
  name: "Dash-posts-stats",
  computed: {
    ...mapGetters(["getSelectedPages", "loadingStatus", "getAnalizedPages"])
  },
  components: {
    DashPostsStatsOne,
    DashPostsStatsMany
  }
};
</script>

<style>
</style>