<template>
  <div id="app">
    <Modals />
    <router-view />
  </div>
</template>

<script>
import Modals from "./components/modals/Modals";
import { mapGetters } from "vuex";
import locale from "../src/locale/locale.js";
import Vue from "vue";
window.$ = require("jquery");
window.moment = require("moment");
export default {
  name: "App",
  components: {
    Modals,
  },
  beforeCreate() {
    // let language = "";
    // if (window.navigator.languages) {
    //   language = window.navigator.languages[0];
    // } else {
    //   language = window.navigator.userLanguage || window.navigator.language;
    // }
    const language = "RU"; //*Archi - мож EN по умолчанию поставим?
    Vue.prototype.$locale = locale[language];
    window.locale = locale[language];
  },
  mounted() {
    this.preventCopy();
  },

  methods: {
    preventCopy() {
      document.addEventListener("copy", (event) => {
        const selection = document.getSelection().toString().replace(/\s/g, "");

        if (isNaN(selection)) return;
        event.clipboardData.setData("text/plain", selection);
        event.preventDefault();
      });
    },
  },
};
</script>

<style>
@import "https://unpkg.com/ionicons@4.2.4/dist/css/ionicons.min.css";
@import "https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css";
#app {
  height: 100%;
}
</style>
