<template>
  <div class="sorting-panel">
    <div class="text">{{ $t('SORT_BY') }}:</div>
    <ul class="sorts">
      <li>
        <button
          @click="setStoriesSortType('date')"
          :class="`btn-st-grey ${isActiveSort('date')}`"
        >
          {{ $t('SORT_DATE') }}
        </button>
      </li>
      <li>
        <button
          @click="setStoriesSortType('views')"
          :class="`btn-st-grey ${isActiveSort('views')}`"
        >
          {{ $t('SORT_VIEWS') }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { DASH_SET_STORIES_SORT_TYPE } from "../../../store/actions/dash";

export default {
  name: "Dash-stories-feed-sort-panel",
  computed: {
    ...mapGetters({
      getStoriesSortStatus: "getStoriesSortStatus",
      getStoriesSortActiveType: "getStoriesSortActiveType",
    }),
  },
  methods: {
    ...mapMutations({
      setStoriesSortType: DASH_SET_STORIES_SORT_TYPE,
    }),
    isActiveSort(type) {
      if (type !== this.getStoriesSortActiveType) return "";
      if (!this.getStoriesSortStatus) return "active negative";
      return "active positive";
    },
  },
};
</script>