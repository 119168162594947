import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import { stylingTooltip } from "../chart-utils";

const create = function ({ ref, pages, chartData, chartColumns, color }) {
  return new Promise((resolve, rejects) => {
    if (!chartData) return

    const maxValue = chartColumns.reduce((acc, val) => { return acc + chartData[val] }, 0)
    // 
    am4core.options.queue = true;
    am4core.options.autoSetClassName = true;
    am4core.options.classNamePrefix = "lucmus-";

    const chart = am4core.create(ref[0], am4charts.RadarChart);

    chart.data = []

    chart.padding(0, 0, 0, 0);

    chart.colors.step = 2;

    chart.innerRadius = am4core.percent(100 + pages.length * 20 + 4);

    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.labels.template.location = 0.5;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.tooltipLocation = 0.5;
    categoryAxis.renderer.grid.template.strokeOpacity = 0.07;
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.mouseEnabled = false;
    categoryAxis.tooltip.disabled = true;
    categoryAxis.renderer.labels.template.disabled = true;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.grid.template.strokeOpacity = 0;
    categoryAxis.cursorTooltipEnabled = false;
    // categoryAxis.tooltip.disabled = true;


    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.labels.template.horizontalCenter = "left";
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;
    valueAxis.renderer.maxLabelPosition = 0.99;
    valueAxis.renderer.minGridDistance = 10;
    valueAxis.renderer.grid.template.strokeOpacity = 0.07;
    valueAxis.renderer.axisFills.template.disabled = true;
    valueAxis.mouseEnabled = false;
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.renderer.grid.template.strokeOpacity = 0;
    valueAxis.cursorTooltipEnabled = false;
    // valueAxis.tooltip.disabled = true;
    let series
    pages.forEach((page, i) => {

      chart.colors.list.push(am4core.color(color));
      const data = {}
      let opacity = 0

      chartColumns.forEach((column, i) => {
        const persentofTotal =
          parseFloat(((chartData[column] / maxValue) * 100).toFixed(0))
        data[column] = persentofTotal
        const series = chart.series.push(new am4charts.RadarColumnSeries());
        series.name = column;
        series.dataFields.categoryY = "category";
        series.dataFields.valueX = column;
        series.stacked = true;
        series.fill = am4core.color(color).lighten(opacity)
        series.columns.template.strokeWidth = 2;
        series.columns.template.stroke = am4core.color("#ffffff");
        series.columns.template.radarColumn.cornerRadius = 30;
        series.columns.template.height = 10;
        series.zIndex = 1;
        var hoverState = series.columns.template.states.create("hover");
        hoverState.properties.strokeWidth = 2;
        hoverState.properties.zIndex = 2;
        hoverState.properties.stroke = am4core.color(color).lighten(opacity);
        opacity += 0.25;
      })
      chart.data.push({ category: page.owner_name, ...data })



    })









    chart.seriesContainer.zIndex = 1;



    chart.cursor = new am4charts.RadarCursor();
    chart.cursor.behavior = "none";
    chart.cursor.behavior = "none";
    chart.cursor.lineY.disabled = true;
    chart.cursor.lineX.disabled = true





    chart.events.on("inited", () => {

      resolve({ chart, color })
    });
  })


}

export default create;