<template>
  <div>
    <!-- <scroll-loader class="dash-loader" :loader-method="()=> {}" :loader-enable="!loaded"></scroll-loader> -->
    <div class="post-stats" v-columns="{ columns: 1, breakpoints: { 550: 2, 1200: 3 } }">

      <div class="panel" :key="index" v-for="(chart, index) in chartNames">

        <h2>{{ $t(`DASHBOARD_POSTS_STATS_CHARTS_${chart.name}`) }}</h2>
        <div class="content radar-chart" v-if="chart.type == 'radar'">
          <div class="m-chart">
            <div
              class="chart"
              :ref="chart.name"
            ></div>
          </div>
          <div
            class="p-stats-cont"
            :style="`color: ${getColorCode(index)}`"
            v-if="chart.data.length"
          >
            <div
              class="type"
              :key="chart.name + i"
              v-for="(column, i) in chart.columns"
              @mouseover="onTextBlockMouseOver(chart, i)"
              @mouseleave="onTextBlockMouseLeave(chart)"
            >
              <span
                >{{ formatLabelStat(chart.data[0][chart.columnDataNames[i]])
                }}{{ chart.percent ? "%" : "" }}</span
              >
              <b :style="`opacity: ${1 - (i / 10) * 2}`">{{ column }}</b>
            </div>
          </div>
        </div>
        <div class="content radar-chart" v-if="chart.type == 'radarMany'">
          <div class="m-chart">
            <div
              class="chart"
              :ref="chart.name"
            ></div>
          </div>
          <div class="p-stats-cont" v-if="chart.data.length">
            <div
              class="type"
              :key="chart.name + i"
              v-for="(column, i) in chart.columns"
              @mouseover="onTextBlockMouseOver(chart, i)"
              @mouseleave="onTextBlockMouseLeave(chart)"              
            >
              <span>{{
                formatLabelStat(chart.data[0][chart.columnDataNames[i]])
              }}</span>
              <b :style="`color: ${commonColorsValues[i]}`">{{ column }}</b>
            </div>
          </div>
        </div>
        <div
          class="content bar-chart"
          v-if="chart.type == 'stacked' || chart.type == 'columns'"
        >
          <div class="m-chart">
            <div class="chart" :ref="chart.name"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import * as am4core from "@amcharts/amcharts4/core";
import RadarPosts from "@/models/charts/radar-posts-stats";
import RadarManyPosts from "@/models/charts/radar-posts-stats-many-one";
import ColumnsPosts from "@/models/charts/columns";
import StackedPosts from "@/models/charts/stacked";
import columnsDirective from "@/directives/columnsDirective.js";

import pageCharts from "@/models/page-charts";

const columnContent = ["text", "photo", "video", "link"];
const columnLength = ["long", "medium", "short"];
const columnLengthEr = ["long er", "medium er", "short er"];

export default {
  name: "Dash-posts-stats-one",
  components: {},
  directives: {
    columns: columnsDirective
  },
  computed: {
    ...mapGetters([
      "getAnalizedPages",
      "getUnionPagesData",
      "getAnalizedAdminPages",
    ]),
    locale() {
      return this.$locale.DASHBOARD.POSTS_STATS_CHARTS;
    },
    page() {
      return this.getAnalizedPages[0];
    },
    commonColorsValues() {
      return Object.values(this.$colors);
    },
  },
  data() {
    return {
      loaded: false,
      chartNames: [
        {
          name: "textLenCount",
          chart: RadarPosts,
          type: "radar",
          columns: columnLength,
          columnDataNames: ["l_cnt", "m_cnt", "s_cnt"],
          data: [],
          instance: null,
        },
        {
          name: "textLenEr",
          chart: RadarManyPosts,
          type: "radarMany",
          columns: columnLengthEr,
          columnDataNames: ["long_er", "medium_er", "short_er"],
          data: [],
          instance: null,
        },
        {
          name: "contentTypesCount",
          chart: RadarPosts,
          type: "radar",
          columns: columnContent,
          columnDataNames: ["text_cnt", "photo_cnt", "video_cnt", "link_cnt"],
          data: [],
          instance: null,
        },
        {
          name: "textLenActivity",
          chart: RadarPosts,
          type: "radar",
          columns: columnLength,
          columnDataNames: [
            "l_avg_activity",
            "m_avg_activity",
            "s_avg_activity",
          ],
          percent: true,
          data: [],
          instance: null,
        },
        {
          name: "contentTypesEr",
          chart: RadarManyPosts,
          type: "radarMany",
          columns: columnContent,
          columnDataNames: ["text_cnt", "photo_cnt", "video_cnt", "link_cnt"],
          data: [],
          instance: null,
        },
        {
          name: "contentTypesActivity",
          chart: RadarPosts,
          type: "radar",
          columns: columnContent,
          columnDataNames: [
            "t_avg_activity",
            "p_avg_activity",
            "v_avg_activity",
            "l_avg_activity",
          ],
          percent: true,
          data: [],
          instance: null,
        },
        {
          name: "hourActivity",
          chart: ColumnsPosts,
          type: "columns",
          columns: columnContent,
          field: "hour_activity",
          category: "label",
          data: [],
          instance: null,
        },
        {
          name: "weekActivity",
          chart: ColumnsPosts,
          type: "columns",
          columns: columnContent,
          category: "label",
          field: "week_activity",
          data: [],
          instance: null,
        },
        {
          name: "activityHoursTextLen",
          chart: StackedPosts,
          type: "stacked",
          columns: columnContent,
          category: "text",
          data: [],
          instance: null,
        },
        {
          name: "activityWeeksTextLen",
          chart: StackedPosts,
          type: "stacked",
          columns: columnContent,
          category: "text",
          data: [],
          instance: null,
        },
        {
          name: "erHoursTextLen",
          chart: StackedPosts,
          type: "stacked",
          columns: columnContent,
          category: "text",
          data: [],
          instance: null,
        },
        {
          name: "erWeeksTextLen",
          chart: StackedPosts,
          type: "stacked",
          columns: columnContent,
          category: "text",
          data: [],
          instance: null,
        },
        {
          name: "hashTagCount",
          chart: ColumnsPosts,
          type: "columns",
          columns: columnContent,
          field: "hashtag_cnt",
          category: "hashtag",
          data: [],
          instance: null,
        },
        {
          name: "hashTagErAvg",
          chart: ColumnsPosts,
          type: "columns",
          columns: columnContent,
          field: "hashtag_er_avg",
          category: "hashtag",
          data: [],
          instance: null,
        },
        {
          name: "hashTagActivityAvg",
          chart: ColumnsPosts,
          type: "columns",
          columns: columnContent,
          field: "hashtag_activity_avg",
          category: "hashtag",
          data: [],
          instance: null,
        },
      ],
    };
  },
  methods: {
    formatLabelStat(n) {
      return n && (n % 1 === 0 ? n : n.toFixed(2));
    },
    getColorCode(index) {
      const colors = [
        "#4979E2",
        "#8C49E2",
        "#F39930",
        "#09ADB8",
        "#E84A88",
        // "#F84E4EL",
        "#FFEC43",
        "#1FDC76",
        "#CDF335",
        "#60D9FF",
        "#1DABD8",
        // "#60D9FF",
        // "#B4ECFE",
        "#779501",
        // "#97BA0A",
        // "#B6DB22",
        "#C8EF2C",
        // "#F84E4E",
        // "#F98F8F",
        "#FAC2C2",
      ];
      if (index % 3 == 1) {
        // const calIndex = (index)
        return "0";
      }
      if (index % 3 == 2) {
        return colors[(index % 10) - Math.ceil(index / 3)];
      }
      return colors[(index % 10) - Math.ceil(index / 3)];
    },
    prepareData() {
      const { pages, posts } = this.getUnionPagesData;
      this.chartNames = this.chartNames.map((chart) => {
        let data;
        if (chart.type != "stacked" && chart.type != "columns")
          data = pageCharts[chart.name](posts);
        else {
          if (chart.type == "stacked") {
            data = pageCharts[chart.name](pages, posts);
          } else {
            data = pageCharts[chart.name](pages, posts);
          }
        }

        return {
          ...chart,
          data,
        };
      }).filter(chart => Object.entries(chart.data).length);
    },
    async renderCharts() {
      const { pages } = this.getUnionPagesData;

      this.prepareData();
      const that = this;

      for (const [i, chart] of this.chartNames.entries()) {
        let chartData;
        if (chart.type == 'stacked') {
          chartData = chart.data;
        } else {
          chartData = Array.isArray(chart.data) && chart.data.length > 1 ? chart.data : chart.data[0];
        }
        const chartColumns = chart.columnDataNames;
        const chartInstance = await chart.chart({
          ref: that.$refs[chart.name],
          pages,
          chartData,
          chartColumns,
          color: that.getColorCode(i),
          colors: that.$colors,
          field: chart.field,
          category: chart.category,
          title: that.locale[chart.name],
        });

        this.chartNames[i].instance = chartInstance;
      }
    },
    onTextBlockMouseOver(chart, seriesIndex) {
      let series = chart.instance.chart.series.getIndex(seriesIndex);
      if (series) {
        series.columns.template.strokeWidth = 2;
        series.columns.template.scale = 1.03;
        series.columns.template.stroke = am4core.color(chart.instance.color).lighten(seriesIndex*0.25);        
      }
    },
    onTextBlockMouseLeave(chart) {
      chart.instance.chart.series.each((series, index) => {
        series.columns.template.scale = 1;
        if (chart.chart === RadarPosts) {
          series.columns.template.strokeWidth = 2;
          series.columns.template.stroke = am4core.color("#ffffff");
        } else {
          series.columns.template.strokeWidth = 0;
        }
      });
    },    
  },
  mounted() {
    setTimeout(this.renderCharts, 0);
  },
  beforeDestroy() {
    setTimeout(() => {
      for (const chart of am4core.registry.baseSprites) {
        chart.disposeChildren();
        chart.dispose();
      }
    }, 0);
  },
};
</script>

<style></style>
