import "core-js/stable";
import "regenerator-runtime/runtime";

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import 'regenerator-runtime/runtime';

import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
import ScrollLoader from "vue-scroll-loader";
import VueYandexMetrika from "vue-yandex-metrika";

import "./assets/main.css";
import "./assets/temp.css";
import VueResource from "vue-resource";
import utils from "./models/page-utils";
import moment from "moment";
Vue.prototype.moment = moment;

import colors from "./assets/colors.json";
Vue.config.ignoredElements = ["mod"];

Vue.prototype.$Axios = utils.Axios;
Vue.prototype.$colors = colors;

window.colors = colors;

import i18n from './i18nSetup';

Vue.use(ScrollLoader);
Vue.use(VueResource);

import VueLazyload from "vue-lazyload";

Vue.use(VueLazyload);

Vue.http.options.emulateJSON = true;

Vue.config.productionTip = false;

import * as am4core from "@amcharts/amcharts4/core";
am4core.options.deferredDelay = 0;
am4core.options.autoDispose = true;

/* Vue.use(VueYandexMetrika, {
  id: 70303045,
  router: router,
  env: process.env.NODE_ENV,
}); */

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
