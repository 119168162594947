var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:`btn-st-red export ${_vm.getSelectedPages.length > 0 ? '' : 'unactive'}`},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(
      () => {
        _vm.isReportOpen = false;
      }
    ),expression:"\n      () => {\n        isReportOpen = false;\n      }\n    "}],staticClass:"drpdown"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.isReportOpen),expression:"isReportOpen"}],attrs:{"type":"checkbox","name":"","id":"v1"},domProps:{"checked":Array.isArray(_vm.isReportOpen)?_vm._i(_vm.isReportOpen,null)>-1:(_vm.isReportOpen)},on:{"change":function($event){var $$a=_vm.isReportOpen,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.isReportOpen=$$a.concat([$$v]))}else{$$i>-1&&(_vm.isReportOpen=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.isReportOpen=$$c}}}}),(!_vm.isReportLoading)?_c('label',{attrs:{"for":"v1"}},[_vm._v(_vm._s(_vm.$t('DASH_EXPORT'))+" "),_c('i',{staticClass:"bi-download"})]):_vm._e(),_c('scroll-loader',{attrs:{"loader-size":"18","loader-method":() => {},"loader-enable":_vm.isReportLoading}}),(_vm.getSelectedPages.length)?_c('ul',[_c('li',{on:{"click":function($event){_vm.sendForReport('pptx');
          _vm.isReportOpen = false;}}},[_vm._v(" "+_vm._s(_vm.$t('DASH_EXP_AS'))+" PPTX ")]),_c('li',{on:{"click":function($event){_vm.sendForReport('pdf');
          _vm.isReportOpen = false;}}},[_vm._v(" "+_vm._s(_vm.$t('DASH_EXP_AS'))+" PDF ")]),_c('li',{on:{"click":function($event){_vm.sendForReport('xlsx');
          _vm.isReportOpen = false;}}},[_vm._v(" "+_vm._s(_vm.$t('DASH_EXP_AS'))+" XLSX ")])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }