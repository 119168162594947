import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import PageCharts from '../page-charts'
import { stylingTooltip } from "../chart-utils";

const days = {
  'Sun': 'Sunday',
  'Mon': 'Monday',
  'Tue': 'Tuesday',
  'Wed': 'Wednesday',
  'Thu': 'Thursday',
  'Fri': 'Friday',
  'Sat': 'Saturday'
}

const agregate = (page) => {
  return PageCharts.onlineFansIGFB(page.audienceStats.online_followers)
    .map(item => {
      return {
        ...item,
        hour: `${parseInt(item.hour)}:00`,
        label: days[item.weekday],
        index: Object.keys(days).indexOf(item.weekday)

      }
    }).sort((a, b) => a.index - b.index)
}

const create = function ({ page, ref, refLegend }) {
  return new Promise((resolve, rejects) => {
    const container = am4core.create(ref, am4core.Container);
    container.width = am4core.percent(100);
    container.height = am4core.percent(100);
    container.padding(0, 0, 0, 0)

    // container.y = -50
    const chart = container.createChild(am4charts.XYChart);
    // chart.dy = 90
    chart.height = am4core.percent(75)
    chart.padding(0, 0, 0, 0)
    chart.maskBullets = false;

    var xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    var yAxis = chart.yAxes.push(new am4charts.CategoryAxis());

    yAxis.dataFields.category = "weekday";
    xAxis.dataFields.category = "hour";
    xAxis.renderer.labels.template.fontSize = 12

    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.minGridDistance = 50;

    yAxis.renderer.grid.template.disabled = true;
    yAxis.renderer.labels.template.adapter.add("textOutput", function (text) {
      if (text)
      return text.toUpperCase()
    });
    yAxis.renderer.inversed = true;
    yAxis.renderer.labels.template.fontSize = 10
    yAxis.renderer.labels.template.fontWeight = '700'
    yAxis.renderer.labels.template.paddingRight = 30
    yAxis.renderer.labels.template.paddingBottom = 5

    yAxis.renderer.minGridDistance = 20;

    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryY = "weekday";
    series.dataFields.categoryX = "hour";
    series.dataFields.value = "value";
    series.name = 'online page fans'
    series.sequencedInterpolation = true;
    series.defaultState.transitionDuration = 3000;

    series.tooltip.animationDuration = 400;
    series.tooltip.hiddenState.transitionDuration = 400;


    var bgColor = new am4core.InterfaceColorSet().getFor("background");

    var columnTemplate = series.columns.template;
    columnTemplate.strokeWidth = 1;
    columnTemplate.strokeOpacity = 0.2;
    columnTemplate.stroke = bgColor;
    columnTemplate.tooltipHTML = "<div class='tooltip'><span class='date'>{label}, {hour}</span><ul class='tooltip-list'>" +
      `<li>
								<div class="soc-sea ${page.colorName
      }"><span class="soc-ico soc-${page.social.toLowerCase()}"></span>${page.owner_name_medium}</div>{value}
              </li>`;
    "</ul></div>";
    columnTemplate.width = am4core.percent(100);
    columnTemplate.height = am4core.percent(100);
    stylingTooltip(series)

    series.heatRules.push({
      target: columnTemplate,
      property: "fill",
      min: am4core.color(page.colorCode).lighten(0.9),
      max: am4core.color(page.colorCode)
    });


    var columnTemplate = series.columns.template;
    columnTemplate.strokeWidth = 1;
    columnTemplate.strokeOpacity = 1;
    columnTemplate.column.cornerRadius(4, 4, 4, 4);
    columnTemplate.stroke = am4core.color("#ffffff");

    // heat legend behavior

    chart.data = agregate(page)

      chart.events.on("ready", () => {
        const legend = refLegend;  
        const colorCode = legend.dataset.colorCode;
  
        const minColor = am4core.color(colorCode).lighten(0.9).hex;
        const maxColor = am4core.color(colorCode).hex;
  
        const labelMin = 'LOWEST';
        const labelMax = 'HIGHEST';      
  
        function hexToRgb(hex) {
          let r = parseInt(hex.slice(1, 3), 16),
              g = parseInt(hex.slice(3, 5), 16),
              b = parseInt(hex.slice(5, 7), 16);
          return { r, g, b };
      }
      
      function rgbToHex(r, g, b) {
          return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
      }
      
      function interpolateColor(color1, color2, factor) {
          let result = hexToRgb(color1);
          let color2rgb = hexToRgb(color2);
          result.r = Math.round(result.r + factor * (color2rgb.r - result.r));
          result.g = Math.round(result.g + factor * (color2rgb.g - result.g));
          result.b = Math.round(result.b + factor * (color2rgb.b - result.b));
          return rgbToHex(result.r, result.g, result.b);
      }      
  
        let intermediateDivs = "";
        for (let i = 1; i <= 3; i++) {
            const color = interpolateColor(minColor, maxColor, i * 0.25);
            intermediateDivs += `<div style="background-color: ${color};" class="inter-points"></div>`;
        }
  
        legend.innerHTML += `
            <span>${labelMin}</span>
            <div style="background-color: ${minColor};" class="inter-points"></div>
            ${intermediateDivs}
            <div style="background-color: ${maxColor};" class="inter-points"></div>
            <span>${labelMax}</span>
            `;
  
        resolve(container)

    });
  })


}

export default create;