
<template>
  <div>

    <div class="one-page">
      <div class="col-6" v-if="page.posts.length">
        <div class="panel main-info">
          <h2>{{ $t('DASH_TOTAL') }}</h2>
          <div class="cont">
            <ul class="sums">
              <li
                @click="selectCell(name)"
                :class="isActiveCell(name)"
                :key="index"
                v-for="({ locale, value }, name, index) in page.stats.total"
              >
                <span :class="activeColor(name)">{{ formatNum(value) }}</span>
                {{ locale }}
              </li>
            </ul>

            <div class="m-chart">
              <div
                class="chart"
                ref="overviewOneStacked"
              ></div>
            </div>
          </div>
        </div>

        <div class="panel main-eng">
          <h2>{{ $t('DASH_ENG') }}</h2>
          <ul class="sums">
            <li
              :key="index"
              v-for="({ locale, value }, name, index) in page.stats.engagement"
            >
              <span>{{ formatNum(value) }}</span>
              {{ locale }}
            </li>
          </ul>
        </div>
      </div>
      <div class="col-4">
        <div class="panel main-av" v-if="Object.keys(page.stats.average).length">
          <h2>{{ $t('DASH_AVE') }}</h2>
          <ul class="sums">
            <li
              :key="index"
              v-for="({ locale, value }, name, index) in page.stats.average"
            >
              <span>{{ formatNum(value) }}</span>
              {{ locale }}
            </li>
          </ul>
        </div>
        <div class="panel main-foll">
          <h2>{{ $t('STATS_TABS_COMMON_SUBSCRIBERS') }}</h2>
          <ul class="sums">
            <li>
              <span>{{
                formatNum(page.stats.followers.subscribers.value)
              }}</span>
              {{ $t('STATS_TABLE_FOLLOWERS_SUBSCRIBERS') }}
            </li>
            <li>
              <span :class="classGrowth">{{
                formatNum(page.stats.followers.growth.value)
              }}</span>
              {{ $t('INTRO_TABS_GROWTH') }}
            </li>
          </ul>
          <div class="m-chart">
            <div
              class="chart"
              ref="overviewOneLines"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import pageUtils from "@/models/page-utils";
import stackedOnePageChart from "@/models/charts/stacked-overview";
import linesOnePageChart from "@/models/charts/lines";
import * as am4core from "@amcharts/amcharts4/core";

export default {
  name: "Dash-overview-one",
  components: {},
  computed: {
    ...mapGetters(["getAnalizedPages", "getUnionPagesData"]),

    page() {
      return this.getAnalizedPages[0];
    },
    classGrowth() {
      const intGrowth = parseInt(this.page.stats.followers.growth.value);
      if (intGrowth > 0) return "positive";
      if (intGrowth < 0) return "negative";
      return "";
    },
  },
  data() {
    return {
      activeCells: [],
      availableColors: Object.entries(this.$colors).map(
        ([key, value], index) => {
          return { key, value, index };
        }
      ),
      activeColors: [],
      loaded: false,
      charts: {
        total: undefined,
        subscribers: undefined,
      },
    };
  },
  methods: {
    formatNum(num) {
      return pageUtils.formatNumbers(num);
    },
    isActiveCell(cell) {
      if (this.activeCells.includes(cell)) return "active";
      return "";
    },
    async selectCell(cell) {
      const index = this.activeCells.indexOf(cell);
      if (index == -1) {
        this.activeCells.push(cell);
        this.activeColors.push(this.availableColors.shift());
      } else {
        this.activeCells.splice(index, 1);
        this.availableColors.push(this.activeColors[index]);
        this.activeColors.splice(index, 1);

        this.availableColors = this.availableColors.sort(
          (a, b) => a.index - b.index
        );
      }

      const { pages, datesRangePosts, datesRangeStories } = this.$store.getters[
        "getUnionPagesData"
      ];
      await this.renderTotalChart(pages, datesRangePosts, datesRangeStories);
    },
    activeColor(cell) {
      const index = this.activeCells.indexOf(cell);
      if (index == -1) return "";
      return this.activeColors[index].key;
    },
    async renderSubscribersChart() {
      const { pages, datesRangePosts, datesRangeStories } = this.$store.getters[
        "getUnionPagesData"
      ];
      if (this.charts.subscribers) this.charts.subscribers.dispose();

      this.charts.subscribers = await linesOnePageChart({
        pages,
        datesRangePosts,
        name: "subscribers",
        type: "overview-one",
        ref: this.$refs["overviewOneLines"],
      });
    },
    async renderTotalChart(pages, datesRangePosts, datesRangeStories) {
      if (this.charts.total) this.charts.total.dispose();
      if (this.page.posts.length) {
        this.charts.total = await stackedOnePageChart({
          pages,
          datesRangePosts,
          datesRangeStories,
          names: this.activeCells,
          ref: this.$refs["overviewOneStacked"],
          colors: this.activeColors,
        });
      }
    },
    async renderCharts(pages, datesRangePosts, datesRangeStories) {
      await Promise.all([
        this.renderTotalChart(pages, datesRangePosts, datesRangeStories),
        this.renderSubscribersChart(pages, datesRangePosts),
      ]);
    },
  },
  mounted() {
    setTimeout(() => {
      this.selectCell("likes");
      this.renderSubscribersChart();
    });
  },
  beforeDestroy() {
    setTimeout(am4core.disposeAllCharts, 0);
  },
};
</script>

<style>
</style>