import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { stylingTooltip } from "../chart-utils";

// import am4themes_animated from "@amcharts/amcharts4/themes/animated";

// am4core.useTheme(am4themes_animated);



const formatLabels = (data) => {

  return data.map(day => {
    if (Number.isInteger(day.text)) return {
      ...day,
      text: `${day.text}:00`
    }
    else {
      return day
    }
  })
}

const create = ({ pages, ref, chartData, field, category, colors }) => {
  return new Promise((resolve, rejects) => {

    const container = am4core.create(ref[0], am4core.Container);
    container.width = am4core.percent(100);
    container.height = am4core.percent(100);
    container.hoverOnFocus = true

    const chart = container.createChild(am4charts.XYChart);
    container.padding = (0, 0, 0, 0)
    // Add data

    chart.data = formatLabels(chartData.data)

    // Create axes
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = category
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 60;
    categoryAxis.renderer.labels.template.disabled = false;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.cellStartLocation = 0.3;
    categoryAxis.renderer.cellEndLocation = 0.6;
    categoryAxis.cursorTooltipEnabled = false;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.dataFields.category = category

    valueAxis.cursorTooltipEnabled = false;
    valueAxis.renderer.grid.template.disabled = false;
    valueAxis.renderer.grid.template.strokeDasharray= "1,8";
    valueAxis.renderer.labels.template.fontSize = 8
    valueAxis.renderer.labels.template.fontWeight = '700'
    valueAxis.renderer.baseGrid.disabled = true;
    // Create series
    chart.colors.list = [];
    Object.values(colors).map(color => chart.colors.list.push(am4core.color(color)));



    chartData.graphs.forEach((page, index) => {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.columns.template.width = am4core.percent(70);
      series.dataFields.valueY = page.field;
      series.dataFields.categoryX = category;
      series.name = page.owner_name
      series.tooltip.animationDuration = 400;
      series.tooltip.defaultState.transitionDuration = 400;
      series.tooltip.hiddenState.transitionDuration = 400;


      series.stacked = page.newStack;
      const columnTemplate = series.columns.template;
      columnTemplate.column.cornerRadius(3, 3, 3, 3);
      columnTemplate.column.padding(1, 0, 1, 0);
      columnTemplate.strokeOpacity = 0;
      columnTemplate.maxWidth = 20

    });


    const firstSeries = chart.series.getIndex(0);


    let innerTooltipHtml = '';
    chartData.graphs.forEach((page, index) => {
      innerTooltipHtml += `<li data-value="{${page.field}}">
      <div class="soc-sea" style="color:${Object.values(colors)[index]}">
      <span class="soc-ico soc-${page.social.toLowerCase()}"></span>
      ${page.title}</div>{${page.field}}

          </li>`
    })

    firstSeries.tooltipHTML =
      `<div class='tooltip'><span class='date'>{categoryX}</span><ul class='tooltip-list'>` +
      innerTooltipHtml +
      "</ul></div>";

    firstSeries.tooltip.label.adapter.add("html", function (text, target) {

      if (target.dataItem.dataContext && chartData.graphs.some(page => {

        return target.dataItem.dataContext[page.field] != 0
      })) {
        return text;
      }
      else {
        return "";
      }
    });
    stylingTooltip(firstSeries)


    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.disabled = true;

    chart.cursor.xAxis = categoryAxis;

    // chart.cursor.behavior = "selectX";
    chart.cursor.lineY.disabled = true;


    chart.cursor.events.on("cursorpositionchanged", function (ev) {
      chart.cursor.triggerMove(ev.target.point, "soft");
    });

    container.events.on("ready", () => {
      resolve({ container, chart, colors })
    });

  })


}

export default create;