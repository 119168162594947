<template>
  <transition>
    <div class="mod-dial" @click="INTRO_SET_ADD_PAGE_VISIBLE(false)">
      <div class="mod-cont" @click.stop>
        <span class="close" @click.stop="INTRO_SET_ADD_PAGE_VISIBLE(false)"
          ><i class="bi bi-x-lg"></i
        ></span>
        <div class="mod-body">
          <div class="alert al-adding" v-if="alert">
            <p>{{ error_notification }}</p>
          </div>

          <form v-on:submit.prevent="searchPage" class="inp-btn">
            <input
              type="text"
              class="inp-st"
              :placeholder="$t('ADD_PAGE_PUT_URL')"
              v-model="inputUrl"
            />
            <button
              type="button"
              class="btn-st-red"
              @click.prevent.stop="searchPage"
            >
              {{ $t("ADD_PAGE_SEARCH") }}
            </button>
          </form>
          <div
            v-if="
              getCurrentSocialTokens.vkToken ||
              getCurrentSocialTokens.igToken ||
              getCurrentSocialTokens.fbToken
            "
          >
            <div v-if="searched">
              <div class="mod-inner">
                <span class="soc-sea">
                  {{ $t("ADD_PAGE_FOUND") }}
                </span>
                <ul class="res">
                  <li :key="index" v-for="(page, index) in foundedPages" @click.capture="selectFounded(page)">
                    <img :src="page.avatar" alt />
                    <span class="caption">{{ page.name }}</span>
                    <button
                      type="button"
                      class="btn-st-tr"
                    >
                      <template v-if="!page.selected">
                        {{ $t("ADD_PAGE_SELECT_PAGE") }}
                      </template>
                      <template v-if="page.selected">
                        {{ $t("ADD_PAGE_DELETE_PAGE") }}
                      </template>
                    </button>
                  </li>
                </ul>
              </div>
              <div class="mod-inner" v-if="getPreviosSelected.length">
                <span class="soc-sea">
                  <!-- <span class="soc-ico"></span> -->
                  {{ $t("ADD_PAGE_WAS_SELECTED") }}
                </span>
                <ul class="res">
                  <li :key="index" v-for="(page, index) in getPreviosSelected" @click.capture="selectSuggestion(page, false)">
                    <img :src="page.avatar" alt />
                    <span class="caption">{{ page.name }}</span>
                    <button
                      type="button"
                      class="btn-st-tr"
                    >
                      <template v-if="!page.selected">
                        {{ $t("ADD_PAGE_SELECT_PAGE") }}
                      </template>
                      <template v-if="page.selected">
                        {{ $t("ADD_PAGE_DELETE_PAGE") }}
                      </template>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <scroll-loader
              :loader-method="() => {}"
              :loader-enable="loadingAdminPages || isSearching"
              loader="dots"
            ></scroll-loader>
            <change-token-alert v-if="!loadingAdminPages && !isSearching" />

            <div v-if="canShowSuggestedPages">
              <div class="b16">{{ $t("ADD_PAGE_WANT_ADD") }}</div>

              <div class="mod-inner" v-if="canAddFB.length > 0">
                <span class="soc-sea">
                  <span class="soc-ico soc-fb"></span>
                </span>
                <span
                  v-if="pagination.FB.current"
                  v-on:click="--pagination.FB.current"
                  class="next"
                  >{{ $t("ADD_PAGE_SHOW_PREVIOUS") }}</span
                >

                <ul class="res">
                  <li :key="index" v-for="(page, index) in getPagination('FB')" @click.capture="selectSuggestion(page)">
                    <img :src="page.avatar" alt />
                    <span class="caption">{{ page.name }}</span>

                    <button
                      type="button"
                      class="btn-st-tr"
                    >
                      <template v-if="!page.selected">
                        {{ $t("ADD_PAGE_SELECT_PAGE") }}
                      </template>
                      <template v-if="page.selected">
                        {{ $t("ADD_PAGE_DELETE_PAGE") }}
                      </template>
                    </button>
                  </li>
                </ul>
                <span
                  v-if="pagination.FB.current < pagination.FB.total - 1"
                  v-on:click="++pagination.FB.current"
                  class="next"
                  >{{ $t("ADD_PAGE_SHOW_NEXT") }}</span
                >
              </div>
              <div class="mod-inner" v-if="canAddIG.length > 0">
                <span class="soc-sea">
                  <span class="soc-ico soc-ig"></span>
                </span>
                <span
                  v-if="pagination.IG.current"
                  v-on:click="--pagination.IG.current"
                  class="next"
                  >{{ $t("ADD_PAGE_SHOW_PREVIOUS") }}</span
                >
                <ul class="res">
                  <li :key="index" v-for="(page, index) in getPagination('IG')" @click.capture="selectSuggestion(page)">
                    <img :src="page.avatar" alt />
                    <span class="caption">{{ page.name }}</span>
                    <button
                      type="button"
                      class="btn-st-tr"
                    >
                      <template v-if="!page.selected">
                        {{ $t("ADD_PAGE_SELECT_PAGE") }}
                      </template>
                      <template v-if="page.selected">
                        {{ $t("ADD_PAGE_DELETE_PAGE") }}
                      </template>
                    </button>
                  </li>
                </ul>
                <span
                  v-if="pagination.IG.current < pagination.IG.total - 1"
                  v-on:click="++pagination.IG.current"
                  class="next"
                  >{{ $t("ADD_PAGE_SHOW_NEXT") }}</span
                >
              </div>
              <div class="mod-inner" v-if="canAddVK.length > 0">
                <span class="soc-sea">
                  <span class="soc-ico soc-vk"></span>
                </span>
                <span
                  v-if="pagination.VK.current"
                  v-on:click="--pagination.VK.current"
                  class="next"
                  >{{ $t("ADD_PAGE_SHOW_PREVIOUS") }}</span
                >
                <ul class="res">
                  <li :key="index" v-for="(page, index) in getPagination('VK')" @click.capture="selectSuggestion(page)">
                    <img :src="page.avatar" alt />
                    <span class="caption">{{ page.name }}</span>
                    <button
                      type="button"
                      class="btn-st-tr"
                    >
                      <template v-if="!page.selected">
                        {{ $t("ADD_PAGE_SELECT_PAGE") }}
                      </template>
                      <template v-if="page.selected">
                        {{ $t("ADD_PAGE_DELETE_PAGE") }}
                      </template>
                    </button>
                  </li>
                </ul>
                <span
                  v-if="pagination.VK.current < pagination.VK.total - 1"
                  v-on:click="++pagination.VK.current"
                  class="next"
                  >{{ $t("ADD_PAGE_SHOW_NEXT") }}</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="mod-foot">
          <div class="b14">
            {{ $t("ADD_PAGE_SIGN_IN_TOKENS") }}
          </div>
          <div class="mod-socials">
            <span
              v-bind:class="[
                'soc-auth',
                getCurrentSocialTokens.vkToken ? 'active' : '',
              ]"
              v-on:click="getVK()"
            >
              <span class="soc-ico soc-vk"></span>
              <span class="socials-logout" @click="removeToken('VK')">
                <i class="bi bi-x"></i>
              </span>
            </span>
            <span
              v-bind:class="[
                'soc-auth',
                getCurrentSocialTokens.fbToken ? 'active' : '',
              ]"
              v-on:click="getFB()"
            >
              <span class="soc-ico soc-fb"></span>
              <span class="socials-logout" @click="removeToken('FB')">
                <i class="bi bi-x"></i>
              </span>
            </span>
            <span
              v-bind:class="[
                'soc-auth',
                getCurrentSocialTokens.igToken ? 'active' : '',
              ]"
              v-on:click="getIG()"
            >
              <span class="soc-ico soc-ig"></span>
              <span class="socials-logout" @click="removeToken('IG')">
                <i class="bi bi-x"></i>
              </span>
            </span>
            <!-- <span class="soc-ico soc-yt"></span> -->
            <!-- <span class="soc-ico soc-tw"></span> -->
          </div>

          <div class="drpdown" v-if="filterByWriteAccess.length > 0">
            <input type="checkbox" name id="d1" :checked="isAccessDrpChecked" />
            <label for="d1">
              <b>{{ $t("ADD_PAGE_CURRENT_ACCOUNT") }}</b>
              {{ selectedAccessEmail }}
            </label>
            <span class="expand"></span>
            <ul>
              <li
                @click="selectAccount(access.owner_email)"
                :key="index"
                v-for="(access, index) in filterByWriteAccess"
              >
                {{ access.owner_email }}
              </li>
            </ul>
          </div>

          <button
            type="button"
            v-if="getCountOfSelected"
            class="btn-st-red"
            @click="addToMonitoring"
          >
            {{ $t('ADD_PAGE_COUNT_TO_ADD').replace("{0}", getCountOfSelected) }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import PageUtils from "../../models/page-utils";
import uuid from "uuid";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import { AUTH_SOCIAL_TOKENS, AUTH_REMOVE_TOKEN } from "@/store/actions/auth";
import {
  INTRO_SET_PAGES,
  INTRO_SET_ADD_PAGE_VISIBLE,
} from "../../store/actions/intro";
import Vue from "vue";
import ChangeTokenAlert from "../ChangeTokenAlert.vue";

export default {
  name: "AddPage",
  components: {
    ChangeTokenAlert,
  },
  computed: {
    ...mapGetters([
      "getSocialTokens",
      "getPeriod",
      "getProfile",
      "getAllowedAccesses",
      "getPages",
      "getPagesBySocial",
    ]),
    locale() {
      return this.$locale.ADD_PAGE;
    },
    canShowSuggestedPages() {
      const res =
        !this.loadingAdminPages &&
        !this.searched &&
        (this.canAddVK.length > 0 ||
          this.canAddIG.length > 0 ||
          this.canAddFB.length > 0);

      return res;
    },

    filterByWriteAccess() {
      if (this.getAllowedAccesses.length > 1)
        return this.getAllowedAccesses.filter(
          (access) =>
            access.owner_email != this.selectedAccessEmail && access.access_type
        );
      else return [];
    },
    getCurrentSocialTokens() {
      if (!this.getSocialTokens || !this.selectedAccessEmail) return {};

      console.log(
        "tokens222",
        this.getSocialTokens,
        this.getSocialTokens[this.selectedAccessEmail]
      );

      return this.getSocialTokens[this.selectedAccessEmail];
    },
    getSelectedSuggestion() {
      return [...this.canAddFB, ...this.canAddIG, ...this.canAddVK].filter(
        (page) => page.selected
      );
    },
    getSelectedFounded() {
      if (!this.foundedPages) return [];
      return this.foundedPages.filter((page) => page.selected);
    },
    getPreviosSelected() {
      if (!this.canAdd) return [];
      return [...this.canAddFB, ...this.canAddIG, ...this.canAddVK].filter(
        (page) => page.previosSelected
      );
    },
    getCountOfSelected() {
      return this.getSelectedSuggestion.length + this.getSelectedFounded.length;
    },
    getAllSelected() {
      return [...this.getSelectedSuggestion, ...this.getSelectedFounded];
    },
  },

  data() {
    return {
      inputUrl: "",
      accessPublicToken: true,

      foundedPages: [],

      searched: false,
      isSearching: false,
      selected: [],

      canAddIG: [],
      canAddFB: [],
      canAddVK: [],
      pagination: {
        VK: {
          current: 0,
          total: 0,
        },
        FB: {
          current: 0,
          total: 0,
        },
        IG: {
          current: 0,
          total: 0,
        },
      },
      alert: false,
      error_notification: "",

      selectedAccessEmail: undefined,
      loadingAdminPages: false,
      isAccessDrpChecked: false,
      refresh: true,
    };
  },
  created() {
    window.addEventListener(
      "storage",
      async ({ key, newValue }) => {
        if (key != "message" || !newValue) return;
        const { command, data: token } = JSON.parse(newValue);
        if (command == "auth") {
          try {
            if (token.hasOwnProperty("ig_token")) {
              const hasBusinessAccount = !!(await PageUtils.getBusinessPagesIg(
                token.ig_token
              ));
              if (!hasBusinessAccount) {
                throw new Error("ERROR_ACCOUNT_NO_BUSINESS");
              }
            }
            await this.$Axios.post("social-token", { token });
            await this.$store.dispatch(AUTH_SOCIAL_TOKENS);

            this.$store.dispatch(INTRO_SET_PAGES);

            if (this.isSearching) this.searchPage();
            else this.findGroups();
          } catch (err) {
            if (this.locale.ERROR_NOTIFICATION[err.message]) {
              this.alert = true;
              this.error_notification =
                this.locale.ERROR_NOTIFICATION[err.message]; //*Archi
              setTimeout(() => {
                this.alert = false;
                this.error_notification = "";
              }, 5000);
            }
          }
        }
      },
      false
    );
  },
  async mounted() {
    if (!this.getSocialTokens) await this.$store.dispatch(AUTH_SOCIAL_TOKENS);
    this.selectedAccessEmail = this.getProfile.email;
    this.findGroups();
  },

  methods: {
    ...mapActions([INTRO_SET_PAGES]),
    ...mapMutations([INTRO_SET_ADD_PAGE_VISIBLE]),

    selectFounded(page) {
      page.selected = !page.selected;
    },
    selectSuggestion(page, flag = true) {
      page.selected = !page.selected;
      if (flag) page.previosSelected = true;
    },

    selectAccount(email) {
      this.isAccessDrpChecked = false;
      this.selectedAccessEmail = email;
      this.findGroups();
    },
    getPagination(soc) {
      return this[`canAdd${soc}`].slice(
        this.pagination[soc].current * 3,
        this.pagination[soc].current * 3 + 3
      );
    },

    getPageTokensVK: async function () {
      const tokens = this.getSocialTokens[this.selectedAccessEmail];
      const pages = this.getPagesBySocial.VK.filter(
        ({ email }) => email == this.selectedAccessEmail
      );
      const group_ids = await PageUtils.findVkAdminGroupsForTokens(
        tokens.vkToken,
        pages
      );
      window.location.href = `https://oauth.vk.com/authorize?client_id=${
        process.env.VUE_APP_VK_APP_ID
      }&display=page&redirect_uri=${
        process.env.VUE_APP_SOCIAL_REDIRECT
      }&group_ids=${group_ids.join(
        ","
      )}&scope=stories&response_type=token&v=5.103`;
    },
    findGroups: async function () {
      if (this.searched) return;
      const tokens = this.getSocialTokens[this.selectedAccessEmail];
      const pages = this.getPages.filter(
        ({ email }) => email == this.selectedAccessEmail
      );
      this.loadingAdminPages = true;

      const loadVK = async () => {
        return (await PageUtils.findVkAdminGroups(tokens.vkToken, pages)).map(
          (r) => {
            return {
              selected: false,
              name: r.name,
              avatar: r.photo_50,
              data: {
                owner_id: r.id,
                owner_domain: r.screen_name,
                owner_type: "page",
                owner_name: r.name,
                owner_avatar: r.photo_50,
                owner_url: "https://vk.com/" + r.screen_name,
                social: "VK",
                is_admin: r.is_admin,
                social_token: tokens.vkToken,
              },
            };
          }
        );
      };
      const loadFb = async () => {
        return (
          await PageUtils.findFbAdminGroups(
            { fbToken: tokens.fbToken, proofToken: tokens.proof_token },
            pages
          )
        ).map((found) => {
          return {
            selected: false,
            name: found.name,
            avatar: found.picture.data.url,
            data: {
              owner_id: found.id,
              owner_domain: found.username,
              owner_type: "page",
              owner_name: found.name,
              owner_avatar: found.picture.data.url,
              owner_url: "https://facebook.com/" + found.username,
              social: "FB",
              is_admin: 0,
              social_token: tokens.fbToken,
            },
          };
        });
      };
      const loadIG = async () => {
        return (await PageUtils.findIgAdminGroups(tokens.igToken, pages)).map(
          (p) => {
            const found = p.body;
            return {
              selected: false,
              name: found.name,
              avatar: found.profile_picture_url,
              data: {
                owner_id: found.id,
                owner_domain: found.username,
                owner_type: "page",
                owner_name: found.name,
                owner_avatar: found.profile_picture_url,
                owner_url: "https://instagram.com/" + found.username,
                social: "IG",
                is_admin: 0,
                social_token: tokens.igToken,
              },
            };
          }
        );
      };

      const suggested = await Promise.all([loadVK(), loadFb(), loadIG()]);
      [this.canAddVK, this.canAddFB, this.canAddIG] = suggested;

      this.pagination.IG.total = Math.ceil(this.canAddIG.length / 3);
      this.pagination.FB.total = Math.ceil(this.canAddFB.length / 3);
      this.pagination.VK.total = Math.ceil(this.canAddVK.length / 3);
      this.loadingAdminPages = false;
    },

    getVK: function () {
      (window.location.href = `https://oauth.vk.com/authorize?client_id=${process.env.VUE_APP_VK_APP_ID}&display=page&redirect_uri=${process.env.VUE_APP_SOCIAL_REDIRECT}&scope=video,groups,offline,stats,stories&response_type=token&v=${process.env.VUE_APP_VK_API_VERSION}`),
        "_blank";
    },
    getFB: function () {
      window.location.href = `https://www.facebook.com/v19.0/dialog/oauth?type=user_agent&client_id=${process.env.VUE_APP_FB_APP_ID}&scope=public_profile,email,read_insights,pages_show_list,pages_read_engagement&redirect_uri=${process.env.VUE_APP_SOCIAL_REDIRECT}&state=facebook`;
    },
    getIG: function () {
      window.location.href = `https://www.facebook.com/v19.0/dialog/oauth?client_id=${process.env.VUE_APP_IG_APP_ID}&redirect_uri=${process.env.VUE_APP_SOCIAL_REDIRECT}&response_type=token&scope=email,instagram_basic,pages_show_list,read_insights,instagram_manage_insights,pages_read_engagement,business_management,pages_manage_metadata&state=instagram`;
    },
    async searchPage() {
      const parsed = PageUtils.parseUrl(this.inputUrl);
      if (!parsed) return;
      this.isSearching = true;

      const tokens = this.getSocialTokens[this.selectedAccessEmail];

      try {
        let found = {};
        let token = "";
        if (parsed.social == "VK") {
          if (!tokens.vkToken) {
            return this.getVK();
          }
          token = tokens.vkToken;
          found = await this.searchVkPage(parsed.page, token);
        } else if (parsed.social == "FB") {
          if (!tokens.fbToken) {
            return this.getFB();
          }
          token = tokens.fbToken;
          found = await this.searchFbPage(parsed.page, {
            fbToken: tokens.fbToken,
            proofToken: tokens.proof_token,
          });
        } else if (parsed.social == "IG") {
          if (!tokens.igToken) {
            return this.getIG();
          }
          token = tokens.igToken;
          found = await this.searchIgPage(parsed.page, token);
        }
        found.selected = false;
        found.social_token = token;

        if (
          !this.foundedPages.some(
            (page) => page.data.owner_id == found.data.owner_id
          )
        ) {
          this.inputUrl = "";
          this.foundedPages.push(found);
        }

        this.searched = true;
      } catch (err) {
        console.log(err);
        if (!this.foundedPages.length) this.searched = false;
        this.alert = true;
        if (this.locale.ERROR_NOTIFICATION[err.message]) {
          this.error_notification = this.locale.ERROR_NOTIFICATION[err.message]; //*Archi
          setTimeout(() => {
            this.alert = false;
            this.error_notification = "";
          }, 5000);
        }
      }

      this.isSearching = false;
    },
    async searchVkPage(page, token) {
      const found = await PageUtils.getVkPageInfo(page, token);
      const foundInfoPage = {};
      foundInfoPage.name = found.name;
      foundInfoPage.avatar = found.photo_50;
      foundInfoPage.data = {
        owner_id: found.id,
        owner_domain: found.screen_name,
        owner_type: "page",
        owner_name: found.name,
        owner_avatar: found.photo_50,
        owner_url: "https://vk.com/" + found.screen_name,
        social: "VK",
        is_admin: found.is_admin,
        social_token: token,
      };
      return foundInfoPage;
    },
    async searchIgPage(page, token) {
      const pageInfo = await PageUtils.getIgPageInfo(page, token);

      const foundInfoPage = {};
      const found = pageInfo;
      foundInfoPage.name = found.name;
      foundInfoPage.avatar = found.profile_picture_url;
      foundInfoPage.show = true;
      foundInfoPage.data = {
        owner_id: found.id,
        owner_domain: found.username,
        owner_type: "page",
        owner_name: found.name,
        owner_avatar: found.profile_picture_url,
        owner_url: "https://instagram.com/" + found.username,
        social: "IG",
        social_token: token,
        is_admin: 0,
      };
      return foundInfoPage;
    },
    async searchFbPage(page, token) {
      const foundInfoPage = {};
      const found = await PageUtils.getFbPageInfo(page, token);
      foundInfoPage.name = found.name;
      foundInfoPage.avatar = found.picture.data.url;
      foundInfoPage.data = {
        owner_id: found.id,
        owner_domain: found.username,
        owner_type: "page",
        owner_name: found.name,
        owner_avatar: found.picture.data.url,
        owner_url: "https://facebook.com/" + found.username,
        social: "FB",
        social_token: token.fbToken,
        is_admin: 0,
      };
      return foundInfoPage;
    },

    async addToMonitoring() {
      const selectedPages = this.getAllSelected.map((s) => {
        return {
          ...s.data,
          hash_id: uuid.v4().replace(/-/g, "").substring(0, 30),
        };
      });
      if (!selectedPages.length) return;

      const response = await this.$Axios.post("add-page", {
        pages: selectedPages,
        owner_email: this.selectedAccessEmail,
      });
      this.INTRO_SET_PAGES().then(() => {
        if (!this.searched) this.findGroups();
      });
      this.foundedPages = this.foundedPages.filter((page) => !page.selected);
      this.searched = false;

      const idsToGetPublicToken = selectedPages
        .filter((p) => p.social == "VK" && p.is_admin)
        .map((p) => p.owner_id);

      if (idsToGetPublicToken.length) {
        window.location.href = `https://oauth.vk.com/authorize?client_id=${
          process.env.VUE_APP_VK_APP_ID
        }&display=page&redirect_uri=${
          process.env.VUE_APP_SOCIAL_REDIRECT
        }&group_ids=${idsToGetPublicToken.join(
          ","
        )}&scope=stories&response_type=token&v=5.103`;

        // this.INTRO_SET_ADD_PAGE_VISIBLE(false);
      }
    },
    async removeToken(social) {
      await this.$store.dispatch(AUTH_REMOVE_TOKEN, {
        social,
        email: this.selectedAccessEmail,
      });
    },
  },
};
</script>
