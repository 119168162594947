<template>
  <div>
    <div class="com-pages">
      <div class="com-main1">
        <div class="panel main-info">
          <h2>{{ $t('STATS_TABS_COMMON_SUBSCRIBERS') }}</h2>
          <div class="cont">
            <div class="info-list">
              <div
                v-for="(page, index) in getAnalizedPages"
                :class="`dash-page ${isActivePage(page.hash_id)}`"
                :key="index"
                @click="changeStatusPageSubChat(page.hash_id)"
              >
                <div class="soc-block">
                  <span
                  :class="`soc-ico soc-${page.social.toLowerCase()}`"
                ></span>
                </div>
                <img :src="page.owner_avatar" alt />

                
                <div class="d-page-cred">
                  <p :title="page.owner_name">{{ page.owner_name_short }}</p>
                  <span class="foll-count"><i class="bi bi-people-fill"></i>{{ page.stats.followers.subscribers.value }}</span>
                  <span :class="`growth ${classGrowth(page)}`">{{
                    page.stats.followers.growth.value
                  }}</span>
                </div>
              </div>
            </div>
            <div class="m-chart" style="width: 100%">
              <div
                class="chart"
                :ref="`overviewManySubscribers`"
              ></div>
            </div>
          </div>
        </div>
        <div class="panel main-total">
          <h2>{{ $t('DASH_TOTAL') }}</h2>
          <div class="cont">
            <div class="metrics-list">
              <span class="m-metric">
                <i class="bi bi-heart"></i>
                {{ $t('STATS_TABLE_TOTAL_ALL_LIKES') }}
              </span>
              <span v-if="canShowShares" class="m-metric">
                <i class="bi bi-arrow-return-left"></i>
                {{ $t('STATS_TABLE_TOTAL_ALL_REPOSTS') }}
              </span>
              <span class="m-metric">
                <i class="bi bi-chat-right-dots"></i>
                {{ $t('STATS_TABLE_TOTAL_ALL_COMMENTS') }}
              </span>
              <span class="m-metric">
                <i class="bi bi-collection"></i>
               {{ $t('STATS_TABLE_TOTAL_ALL_RECORDS') }}
              </span>
              <span v-if="canShowViews" class="m-metric">
                <i class="bi bi-eye"></i>
                {{ $t('STATS_TABLE_TOTAL_ALL_VIEWS') }}
              </span>
              <span v-if="canShowStories" class="m-metric">
                <i class="bi bi-stickies"></i>
                {{ $t('STATS_TABLE_TOTAL_STORIES') }}
              </span>
              <span v-if="canShowInteractions" class="m-metric">
                <i class="bi bi-lightning-charge"></i>
                {{ $t('STATS_TABLE_TOTAL_INTERACTIONS') }}
              </span>
            </div>
            <div class="m-chart">
              <div
                class="chart"
                :style="`height: ${chartNames.length * 50}px;`"
                :ref="`overviewManyAll`"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="com-main2">
        <div class="panel main-eng">
          <h2>{{ $t('DASH_ENG') }}</h2>
          <div class="cont">
            <div class="metrics-tabs">
              <div
                :class="activeClassEngagement(key)"
                :key="index"
                v-for="({ key, locale }, index) in engagementMap"
                @click="() => selectActiveEngagement(key)"
              >
                {{ locale }}
              </div>
            </div>

            <div class="m-tabs-charts">
                <!-- <scroll-loader
                  class="dash-loader"
                  :loader-method="() => {}"
                  :loader-enable="!gaugeLoaded"></scroll-loader> -->
                <div class="radar-overview"
                  :ref="`gaugeOverview`"
                ></div>
                <ul class="tooltip-list">
                  <template v-for="(page, index) in getAnalizedPages">
                    <li v-if="page.stats.engagement[activeEngagement]" :key="index">
                      <div :class="`soc-sea ${page.colorName}`">
                        <span
                          :class="`soc-ico soc-${page.social.toLowerCase()}`"
                        ></span>
                        <span class="page-caption">{{ page.owner_name_short }}</span>
                      </div>
                      {{ page.stats.engagement[activeEngagement].value + "%" }}
                    </li>
                  </template>
                </ul>
            </div>
          </div>
        </div>
        <div class="panel main-av">
          <h2>{{ $t('DASH_AVE') }}</h2>
          <div class="cont">
            <div class="metrics-tabs">
              <div
                :class="activeClassAverage(key)"
                :key="index"
                v-for="({ key, locale }, index) in averageMap"
                @click="selectActiveAverage(key)"
              >
                {{ locale }}
              </div>
            </div>
            <div class="m-tabs-numbers">
              <div class="m-borders">
                <template v-for="(page, index) in getAnalizedPages">
                  <div v-if="page.stats.average[activeAverage]" :key="index">
                    {{ page.stats.average[activeAverage].value }}
                    <span :class="`soc-sea ${page.colorName}`">
                      <span
                        :class="`soc-ico soc-${page.social.toLowerCase()}`"
                      ></span>
                      {{ page.owner_name_short }}
                    </span>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import linesChart from "@/models/charts/lines";
import gaugeChart from "@/models/charts/gauge";
import gorizontalColumns from "@/models/charts/gorizontal-columns";

import RadarManyPosts from "@/models/charts/radar-posts-stats-many-many";

import PageStat from "@/models/page-stat";
import * as am4core from "@amcharts/amcharts4/core";

import i18n from '@/i18nSetup';

export default {
  name: "Dash-overview-many",
  computed: {
    ...mapGetters([
      "getAnalizedPages",
      "getUnionPagesData",
      "getAnalizedAdminPages",
    ]),
    canShowStories() {
      const { stories } = this.getUnionPagesData;
      return !!stories.length;
    },
    canShowViews() {
      const pagesLength = this.getAnalizedPages.length;
      const noViewsPagesLength = this.getAnalizedPages.filter(
        (page) => page.social == "FB" || page.social == "IG"
      ).length;
      return !(pagesLength === noViewsPagesLength);
    },
    canShowShares() {
      const pagesLength = this.getAnalizedPages.length;
      const noSharesPagesLength = this.getAnalizedPages.filter(
        (page) => page.social == "IG"
      ).length;
      return !(pagesLength === noSharesPagesLength);
    },
    canShowInteractions() {
      return !!this.getAnalizedAdminPages.length;
    },
    chartNames() {
      return [
        "likes",
        "reposts",
        "comments",
        "posts",
        "views",
        "stories",
        "interactions",
      ].filter((name) => {
        if (name == "views") return this.canShowViews;
        else if (name == "stories") return this.canShowStories;
        else if (name == "reposts") return this.canShowShares;
        else if (name == "interactions") return this.canShowInteractions;
        else return true;
      });
    },
    engagementMap() {
      if (!this.getAnalizedPages.length) return [];
      return Object.entries(PageStat.mapStat)
        .filter(([key, value]) => value.type == "engagement")
        .map(([key, value]) => {
          let isValueExist = this.getAnalizedPages
            .map((p) => p.stats.engagement[key])
            .some((statObj) => statObj != undefined);
          if (!isValueExist) return undefined;
          return {
            key,
            locale: i18n.t(`STATS_TABLE_${value.type.toUpperCase()}_${value.locale}`)
          };
        })
        .filter((stat) => stat != undefined);
    },
    averageMap() {
      if (!this.getAnalizedPages.length) return [];
      return Object.entries(PageStat.mapStat)
        .filter(([key, value]) => value.type == "average")
        .map(([key, value]) => {
          let isValueExist = this.getAnalizedPages
            .map((p) => p.stats.average[key])
            .some((statObj) => statObj != undefined);
          if (!isValueExist) return undefined;
          return {
            key,
            locale: i18n.t(`STATS_TABLE_${value.type.toUpperCase()}_${value.locale}`)
          };
        })
        .filter((stat) => stat != undefined);
    },
  },
  data() {
    return {
      activeEngagement: "er_day",
      activeAverage: "likes_avg",
      loaded: false,
      charts: {
        subscribers: [],
        total: [],
        gauge: [],
      },
      gaugeLoaded: false,
      subscribersChatActivePages: {},
    };
  },
  methods: {
    isActivePage(hash) {
      if (this.subscribersChatActivePages[hash] == undefined) return "";
      if (!this.subscribersChatActivePages[hash]) return "unactive";
      return "";
    },
    classGrowth(page) {
      const intGrowth = parseInt(page.stats.followers.growth.value);
      if (intGrowth > 0) return "positive";
      if (intGrowth < 0) return "negative";
      return "";
    },
    async renderCharts() {
      // await Promise.all([
      await this.renderSubscribersChart();
      await this.renderTotalCharts();
      await this.renderRadarMany();
      // ]);
      // this.loaded = true;
    },
    async renderSubscribersChart() {
      const { pages, datesRangePosts } = this.getUnionPagesData;
      if (this.charts.subscribers.length)
        this.charts.subscribers.map((c) => c.dispose());
      this.charts.subscribers = await Promise.all([
        linesChart({
          pages,
          datesRangePosts,
          name: "subscribers",
          ref: this.$refs.overviewManySubscribers,
          activePages: this.subscribersChatActivePages,
          type: "overview-many",
        }),
      ]);
    },
    async renderRadarMany() {
      const pages = [...this.getAnalizedPages.entries()];
      
      const filteredPages = pages.filter(item => item[1].stats.engagement.hasOwnProperty(this.activeEngagement));

      const obj = {
        chartColumns: [this.activeEngagement],
        chartData: filteredPages.map(item => {
          const chartDataItem = {};
          Object.keys(item[1].stats.engagement).forEach(key => {
            chartDataItem[key] = parseFloat(item[1].stats.engagement[key].value.replace(',', '.'));
          });
          return chartDataItem;
        }),
        colors: filteredPages.map(item => item[1].colorCode),
        pages: filteredPages.map(item => item[1]),
        ref: [this.$refs[`gaugeOverview`]],
        title: 'test'
      };
      return RadarManyPosts(obj);
    },
    async renderTotalCharts() {
      // if (this.charts.total.length) this.charts.total.map(c => c.dispose());
      const { pages, datesRangePosts, stories } = this.getUnionPagesData;

      this.charts.total = gorizontalColumns({
        pages,
        datesRange: datesRangePosts,
        names: this.chartNames,
        ref: this.$refs[`overviewManyAll`],        
      });
    },
    activeClassEngagement(key) {
      if (key == this.activeEngagement) return "active";
    },
    activeClassAverage(key) {
      if (key == this.activeAverage) return "active";
    },
    selectActiveEngagement(key) {
      this.gaugeLoaded = false;

      this.activeEngagement = key;
      setTimeout(async () => {
        await this.renderRadarMany();
        this.gaugeLoaded = true;
      }, 0);
    },
    selectActiveAverage(key) {
      this.activeAverage = key;
    },
    changeStatusPageSubChat(hash) {
      const status = this.subscribersChatActivePages[hash];
      this.subscribersChatActivePages[hash] = !status;
      this.subscribersChatActivePages = Object.assign(
        {},
        this.subscribersChatActivePages
      );

      setTimeout(this.renderSubscribersChart, 0);
    },
  },
  mounted() {
    setTimeout(() => {
      const { pages } = this.getUnionPagesData;

      for (const [index, page] of pages.entries()) {
        if (index == 0) this.subscribersChatActivePages[page.hash_id] = true;
        else this.subscribersChatActivePages[page.hash_id] = false;
      }
      this.subscribersChatActivePages = Object.assign(
        {},
        this.subscribersChatActivePages
      );
      this.renderCharts();
    }, 0);
  },
  beforeDestroy() {
    setTimeout(am4core.disposeAllCharts, 0);
  },
};
</script>

<style></style>
