var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('scroll-loader',{staticClass:"dash-loader",attrs:{"loader-method":()=> {},"loader-enable":!_vm.loadingStatus.data || !_vm.loadingStatus.storiesAttachments}}),(
    _vm.getSelectedPages.length == 1 
    && _vm.loadingStatus.data 
    && _vm.loadingStatus.storiesAttachments)?_c('Dash-stories-feed-one'):_vm._e(),(
    _vm.getSelectedPages.length > 1 
    && _vm.loadingStatus.data 
    && _vm.loadingStatus.storiesAttachments)?_c('Dash-stories-feed-many'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }