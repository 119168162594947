const locale = {
  "RU": {
    "AUTH": {
      "ERROR": {
        'LOGIN_FAILED_WRONG_PASS': "$$$_Wrong password, try again.",
        "LOGIN_FAILED_USER_REGISTRATION_NOT_COMPLETED": "$$$_Registration is not complete. Check email.",
        "LOGIN_FAILED_USER_NOT_FOUND": "$$$_User not found",
        "REGISTER_FAILED_USER_EXISTS": "$$$_User already register."
      },
      "COMMON": {
        "DONT_HAVE_ACCOUNT": "$$$_Don’t have an account?",
        'EMAIL': '$$$_Email',
        'PASSWORD': '$$$_Password',
        'CONFIRM': '$$$_Confirm password',
        'SIGN_IN': '$$$_Sign in',
        'SIGN_UP': '$$$_Sign up',
        'ENTER': '$$$_Enter',
        "CONTINUE": '$$$_Continue',
        "VALID_EMAIL": '$$$_Should be valid email.',
        "VALID_PASSWORD": "$$$_Password should contain at least 7 symbols and no space characters."
      },
      "SIGNUP": {
        "CREATE": "$$$_Create your account",
        "ALREADY_HAVE": '$$$_Already have an account?',
        'SENT': '$$$_Activation mail has been sent. Check your email',
        "NOT_EQUAL": '$$$_Passwords are not the same.'
      },
      "SIGNIN": {
        "SIGNIN_TO": "$$$_Sign in to your account",
        "FORGOT_YOUR_PASS": '$$$_Forgot your password?',
        "STAY_IN": "$$$_Stay signed in",
      },
      "FORGOT": {
        "RECOVERY": "$$$_Password recovery",
        "RESET": "$$$_To reset your password, enter the email associated with your account.",
        "SENT": '$$$_Request for change password sent to your email.'
      },
      "CHANGE": {
        "NEW_PASS": "$$$_Write your new password",
        'SENT': '$$$_Your password was changed. You will be redirected to login. Or you can do it directly by link',

        
      },
      "ACTIVATE": {
        "ACTIVATING": '$$$_Account is activating',
        "ACTIVATED": '$$$_Your account is activated. You will be redirected to login page',
        "NO_REDIRECT": "$$$_If it hasn't happened, click to"
      }
    },
    "HEADER": {
      "SEARCH": "$$$_Search...",
      "ACCESS_MANAGE": "$$$_Manage accesses",
      "REPORTS_MANAGE": "$$$_Manage reports",
      "LOG_OUT": "$$$_Log out"
    },
    "INTRO": {
      "START": {
        "EMPTY_LIST": "$$$_Social media list is empty",
        "START_JOURNEY": "$$$_Start your journey right now with adding first page",
        "CURRENLY_PLAN": "$$$_You are currently using our FREE plan, so you can add only 1 media. Want more?",
        "PRICING": "$$$_See our pricing plans",
        "ADD_PAGE": "$$$_+ Add page"
      },
      "MAIN": {
        "SOCIAL_MEDIA": "$$$_Social media",
        "DISPLAYING_BY": '$$$_Displaying by:',
        "BY_NAME": '$$$_By Name',
        "BY_SOCIAL": "$$$_By Social",
        "BY_OWNER": "$$$_By Owner"
      },
      "TABS": {
        "NAME": "$$$_Page name",
        "FOLLOWERS": "$$$_Followers",
        "GROWTH": "$$$_Growth",
        "REACH": "$$$_Reach",
        "ER": "$$$_ER",
        "SOCIAL": "$$$_Social",
        "LINK": '$$$_Link',
        "DELETE": "$$$_Delete"
      },
      "NOTIFICATIONS": {
        "NOTIFICATION_TITLE": "$$$_Analysis is completed."
      }
    },
    "DASHBOARD": {
      "TABS": {
        "OVERVIEW": '$$$_Overview',
        "ACTIVITY": "$$$_Activity",
        "POSTS_STATS": "$$$_Posts stats",
        "REACH": '$$$_Reach',
        "AUDIENCE": "$$$_Audience",
        "POSTS_FEED": "$$$_Posts feed",
        "STORIES_FEED": "$$$_Stories feed"
      },
      "ACTIVITY_CHARTS": {
        "likes": '$$$_Likes',
        "comments": "$$$_Comments",
        "reposts": "$$$_Shares",
        "views": "$$$_Views",
        "posts": "$$$_Posts",
        "stories": "$$$_Stories",
        "interactions": "$$$_Interactions",
        "subunsub": "$$$_Subscribed / Unsubscribed"
      },
      "POSTS_STATS_CHARTS": {
        "textLenCount": "$$$_Count by text length",
        "textLenEr": "$$$_ER by text length",
        "contentTypesCount": "$$$_Count by content type",
        "textLenActivity": "$$$_Text length relative activity",
        "contentTypesEr": "$$$_ER by content type",
        "contentTypesActivity": "$$$_Content type relative activity",
        "hourActivity": "$$$_Activity by hours of day",
        "weekActivity": "$$$_Activity by days of week",
        "activityHoursTextLen": "$$$_Text length activity by hours of day",
        "activityWeeksTextLen": "$$$_Text length activity by days of week",
        "erHoursTextLen": "$$$_Text length ER by hours of day",
        "erWeeksTextLen": "$$$_Text length ER by days of week",
        "hashTagCount": "$$$_Tags count",
        "hashTagErAvg": "$$$_ER by tags",
        "hashTagActivityAvg": "$$$_Tags activity"
      },
      "REACH_CHARTS": {
        "reach": "$$$_Reach",
        "reach_subscribers": "$$$_Reach followers",
        "mobile_reach": "$$$_Mobile Reach"
      },
      "AUDIENCE_CHARTS": {
        "COUNTRIES": "$$$_By countries",
        "AGES": "$$$_By ages",
        "ONLINE": "$$$_Online heatmap"

      },

    },
    "ADD_PAGE": {
      "SEARCH": "$$$_Search",
      "FOUND": "$$$_FOUND",
      "WANT_ADD": '$$$_List of pages you probably want to add',
      "SHOW_PREVIOUS": "$$$_Show previous",
      "SELECT_PAGE": '$$$_Add',
      "DELETE_PAGE": "$$$_Delete",
      "SHOW_NEXT": "$$$_Show next",
      "SIGN_IN_TOKENS": "$$$_Sign in with socials to connect the pages to your profile:",
      "CURRENT_ACCOUNT": "$$$_Using account:",
      "COUNT_TO_ADD": "$$$_ADD {0} PAGE",
      "WAS_SELECTED": "$$$_Selected",
      "PUT_URL": "$$$_Put the URL of the page",
      "ERROR_NOTIFICATION": {
        "ERROR_FOUNDED_ACCOUNT_NOT_BUSINESS": "$$$_It's a private profile and can't be analyzed.",
        "ERROR_UNEXPECTED": "$$$_Something happen with find this page. We will fix it ASAP.",
        "ERROR_FOUNDED_FB_GROUPS": "$$$_Fb groups cant be analyzed.",
        "ERROR_ACCOUNT_NO_BUSINESS": "$$$_Your account is not business. Change it in instagram settings and try again."
      },
    },
    "ACCESS_MANAGER": {
      "RIGHTS_MANAGER": "$$$_Access rights manager",
      "GRANT_ACCESS": "$$$_Grant access",
      "FULL_ACCESS": "$$$_Full access",
      "READ_ONLY": "$$$_Read only",
      "EMAIL": "$$$_Email",
      "ACCESS": "$$$_Access",
      "ACTION": "$$$_Action",
      "REMOVE_ACCESS": "$$$_Remove access",
      "GIVE_ACCESS": "$$$_Give access",
      "CLOSE": "$$$_CLOSE"

    },
    "COMMON": {
      "SOCIALS": {
        'IG': '$$$_Instagram',
        'FB': '$$$_Facebook',
        'VK': '$$$_VK'
      },
      "WEEKS": [
        { week: 0, text: '$$$_Su'},
        { week: 1, text: '$$$_Mo'},
        { week: 2, text: '$$$_Tu'},
        { week: 3, text: '$$$_We'},
        { week: 4, text: '$$$_Th'},
        { week: 5, text: '$$$_Fr'},
        { week: 6, text: '$$$_Sa'}
      ]
     
    },

    "ERRORS": {
      "PAGE_LIMIT": "$$$_You can't compare more than 5 pages at once"
    },

    "STATS": {
      'POSTS': {
        "ER_POST": "$$$_ER post, %",
        "ER_VIEW": "$$$_ER view, %",
        "IR": "$$$_IR, %",
        "REACH_VIRAL": "$$$_Reach viral",
        "REACH_SUBSCR": "$$$_Reach subscr",
        "REACH": "$$$_Reach",
        "ER_PROGRESSIVE": "$$$_ER Progressive, %",
        "ERR": "$$$_ERR , %",
      },
      "STORIES": {
        "REPLIES": "$$$_Replies",
        "TAPS_FORWARD": "$$$_Forward",
        "TAPS_BACK": "$$$_Back",
        "EXITS": "$$$_Exited",
        "REACH": '$$$_Reached',
        "VIEWS": '$$$_Views'
      },
      "TABLE": {
        "TOTAL": {
          "ALL_LIKES": "$$$_Likes",
          "ALL_REPOSTS": "$$$_Shares",
          "ALL_COMMENTS": "$$$_Comments",
          "ALL_VIEWS": "$$$_Views",
          "ALL_RECORDS": "$$$_Posts",
          "STORIES": '$$$_Stories',
          "INTERACTIONS": "$$$_Interactions",
        },
        "AVERAGE": {
          "REACH_POST_AVG": "$$$_Reach post ",
          "LIKES_AVG": "$$$_Likes ",
          "INTERACTIONS_AVG": '$$$_Interactions',
          "REPOSTS_AVG": "$$$_Shares ",
          "VIEWS_AVG": "$$$_Views ",
          "STORIES_REACH_AVG": '$$$_Reach stories',
          "COMMENTS_AVG": "$$$_Comments ",
          "PAGE_REACH_AVG": "$$$_Daily reach  ",
          "PAGE_REACH_MOBILE_AVG": "$$$_Daily mobile reach  ",
        },
        "ENGAGEMENT": {
          "ER_DAY": "$$$_ER day, %",
          "ER_POST": "$$$_ER post, %",
          "ER_VIEW": "$$$_ER view, %",
          "LR": "$$$_LR, %",
          "TR": "$$$_TR, %",
          "REACH_RATE_AVG": "$$$_RR, % ",
          "ERR": "$$$_ERR , %",
          "VIS_RATE": "$$$_Vis. Rate, %",
          "VIEWS_RATE": "$$$_Views Rate",
          "IR_POST_AVG": "$$$_IR post, %  ",
          "IRR_POST_AVG": "$$$_IRR post, %  ",
          "ER_PROGRESSIVE": "$$$_ER Progressive, %"

        },
        "FOLLOWERS": {
          "SUBSCRIBERS": "$$$_Followers",
          "GROWTH": "$$$_Growth",
        }
      },
      "TABS": {
        "COMMON": {
          "TITLE": "$$$_Common",
          "SUBSCRIBERS": "$$$_Followers",
          "LIKES": "$$$_Likes",
          "REPOSTS": "$$$_Shares",
          "COMMENTS": "$$$_Comments",
          "VIEWS": "$$$_Views",
          "ER": "$$$_ER",
          "ER_PROGRESSIVE": "$$$_ER progressive",
          "POST_COUNT": "$$$_Posts count"
        },
        "PUBLICATIONS": {
          "TITLE": "$$$_Posts",
          "AMT_BY_TEXT_LEN": "$$$_Count by text length",
          "ER_BY_TEXT_LEN": "$$$_ER by text length",
          "REL_ACT_BY_TEXT_LEN": "$$$_Text length relative activity",
          "AMT_BY_CONTENT_TYPE": "$$$_Count by content type",
          "ER_BY_CONTENT_TYPE": "$$$_ER by content type",
          "REL_ACT_BY_CONTENT_TYPE": "$$$_Content type relative activity",
          "HASHTAGS": "$$$_Tags",
          "HASHTAGS_ER": "$$$_ER by tags",
          "REL_ACT_BY_HASHTAGS": "$$$_Relative tags activity",
          "HOURS": "$$$_Hours of day",
          "DAYS_TEXT": "$$$_Activity by days of week",
          "HOURS_TEXT": "$$$_Activity by hours of day",
          "REL_ACT_DAYS_TEXT": "$$$_Text length activity by days of week",
          "REL_ACT_HOURS_TEXT": "$$$_Text length activity by hours of day"
        },
        "REACH": {
          "TITLE": "$$$_Reach",
          "REACH": "$$$_Reach",
          "REACH_SUBSCRIBERS": "$$$_Reach followers",
          "REACH_DAYS": "$$$_Daily reach",
        },
        "SOCIAL": {
          "TITLE": "$$$_Audience",
          "TOWNS": "$$$_Cities",
          "AGES": "$$$_Ages"

        }
      }
    }
  },
  "EN": {
    "AUTH": {
      "COMMON": {
        "DONT_HAVE_ACCOUNT": "$$$_Don’t have an account?",
        'EMAIL': '$$$_Email',
        'PASSWORD': '$$$_Password',
        'CONFIRM': '$$$_Confirm password',
        'SIGN_IN': '$$$_Sign in',
        'SIGN_UP': '$$$_Sign up',
        'ENTER': '$$$_Enter'
      },
      "SIGHUP": {
        "CREATE": "$$$_Create your account",
        "ALREADY_HAVE": '$$$_Already have an account ?',
      },
      "SIGNIN": {
        "SIGNIN_TO": "$$$_Sign in to your account",
        "FORGOT_YOUR_PASS": '$$$_Forgot your password?',
        "STAY_IN": "$$$_Stay signed in",
      },
      "FORGOT": {
        "RECOVERY": "$$$_Password recovery",
        "RESET": "$$$_To reset your password, enter the email associated with your account."
      },
      "CHANGE": {
        "NEW_PASS": "$$$_Write your new password"
      },
      "ACTIVATE": {
        "ACTIVATING": '$$$_Activating account ...',
        "ACTIVATED": '$$$_Your account is activated. You will redirect to login page'
      }
    }
  }
}


module.exports = locale
