import alasql from 'alasql';
import moment from './common/range'
import momentRangeDays from './common/data-range'

import i18n from '@/i18nSetup';

const removeUndefined = (obj) => {
  if (obj instanceof Array) {
    obj.forEach(function (item) {
      Object.keys(item).forEach(function (key) {
        if (typeof item[key] === 'undefined') {
          delete item[key];
        }
      });
    })
  } else {
    Object.keys(obj).forEach(function (key) {
      if (typeof obj[key] === 'undefined') {
        delete obj[key];
      }
    });
  }
  return obj;
}

const datesRangePosts = (posts) => {
  if (!posts.length) return []

  posts.sort(function (a, b) {
    return (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0);
  });

  const chartMinDate = posts[0].date
  const chartMaxDate = posts[posts.length - 1].date

  // const datesRange1 = moment.range(moment(chartMinDate), moment(chartMaxDate).add(1, 'days')).toArray('days');
  const datesRange = momentRangeDays(moment, chartMinDate, chartMaxDate)
  return datesRange.map(function (item) {
    return {
      date: moment(item).unix() * 1000,
      day: moment(item).startOf('day').unix()
    }
  });


}

const datesRangeStories = (stories) => {
  if (!stories.length) return []
  stories.sort(function (a, b) {
    return (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0);
  });

  const chartMinDate = stories[0].date
  const chartMaxDate = stories[stories.length - 1].date


  // const datesRange1 = moment.range(moment(chartMinDate), moment(chartMaxDate).add(1, 'days')).toArray('days');
  const datesRange = momentRangeDays(moment, chartMinDate, chartMaxDate)
  return datesRange.map(function (item) {
    return {
      date: moment(item).unix() * 1000,
      day: moment(item).startOf('day').unix()
    }
  });


}

const reach = ({ pages, datesRangePosts: dates }) => {

  let result = [];
  pages.forEach(function (page, i) {
    result.push(alasql([
      `SELECT l.day*1000 as date, l.reach AS reach_${i}`,
      'FROM ? l',

    ].join(' '), [page.dataset_admin_stat]))

  });
  if (result.length < 2) {
    return result[0].reverse()
  }
  else {
    let data = result[0]
    for (let i = 1; i < result.length; i++) {
      data = alasql('SELECT * FROM ? d right JOIN ? l USING date', [data, result[i]])
    }
    return data.reverse()
  }
}


const SubUnsub = ({ page }) => {

  let result = [];
  // pages.forEach(function (page, i) {
    result.push(alasql([
      `SELECT '${page.hash_id}' as hash_id, l.day*1000 as date, l.subscribed AS subscribed, l.unsubscribed*-1 AS unsubscribed`,
      'FROM ? l',

    ].join(' '), [page.dataset_admin_stat]))

  // });
  if (result.length < 2) {
    return result[0].reverse()
  }
  else {
    let data = result[0]
    for (let i = 1; i < result.length; i++) {
      data = alasql('SELECT * FROM ? d right JOIN ? l USING date', [data, result[i]])
    }
    return data.reverse()
  }
}


const growth = ({ pages }) => {

  let result = [];
  pages.forEach(function (page, i) {
    result.push(alasql([
      `SELECT l.day*1000 as date, l.growth AS growth_${i}`,
      'FROM ? l',

    ].join(' '), [page.growth]))

  });
  if (result.length < 2) {
    return result[0]
  }
  else {
    let data = result[0]
    for (let i = 1; i < result.length; i++) {
      data = alasql('SELECT * FROM ? d left JOIN ? l USING date', [data, result[i]])
    }
    return data
  }
}

const countries = (pages) => {
  let result = [];
  pages.forEach(function (page, i) {
    result.push(alasql([
      `SELECT code, name, [count] AS count_${i}`,
      'FROM ? l',


    ].join(' '), [page.audienceStats.countries]))

  });

  let data = result[0]
  for (let i = 1; i < result.length; i++) {
    data = alasql('SELECT * FROM ? d JOIN ? l USING code', [data, result[i]])
  }
  let sumPartition = pages.map((_, i) => `SUM(count_${i}) as total_${i}`).join(',')
  const sums = alasql(`select ${sumPartition} from ?`, [data])
  const agregTotal = Object.entries(sums[0]).map(([key, value], i) => {
    return `${value} as ${key}`
  }).join(',')
  const agregProc = Object.entries(sums[0]).map(([key, value], i) => {
    return `((count_${i}/${key})*100) as proc_${i}`
  }).join(',')
  data = alasql(`select *, ${agregTotal} from ? `, [data])
  data = alasql(`select *, ${agregProc}  from ? `, [data])
  const totalProc = pages.map((_, i) => `proc_${i}`).join('+')
  return alasql(`select *, (${totalProc}) as totalProc from ? order by totalProc desc limit 10 `, [data])

}

const reach_subscribers = ({ pages, datesRangePosts: dates }) => {

  let result = [];


  pages.forEach(function (page, i) {
    result.push(alasql([
      `SELECT l.day*1000 as date, l.reach_subscribers AS reach_subscribers_${i}`,
      'FROM ? l',

    ].join(' '), [page.dataset_admin_stat]))

  });
  if (result.length < 2) {
    return result[0].reverse()
  }
  else {
    let data = result[0]
    for (let i = 1; i < result.length; i++) {
      data = alasql('SELECT * FROM ? d right JOIN ? l USING date', [data, result[i]])
    }
    return data.reverse()
  }
}

const mobile_reach = ({ pages, datesRangePosts: dates }) => {

  let result = [];


  pages.forEach(function (page, i) {
    result.push(alasql([
      `SELECT l.day*1000 as date, l.mobile_reach AS mobile_reach_${i}`,
      'FROM ? l',

    ].join(' '), [page.dataset_admin_stat]))

  });
  if (result.length < 2) {
    return result[0].reverse()
  }
  else {
    let data = result[0]
    for (let i = 1; i < result.length; i++) {
      data = alasql('SELECT * FROM ? d right JOIN ? l USING date', [data, result[i]])
    }
    return data.reverse()
  }
}

const visitors = (pages, dates) => {

  let result = [];


  pages.forEach(function (page, i) {
    result.push(alasql([
      `SELECT l.day*1000 as date, l.reach_subscribers AS visitors_${i}`,
      'FROM ? l',

    ].join(' '), [page.dataset_admin_stat]))

  });
  if (result.length < 2) {
    return result[0].reverse()
  }
  else {
    let data = result[0]
    for (let i = 1; i < result.length; i++) {
      data = alasql('SELECT * FROM ? d JOIN ? l USING date', [data, result[i]])
    }
    return data.reverse()
  }
}

const common_views = (pages, dates) => {

  let result = [];


  pages.forEach(function (page, i) {
    result.push(alasql([
      `SELECT l.day*1000 as date, l.reach_subscribers AS common_views_${i}`,
      'FROM ? l',

    ].join(' '), [page.dataset_admin_stat]))

  });
  if (result.length < 2) {
    return result[0].reverse()
  }
  else {
    let data = result[0]
    for (let i = 1; i < result.length; i++) {
      data = alasql('SELECT * FROM ? d JOIN ? l USING date', [data, result[i]])
    }
    return data.reverse()
  }
}

const likes = ({ pages, datesRangePosts: dates }) => {

  // let result = dates.slice(0, -1);
  let result = dates;

  pages.forEach(function (page, i) {
    result = alasql([
      'SELECT ld.*, la_' + i + '.likes AS likes_' + i,
      'FROM ? ld LEFT JOIN ? la_' + i + ' ON ld.day = la_' + i + '.day'
    ].join(' '), [result, alasql([
      'SELECT l.day, SUM(l.likes) AS likes',
      'FROM ? l',
      'GROUP BY l.day'
    ].join(' '), [page.posts])]);

  });


  return removeUndefined(result);
}


const interactions = ({ pages, datesRangePosts: dates }) => {

  // let result = dates.slice(0, -1);
  let result = dates;

  pages.forEach(function (page, i) {
    result = alasql([
      'SELECT ld.*, la_' + i + '.interactions AS interactions_' + i,
      'FROM ? ld LEFT JOIN ? la_' + i + ' ON ld.day = la_' + i + '.day'
    ].join(' '), [result, alasql([
      'SELECT l.day, SUM(l.interactions) AS interactions',
      'FROM ? l',
      'GROUP BY l.day'
    ].join(' '), [page.posts])]);

  });

  return removeUndefined(result);
}


const reposts = ({ pages, datesRangePosts: dates }) => {

  // let result = dates.slice(0, -1);
  let result = dates;

  pages.forEach(function (page, i) {

    result = alasql([
      'SELECT ld.*, la_' + i + '.reposts AS reposts_' + i,
      'FROM ? ld LEFT JOIN ? la_' + i + ' ON ld.day = la_' + i + '.day'
    ].join(' '), [result, alasql([
      'SELECT l.day, SUM(l.reposts) AS reposts',
      'FROM ? l',
      'GROUP BY l.day'
    ].join(' '), [page.posts])]);

  });
  return removeUndefined(result);

}

const comments = ({ pages, datesRangePosts: dates }) => {

  // let result = dates.slice(0, -1);
  let result = dates;

  pages.forEach(function (page, i) {

    result = alasql([
      'SELECT ld.*, la_' + i + '.comments AS comments_' + i,
      'FROM ? ld LEFT JOIN ? la_' + i + ' ON ld.day = la_' + i + '.day'
    ].join(' '), [result, alasql([
      'SELECT l.day, SUM(l.comments) AS comments',
      'FROM ? l',
      'GROUP BY l.day'
    ].join(' '), [page.posts])]);

  });

  return removeUndefined(result);

}

const views = ({ pages, datesRangePosts: dates }) => {

  // let result = dates.slice(0, -1);
  let result = dates;

  pages.forEach(function (page, i) {

    result = alasql([
      'SELECT ld.*, la_' + i + '.views AS views_' + i,
      'FROM ? ld LEFT JOIN ? la_' + i + ' ON ld.day = la_' + i + '.day'
    ].join(' '), [result, alasql([
      'SELECT l.day, SUM(l.views) AS views',
      'FROM ? l',
      'GROUP BY l.day'
    ].join(' '), [page.posts])]);

  });

  return removeUndefined(result);

}

const er = ({ pages, datesRangePosts: dates }) => {

  var result = dates;

  pages.forEach(function (page, i) {

    result = alasql([
      'SELECT ld.*, ROUND(la_' + i + '.er, 4) AS er_' + i,
      'FROM ? ld JOIN ? la_' + i + ' ON ld.day = la_' + i + '.day'
    ].join(' '), [result, alasql([
      'SELECT l.day, SUM(l.er) AS er',
      'FROM ? l',
      'GROUP BY l.day'
    ].join(' '), [page.posts])]);

  });

  return removeUndefined(result);

}

const erProgressive = ({ pages, datesRangePosts: dates }) => {

  var result = dates;

  pages.forEach(function (page, i) {

    result = alasql(`Select * from ? where erProgressive_${i}> 0`, [alasql([
      'SELECT ld.*, ROUND(la_' + i + '.er, 4) AS erProgressive_' + i,
      'FROM ? ld JOIN ? la_' + i + ' ON ld.day = la_' + i + '.day'
    ].join(' '), [result, alasql([
      'SELECT l.day, SUM(l.er_thisday) AS er',
      'FROM ? l',
      'GROUP BY l.day'
    ].join(' '), [page.posts])])])

  });

  return removeUndefined(result);

}

const posts = ({ pages, datesRangePosts: dates }) => {

  var params = [];

  // params.push(dates.slice(0, -1));
  params.push(dates);

  pages.forEach(function (item) {

    params.push(alasql('SELECT day, COUNT(day) AS postsCount FROM ? GROUP BY day', [item.posts]));

  });

  var sql = [
    'SELECT ld.date, ' + pages.map(function (item, i) {
      return 'la_' + i + '.postsCount AS posts_' + i
    }).join(', '),
    'FROM ? ld ' + pages.map(function (item, i) {
      return 'LEFT JOIN ? la_' + i + ' ON ld.day = la_' + i + '.day'
    }).join(' ')
  ].join(' ');

  const result = removeUndefined(alasql(sql, params));

  return result

}

const stories = ({ pages, datesRangeStories: dates }) => {

  var params = [];

  params.push(dates);
  pages.forEach(function (item) {

    params.push(alasql('SELECT day, COUNT(day) AS storiesCount FROM ? GROUP BY day', [item.stories]));

  });


  var sql = [
    'SELECT ld.date, ' + pages.map(function (item, i) {
      return 'la_' + i + '.storiesCount AS stories_' + i
    }).join(', '),
    'FROM ? ld ' + pages.map(function (item, i) {
      return 'LEFT JOIN ? la_' + i + ' ON ld.day = la_' + i + '.day'
    }).join(' ')
  ].join(' ');

  const result = removeUndefined(alasql(sql, params));

  return result

}

const subscribers = ({ pages, datesRangeStories: dates }) => {

  let data = []

  let result = [];

  pages.forEach(function (page, i) {
    result.push(alasql([
      `SELECT l.day*1000 as date, l.subscribers AS subscribers_${i}`,
      'FROM ? l where subscribers > -1',

    ].join(' '), [page.subscribers.items]))

  });
  if (result.length < 2) {
    data = result[0].reverse()
  }
  else {
    data = result[0]
    for (let i = 1; i < result.length; i++) {
      data = alasql('SELECT * FROM ? d JOIN ? l USING date', [data, result[i]])
    }
    data = data.reverse()
  }
  return data

}

function weekActivity(pages) {

  const week_activity_sql = `
        SELECT week, SUM(activity)/COUNT(week) AS week_activity
        FROM ?
        GROUP BY week`;

  const pages_week_activity = pages.map(page => alasql(week_activity_sql, [page.posts]));

  const sum_week_activity_sql = `
        SELECT SUM(week_activity) AS sum_week_activity
        FROM ?`;

  const pages_sum_week_activity = pages_week_activity.map(week_activity => alasql(sum_week_activity_sql, [week_activity]));

  const sql = [
    'SELECT wd.week, wd.text AS label, ' + pages.map(function (item, i) {
      return 'ROUND(IFNULL(wa_' + i + '.week_activity/?*100, 0), 4) AS week_activity_' + i;
    }).join(', '),
    'FROM ? AS wd' + pages.map(function (item, i) {
      return ' LEFT JOIN ? AS wa_' + i + ' ON wd.week = wa_' + i + '.week';
    }).join(' ')
  ],

    params = [];

  pages_sum_week_activity.forEach(function (sum_week_activity) {
    params.push(sum_week_activity.pop().sum_week_activity);
  });

  params.push(i18n.t('COMMON_WEEKS'));

  pages_week_activity.forEach(function (week_activity) {
    params.push(week_activity);
  });

  return alasql(sql.join(' '), params);

}

function hourActivity(pages) {
  const hour_activity_sql = `
        SELECT hour, SUM(activity)/COUNT(hour) AS hour_activity
        FROM ?
        GROUP BY hour`;

  const pages_hour_activity = pages.map(page => alasql(hour_activity_sql, [page.posts]));

  const sum_hour_activity_sql = `
        SELECT SUM(hour_activity) AS sum_hour_activity
        FROM ?`;

  const pages_sum_hour_activity = pages_hour_activity.map(hour_activity => alasql(sum_hour_activity_sql, [hour_activity]));

  const sql = [
    'SELECT wd.hour AS label, ' + pages.map(function (item, i) {
      return 'ROUND(IFNULL(wa_' + i + '.hour_activity/?*100, 0), 4) AS hour_activity_' + i;
    }).join(', '),
    'FROM ? AS wd' + pages.map(function (item, i) {
      return ' LEFT JOIN ? AS wa_' + i + ' ON wd.hour = wa_' + i + '.hour';
    }).join(' ')
  ],

    params = [];

  pages_sum_hour_activity.forEach(function (sum_hour_activity) {
    params.push(sum_hour_activity.pop().sum_hour_activity);
  });

  params.push(Array(24).fill('').map((e, i) => ({ hour: i })));

  pages_hour_activity.forEach(function (hour_activity) {
    params.push(hour_activity);
  });
  return alasql(sql.join(' '), params);

}

function erWeeksTextLen(pages, posts) {

  const weekDays = i18n.t('COMMON_WEEKS');

  const preTextLenData = alasql([
    'SELECT page_id, week, ',
    '   SUM(IF(textLen = "short", er, 0)) s_sum_er,',
    '   SUM(IF(textLen = "short", 1, 0)) s_cnt_er,',
    '   SUM(IF(textLen = "medium", er, 0)) m_sum_er,',
    '   SUM(IF(textLen = "medium", 1, 0)) m_cnt_er,',
    '   SUM(IF(textLen = "long", er, 0)) l_sum_er,',
    '   SUM(IF(textLen = "long", 1, 0)) l_cnt_er',
    'FROM ?',
    'GROUP BY page_id, week'
  ].join(' '), [posts]);

  const textLenData = alasql([
    'SELECT w.week, t.page_id,',
    '   ROUND(IF(t.s_cnt_er = 0, 0, t.s_sum_er/t.s_cnt_er), 4) AS s_avg_er,',
    '   ROUND(IF(t.m_cnt_er = 0, 0, t.m_sum_er/t.m_cnt_er), 4) AS m_avg_er,',
    '   ROUND(IF(t.l_cnt_er = 0, 0, t.l_sum_er/t.l_cnt_er), 4) AS l_avg_er',
    'FROM ? w LEFT JOIN ? t ON w.week = t.week'
  ].join(' '), [weekDays, preTextLenData]);

  const types = ['short', 'medium', 'long'];

  const result = weekDays.map(function (week) {

    var obj = {};

    pages.forEach(function (page, i) {

      obj = Object.assign(obj, week);

      var find = textLenData.filter(function (item) {
        return item.page_id == page.page_id && item.week == week.week;
      });

      types.forEach(function (type) {

        if (type == 'short') {
          obj['page_' + i + '_' + type] = find.length == 1 ? find[0].s_avg_er : 0;
        }
        if (type == 'medium') {
          obj['page_' + i + '_' + type] = find.length == 1 ? find[0].m_avg_er : 0;
        }
        if (type == 'long') {
          obj['page_' + i + '_' + type] = find.length == 1 ? find[0].l_avg_er : 0;
        }
      })
    });

    return obj;
  });

  let graphs = [];

  pages.forEach(function (page, i) {
    types.forEach(function (type) {
      graphs.push({
        field: 'page_' + i + '_' + type,
        title: page.owner_name_medium + ' ' + page.social + ' - ' + type,
        newStack: (type == 'short' && i >= 1) ? false : true,
        social: page.social

      });
    })
  });

  return {
    data: result,
    graphs: graphs
  };

}

function erHoursTextLen(pages, posts) {

  const hours = Array(24).fill('').map(function (e, i) {
    return {
      hour: i,
      text: i
    };
  });

  const preTextLenData = alasql([
    'SELECT page_id, hour, ',
    '   SUM(IF(textLen = "short", er, 0)) s_sum_er,',
    '   SUM(IF(textLen = "short", 1, 0)) s_cnt_er,',
    '   SUM(IF(textLen = "medium", er, 0)) m_sum_er,',
    '   SUM(IF(textLen = "medium", 1, 0)) m_cnt_er,',
    '   SUM(IF(textLen = "long", er, 0)) l_sum_er,',
    '   SUM(IF(textLen = "long", 1, 0)) l_cnt_er',
    'FROM ?',
    'GROUP BY page_id, hour'
  ].join(' '), [posts]);

  const textLenData = alasql([
    'SELECT w.hour, t.page_id,',
    '   ROUND(IF(t.s_cnt_er = 0, 0, t.s_sum_er/t.s_cnt_er), 4) AS s_avg_er,',
    '   ROUND(IF(t.m_cnt_er = 0, 0, t.m_sum_er/t.m_cnt_er), 4) AS m_avg_er,',
    '   ROUND(IF(t.l_cnt_er = 0, 0, t.l_sum_er/t.l_cnt_er), 4) AS l_avg_er',
    'FROM ? w LEFT JOIN ? t ON w.hour = t.hour'
  ].join(' '), [hours, preTextLenData]);

  const types = ['short', 'medium', 'long'];

  const result = hours.map(function (hour) {

    var obj = {};

    pages.forEach(function (page, i) {

      obj = Object.assign(obj, hour);

      var find = textLenData.filter(function (item) {
        return item.page_id == page.page_id && item.hour == hour.hour;
      });

      types.forEach(function (type) {

        if (type == 'short') {
          obj['page_' + i + '_' + type] = find.length == 1 ? find[0].s_avg_er : 0;
        }
        if (type == 'medium') {
          obj['page_' + i + '_' + type] = find.length == 1 ? find[0].m_avg_er : 0;
        }
        if (type == 'long') {
          obj['page_' + i + '_' + type] = find.length == 1 ? find[0].l_avg_er : 0;
        }
      })
    });

    return obj;
  });

  var graphs = [];

  pages.forEach(function (page, i) {
    types.forEach(function (type) {
      graphs.push({
        field: 'page_' + i + '_' + type,
        title: page.owner_name_medium + ' ' + page.social + ' - ' + type,
        newStack: (type == 'short' && i >= 1) ? false : true,
        social: page.social

      });
    })
  });

  return {
    data: result,
    graphs: graphs
  };

}

function activityWeeksTextLen(pages, posts) {

  const weekDays = i18n.t('COMMON_WEEKS');

  const preActivitiesWeek = alasql([
    'SELECT page_id, week, ',
    '   SUM(IF(textLen = "short", IFNULL(activity, 0), 0)) AS s_activity,',
    '   SUM(IF(textLen = "short", 1, 0)) AS s_count_activity, ',
    '   SUM(IF(textLen = "medium", IFNULL(activity, 0), 0)) AS m_activity,',
    '   SUM(IF(textLen = "medium", 1, 0)) AS m_count_activity, ',
    '   SUM(IF(textLen = "long", IFNULL(activity, 0), 0)) AS l_activity,',
    '   SUM(IF(textLen = "long", 1, 0)) AS l_count_activity ',
    'FROM ?',
    'GROUP BY page_id, week'
  ].join(' '), [posts]);

  const activitiesWeek = alasql([
    'SELECT page_id, week, ',
    '   IF(s_count_activity = 0, 0, s_activity/s_count_activity*100) AS s_activity,',
    '   IF(m_count_activity = 0, 0, m_activity/m_count_activity*100) AS m_activity,',
    '   IF(l_count_activity = 0, 0, l_activity/l_count_activity*100) AS l_activity',
    'FROM ?'
  ].join(' '), [preActivitiesWeek]);

  const sumActivitiesWeek = alasql([
    'SELECT page_id, ',
    '   SUM(s_activity + m_activity + l_activity) AS total_activity ',
    'FROM ?',
    'GROUP BY page_id'
  ].join(' '), [activitiesWeek]);

  const textLenData = alasql([
    'SELECT w.week, t.page_id,',
    'ROUND(t.s_activity/s.total_activity*100, 4) AS s_avg_activity,',
    'ROUND(t.m_activity/s.total_activity*100, 4) AS m_avg_activity,',
    'ROUND(t.l_activity/s.total_activity*100, 4) AS l_avg_activity',
    'FROM ? w LEFT JOIN ? t ON w.week = t.week LEFT JOIN ? s ON t.page_id = s.page_id'
  ].join(' '), [weekDays, activitiesWeek, sumActivitiesWeek]);

  const types = ['short', 'medium', 'long'];

  const result = weekDays.map(function (week) {

    var obj = {};

    pages.forEach(function (page, i) {

      obj = Object.assign(obj, week);

      var find = textLenData.filter(function (item) {
        return item.page_id == page.page_id && item.week == week.week;
      });

      types.forEach(function (type) {

        if (type == 'short') {
          obj['page_' + i + '_' + type] = find.length == 1 ? find[0].s_avg_activity : 0;
        }
        if (type == 'medium') {
          obj['page_' + i + '_' + type] = find.length == 1 ? find[0].m_avg_activity : 0;
        }
        if (type == 'long') {
          obj['page_' + i + '_' + type] = find.length == 1 ? find[0].l_avg_activity : 0;
        }
      })
    });

    return obj;
  });

  let graphs = [];

  pages.forEach(function (page, i) {
    types.forEach(function (type) {
      graphs.push({
        field: 'page_' + i + '_' + type,
        title: page.owner_name_medium + ' ' + page.social + ' - ' + type,
        newStack: (type == 'short' && i >= 1) ? false : true,
        social: page.social

      });
    })
  });

  return {
    data: result,
    graphs: graphs
  };

}

function activityHoursTextLen(pages, posts) {

  const hours = Array(24).fill('').map(function (e, i) {
    return {
      hour: i,
      text: i
    };
  });

  const preActivitiesWeek = alasql([
    'SELECT page_id, hour, ',
    '   SUM(IF(textLen = "short", IFNULL(activity, 0), 0)) AS s_activity,', '   SUM(IF(textLen = "short", 1, 0)) AS s_count_activity, ',
    '   SUM(IF(textLen = "medium", IFNULL(activity, 0), 0)) AS m_activity,',
    '   SUM(IF(textLen = "medium", 1, 0)) AS m_count_activity, ',
    '   SUM(IF(textLen = "long", IFNULL(activity, 0), 0)) AS l_activity,',
    '   SUM(IF(textLen = "long", 1, 0)) AS l_count_activity ',
    'FROM ?',
    'GROUP BY page_id, hour'
  ].join(' '), [posts]);

  const activitiesWeek = alasql([
    'SELECT page_id, hour, ',
    '   IF(s_count_activity = 0, 0, s_activity/s_count_activity*100) AS s_activity,',
    '   IF(m_count_activity = 0, 0, m_activity/m_count_activity*100) AS m_activity,',
    '   IF(l_count_activity = 0, 0, l_activity/l_count_activity*100) AS l_activity',
    'FROM ?'
  ].join(' '), [preActivitiesWeek]);

  const sumActivitiesWeek = alasql([
    'SELECT page_id, ',
    '   SUM(s_activity + m_activity + l_activity) AS total_activity ',
    'FROM ?',
    'GROUP BY page_id'
  ].join(' '), [activitiesWeek]);

  const textLenData = alasql([
    'SELECT w.hour, t.page_id,',
    'ROUND(t.s_activity/s.total_activity*100, 4) AS s_avg_activity,',
    'ROUND(t.m_activity/s.total_activity*100, 4) AS m_avg_activity,',
    'ROUND(t.l_activity/s.total_activity*100, 4) AS l_avg_activity',
    'FROM ? w LEFT JOIN ? t ON w.hour = t.hour LEFT JOIN ? s ON t.page_id = s.page_id'
  ].join(' '), [hours, activitiesWeek, sumActivitiesWeek]);

  const types = ['short', 'medium', 'long'];

  const result = hours.map(function (hour) {

    var obj = {};

    pages.forEach(function (page, i) {

      obj = Object.assign(obj, hour);

      var find = textLenData.filter(function (item) {
        return item.page_id == page.page_id && item.hour == hour.hour;
      });

      types.forEach(function (type) {

        if (type == 'short') {
          obj['page_' + i + '_' + type] = find.length == 1 ? find[0].s_avg_activity : 0;
        }
        if (type == 'medium') {
          obj['page_' + i + '_' + type] = find.length == 1 ? find[0].m_avg_activity : 0;
        }
        if (type == 'long') {
          obj['page_' + i + '_' + type] = find.length == 1 ? find[0].l_avg_activity : 0;
        }
      })
    });

    return obj;
  });

  var graphs = [];

  pages.forEach(function (page, i) {
    types.forEach(function (type) {
      graphs.push({
        field: 'page_' + i + '_' + type,
        title: page.owner_name_medium + ' ' + page.social + ' - ' + type,
        newStack: (type == 'short' && i >= 1) ? false : true,
        social: page.social

      });
    })
  });

  return {
    data: result,
    graphs: graphs
  };

}

function textLenCount(posts) {

  return alasql(['SELECT name, page_id, ',
    '   SUM(IF(textLen = "short", 1, 0)) s_cnt,',
    '   SUM(IF(textLen = "medium", 1, 0)) m_cnt,',
    '   SUM(IF(textLen = "long", 1, 0)) l_cnt',
    'FROM ?',
    'GROUP BY name, page_id'
  ].join(' '), [posts]);

}

function textLenEr(posts) {

  const preTextLenEr = alasql(['SELECT name, social, page_id,',
    '   SUM(IF(textLen = "short", er, 0)) s_sum_er,',
    '   SUM(IF(textLen = "short", 1, 0)) s_cnt_er,',
    '   SUM(IF(textLen = "medium", er, 0)) m_sum_er,',
    '   SUM(IF(textLen = "medium", 1, 0)) m_cnt_er,',
    '   SUM(IF(textLen = "long", er, 0)) l_sum_er,',
    '   SUM(IF(textLen = "long", 1, 0)) l_cnt_er',
    'FROM ?',
    'GROUP BY name, social, page_id'
  ].join(' '), [posts]);

  return alasql([
    'SELECT t.name, t.page_id, t.name + \' \' + t.social AS nameSocial,',
    'ROUND(IF(t.s_cnt_er = 0, 0, t.s_sum_er/t.s_cnt_er), 4) as short_er,',
    'ROUND(IF(t.m_cnt_er = 0, 0, t.m_sum_er/t.m_cnt_er), 4) as medium_er,',
    'ROUND(IF(t.l_cnt_er = 0, 0, t.l_sum_er/t.l_cnt_er), 4) as long_er',
    'FROM ? t'
  ].join(' '), [preTextLenEr]);

}

function textLenActivity(posts) {
  const preActivitiesType = alasql([
    'SELECT page_id, name, social,',
    '   SUM(IF(textLen = "short", IFNULL(activity, 0), 0)) AS s_activity,',
    '   SUM(IF(textLen = "short", 1, 0)) AS s_count_activity, ',
    '   SUM(IF(textLen = "medium", IFNULL(activity, 0), 0)) AS m_activity,',
    '   SUM(IF(textLen = "medium", 1, 0)) AS m_count_activity, ',
    '   SUM(IF(textLen = "long", IFNULL(activity, 0), 0)) AS l_activity,',
    '   SUM(IF(textLen = "long", 1, 0)) AS l_count_activity ',
    'FROM ?',
    'GROUP BY page_id, name, social'
  ].join(' '), [posts]);

  const activitiesType = alasql([
    'SELECT page_id, name, social,',
    '   IF(s_count_activity = 0, 0, s_activity/s_count_activity*100) AS s_activity,',
    '   IF(m_count_activity = 0, 0, m_activity/m_count_activity*100) AS m_activity,',
    '   IF(l_count_activity = 0, 0, l_activity/l_count_activity*100) AS l_activity',
    'FROM ?'
  ].join(' '), [preActivitiesType]);

  const sumActivitiesType = alasql([
    'SELECT page_id, name, ',
    '   SUM(s_activity + m_activity + l_activity) AS total_activity ',
    'FROM ?',
    'GROUP BY page_id, name'
  ].join(' '), [activitiesType]);

  return alasql([
    'SELECT t.page_id, t.name, t.name + \' \' + t.social AS nameSocial, ',
    '   IF(s.total_activity = 0, 0, ROUND(t.s_activity/s.total_activity*100, 4)) AS s_avg_activity,',
    '   IF(s.total_activity = 0, 0, ROUND(t.m_activity/s.total_activity*100, 4)) AS m_avg_activity,',
    '   IF(s.total_activity = 0, 0, ROUND(t.l_activity/s.total_activity*100, 4)) AS l_avg_activity',
    'FROM ? t JOIN ? s ON t.page_id = s.page_id'
  ].join(' '), [activitiesType, sumActivitiesType]);
}

function contentTypesCount(posts) {

  return alasql(['SELECT name, page_id, social, name + \' \' + social AS nameSocial, sum(hasText) as text_cnt,',
    'sum(hasPhoto) as photo_cnt, sum(hasVideo) as video_cnt, sum(hasLink) as link_cnt',
    'FROM ?',
    'GROUP BY name, page_id, social'
  ].join(' '), [posts]);

}

function contentTypesEr(posts) {

  const preContentTypesCount = alasql(['SELECT name, page_id, social,',
    'SUM(IF(hasText = 1, er, 0)) as text_type_sum,',
    'SUM(IF(hasText = 1, 1, 0)) as text_type_cnt,',
    'SUM(IF(hasPhoto = 1, er, 0)) as photo_type_sum,',
    'SUM(IF(hasPhoto = 1, 1, 0)) as photo_type_cnt,',
    'SUM(IF(hasVideo = 1, er, 0)) as video_type_sum,',
    'SUM(IF(hasVideo = 1, 1, 0)) as video_type_cnt,',
    'SUM(IF(hasLink = 1, er, 0)) as link_type_sum,',
    'SUM(IF(hasLink = 1, 1, 0)) as link_type_cnt',
    'FROM ?',
    'GROUP BY name, page_id, social'
  ].join(' '), [posts]);

  return alasql([
    'SELECT t.name, t.name + \' \' + t.social AS nameSocial, t.page_id,',
    'ROUND(IF(t.text_type_cnt = 0, 0, t.text_type_sum/t.text_type_cnt), 4) as text_cnt,',
    'ROUND(IF(t.photo_type_cnt = 0, 0, t.photo_type_sum/t.photo_type_cnt), 4) as photo_cnt,',
    'ROUND(IF(t.video_type_cnt = 0, 0, t.video_type_sum/t.video_type_cnt), 4) as video_cnt,',
    'ROUND(IF(t.link_type_cnt = 0, 0, t.link_type_sum/t.link_type_cnt), 4) as link_cnt',
    'FROM ? t'
  ].join(' '), [preContentTypesCount]);

}

function contentTypesActivity(posts) {
  const preActivitiesType = alasql([
    'SELECT page_id, name, social,',
    '   SUM(IF(hasText = 1, IFNULL(activity, 0), 0)) AS t_activity,',
    '   SUM(IF(hasText = 1, 1, 0)) AS t_count_activity, ',
    '   SUM(IF(hasPhoto = 1, IFNULL(activity, 0), 0)) AS p_activity,',
    '   SUM(IF(hasPhoto = 1, 1, 0)) AS p_count_activity, ',
    '   SUM(IF(hasVideo = 1, IFNULL(activity, 0), 0)) AS v_activity,',
    '   SUM(IF(hasVideo = 1, 1, 0)) AS v_count_activity, ',
    '   SUM(IF(hasLink = 1, IFNULL(activity, 0), 0)) AS l_activity,',
    '   SUM(IF(hasLink = 1, 1, 0)) AS l_count_activity ',
    'FROM ?',
    'GROUP BY page_id, name, social'
  ].join(' '), [posts]);

  const activitiesType = alasql([
    'SELECT page_id, name, social, ',
    '   IF(t_count_activity = 0, 0, t_activity/t_count_activity*100) AS t_activity,',
    '   IF(p_count_activity = 0, 0, p_activity/p_count_activity*100) AS p_activity,',
    '   IF(v_count_activity = 0, 0, v_activity/v_count_activity*100) AS v_activity,',
    '   IF(l_count_activity = 0, 0, l_activity/l_count_activity*100) AS l_activity',
    'FROM ?'
  ].join(' '), [preActivitiesType]);

  const sumActivitiesType = alasql([
    'SELECT page_id, name,',
    '   SUM(t_activity + p_activity + v_activity + l_activity) AS total_activity ',
    'FROM ?',
    'GROUP BY page_id, name'
  ].join(' '), [activitiesType]);

  return alasql([
    'SELECT t.page_id, t.name, t.name + \' \' + t.social AS nameSocial, ',
    '   IF(s.total_activity = 0, 0, ROUND(t.t_activity/s.total_activity*100, 4)) AS t_avg_activity,',
    '   IF(s.total_activity = 0, 0, ROUND(t.p_activity/s.total_activity*100, 4)) AS p_avg_activity,',
    '   IF(s.total_activity = 0, 0, ROUND(t.v_activity/s.total_activity*100, 4)) AS v_avg_activity,',
    '   IF(s.total_activity = 0, 0, ROUND(t.l_activity/s.total_activity*100, 4)) AS l_avg_activity',
    'FROM ? t JOIN ? s ON t.page_id = s.page_id'
  ].join(' '), [activitiesType, sumActivitiesType]);
}


function hashTagCount(pages) {

  const sum = pages.map((item, i) => 'IFNULL(dh_'+i+ '.hashtag_cnt, 0)').join('+') + ' as total,'


  var allHashTags = alasql('SELECT  hashtag, hashtag_cnt FROM (SELECT t.hashtag, COUNT(t.hashtag) AS hashtag_cnt FROM ? t GROUP BY t.hashtag) ORDER BY 2 DESC', [
    [].concat.apply([], pages.map(function (item) {
      return item.hashtags
    })).map(function (item) {
      return {
        hashtag: item.hashtag
      }
    })
  ]),

    hashCountSubqueryArray = pages.map(function (item) {

      return alasql('SELECT hashtag, COUNT(hashtag) as hashtag_cnt FROM ? GROUP BY hashtag', [item.hashtags]).filter(function (hashVal) {
        return hashVal.hashtag != undefined;
      });

    }),



    hashCountSql = [
      'SELECT TOP 50 total,  ah.hashtag, ' +sum + pages.map(function (item, i) {
        return 'IFNULL(dh_' + i + '.hashtag_cnt, 0) as hashtag_cnt_' + i
      }).join(', '),
      'FROM ? ah ' + pages.map(function (item, i) {
        return 'LEFT JOIN ? as dh_' + i + ' ON ah.hashtag = dh_' + i + '.hashtag'
      }).join(' '),
      'ORDER BY ' + (pages.length === 1 ? 'hashtag_cnt_0 DESC' : 'hashtag')
    ].join(' '),

    hashCountParams = [];

  hashCountParams.push(allHashTags);

  hashCountSubqueryArray.forEach(function (item) {
    hashCountParams.push(item);
  });

  const data =  alasql(hashCountSql, hashCountParams);
  return data

}

// function hashTagCount(pages, allHashTags) {

//   const hashCountSubqueryArray = pages.map(function (item) {

//     return alasql('SELECT hashtag, COUNT(hashtag) as hashtag_cnt FROM ? GROUP BY hashtag', [item.hashtags]).filter(function (hashVal) {
//       return hashVal.hashtag != undefined;
//     });

//   });

//   const hashCountSql = [
//     'SELECT ah.hashtag, ' + pages.map(function (item, i) {
//       return 'IFNULL(dh_' + i + '.hashtag_cnt, 0) as hashtag_cnt_' + i
//     }).join(', '),
//     'FROM ? ah ' + pages.map(function (item, i) {
//       return 'LEFT JOIN ? as dh_' + i + ' ON ah.hashtag = dh_' + i + '.hashtag'
//     }).join(' '),
//     'ORDER BY ' + (pages.length === 1 ? 'hashtag_cnt_0 DESC' : 'hashtag')
//   ].join(' ');

//   let hashCountParams = [];

//   hashCountParams.push(allHashTags);

//   hashCountSubqueryArray.forEach(function (item) {
//     hashCountParams.push(item);
//   });

//   return alasql(hashCountSql, hashCountParams);

// }

function hashTagErAvg(pages) {

  const allHashTags = pages.reduce((acc, val) => {
    return [...acc, ...val.hashtags]
  }, [])

  const sum = pages.map((item, i) => 'IFNULL(dh_' + i + '.hashtag_er_avg, 0)').join('+') + ' as total,'


  const hashCountSubqueryArray = pages.map(function (item) {

    return alasql('SELECT hashtag, ROUND(AVG(er), 4) as hashtag_er_avg FROM ? GROUP BY hashtag', [item.hashtags]).filter(function (hashVal) {
      return hashVal.hashtag != undefined;
    });

  });

  const hashCountSql = [
    'SELECT top 50 total, ah.hashtag, ' +sum + pages.map(function (item, i) {
      return 'IFNULL(dh_' + i + '.hashtag_er_avg, 0) as hashtag_er_avg_' + i
    }).join(', '),
    'FROM ? ah ' + pages.map(function (item, i) {
      return 'LEFT JOIN ? as dh_' + i + ' ON ah.hashtag = dh_' + i + '.hashtag'
    }).join(' '),
    'ORDER BY ' + (pages.length === 1 ? 'hashtag_er_avg_0 DESC' : 'hashtag')
  ].join(' ');

  const hashCountParams = [];

  hashCountParams.push(allHashTags);

  hashCountSubqueryArray.forEach(function (item) {
    hashCountParams.push(item);
  });

  const data = alasql(hashCountSql, hashCountParams);
  
  return data
}

function hashTagActivityAvg(pages) {

  const sum = pages.map((item, i) => 'IFNULL(dh_' + i + '.hashtag_activity_avg, 0)').join('+') + ' as total,'


  const allHashTags = pages.reduce((acc, val) => {
    return [...acc, ...val.hashtags]
  }, [])

  const activitiesHashTag = pages.map(function (item) {
    return alasql([
      'SELECT hashtag,',
      'SUM(activity) as hashtag_activity_sum,',
      'COUNT(hashtag) as hashtag_cnt,',
      'IFNULL(SUM(activity)/COUNT(hashtag), 0) as hashtag_activity',
      'FROM ?',
      'GROUP BY hashtag'
    ].join(' '), [item.hashtags]);
  });

  const sumActivitiesHashTag = activitiesHashTag.map(function (item) {
    return alasql([
      'SELECT SUM(hashtag_activity) AS total_activity',
      'FROM ?',
    ].join(' '), [item]);
  });

  const avgActivitiesHashTag = activitiesHashTag.map(function (page, i) {
    return page.map(function (item) {
      return Object.assign(item, {
        hashtag_activity_avg: item.hashtag_activity / sumActivitiesHashTag[i][0].total_activity * 100
      });
    })
  });

  const hashCountSql = [
    'SELECT top 50 total, ah.hashtag, ' + sum  + pages.map(function (item, i) {
      return 'ROUND(IFNULL(dh_' + i + '.hashtag_activity_avg, 0), 4) as hashtag_activity_avg_' + i
    }).join(', '),
    'FROM ? ah ' + pages.map(function (item, i) {
      return 'LEFT JOIN ? as dh_' + i + ' ON ah.hashtag = dh_' + i + '.hashtag'
    }).join(' '),
    'ORDER BY ' + (pages.length === 1 ? 'hashtag_activity_avg_0 DESC' : 'hashtag')
  ].join(' ');

  let hashCountParams = [];

  hashCountParams.push(allHashTags);

  avgActivitiesHashTag.forEach(function (item) {
    hashCountParams.push(item);
  });

  
  const data =  alasql(hashCountSql, hashCountParams);
  
  return data
}


const filterCityes = (cities) => {
  return cities.sort((a, b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0)).splice(0, 20);
}

const citiesIGFB = (cities) => {

  return Object.keys(cities).map((key) => { return { name: key, count: cities[key] } })
}

const agesIGFB = (ages) => {
  const result = {}
  for (const age of Object.keys(ages)) {
    const [gender, cnt] = age.split('.')
    if (gender == 'F') {
      if (result[cnt]) { result[cnt].female = ages[age] }
      else { result[cnt] = { female: ages[age] } }
    }
    else if (gender == 'M') {
      if (result[cnt]) { result[cnt].male = ages[age] }
      else { result[cnt] = { male: ages[age] } }
    }

  }
  return Object.keys(result).map((r) => {
    return { age: r, ...result[r] }
  }).sort((a, b) => {
    if (parseInt(a.age.slice(0, 2)) < parseInt(b.age.slice(0, 2))) {
      return -1;
    }
    if (parseInt(a.age.slice(0, 2)) > parseInt(b.age.slice(0, 2))) {
      return 1;
    }
    // a должно быть равным b
    return 0;
  })
}



const onlineFansIGFB = (data) => {
  const x = alasql('select day, ARRAY(_) as items from ? group by day',
    [alasql(`select *, DAYOFWEEK(end_time) as day from ?`, [data])])
  const result = []
  x.map(item => {
    item.day = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][item.day]
    let sqlHelp = ''
    for (const i in [...Array(24).keys()]) sqlHelp += 'round(avg(' + '`' + i + '`' + ')) as ' + i + ','
    item.items = item.items.map(i => i.value)
    item.items = alasql(`select ${sqlHelp.slice(0, -1)} from ?`, [item.items]).pop()
    Object.keys(item.items).map(i => result.push({
      weekday: item.day,
      hour: i,
      value: item.items[i]
    }))
  })


  return result
}

const agesVK = (ages) => {
  const result = {}
  for (const age of ages) {
    const [gender, cnt] = age.value.split(';')
    if (gender == 'f') {
      if (result[cnt]) { result[cnt].female = age.count }
      else { result[cnt] = { female: age.count } }
    }
    else {
      if (result[cnt]) { result[cnt].male = age.count }
      else { result[cnt] = { male: age.count } }
    }

  }
  return Object.keys(result).map((r) => {
    return { age: r, ...result[r] }
  })
}

const ages = (pages, type) => {

  let result = [];

  pages.forEach(function (page, i) {
    let data
    if (page.social == 'VK') {
      data = agesVK(page.audienceStats.sex_age)
    }
    else {
      data = agesIGFB(page.audienceStats.page_fans_gender_age)
    }
    if (type == 'All') {
      data = alasql(`select age, (male+female) as [value_${i}] from ? order by age`, [data])
    }
    if (type == 'Men') {
      data = alasql(`select age, (male) as [value_${i}] from ? order by age`, [data])

    }
    if (type == 'Women') {
      data = alasql(`select age, (female) as [value_${i}] from ? order by age`, [data])
    }
    const { total } = alasql(`select SUM([value_${i}]) as total from ?`, [data]).pop()

    result.push(alasql(`select age, [value_${i}], ROUND(([value_${i}]/${total})*100, 2) as proc_${i} from ? `, [data]))

  });
  if (result.length < 2) {
    return result[0].reverse()
  }
  else {
    let data = result[0]
    for (let i = 1; i < result.length; i++) {
      data = alasql('SELECT * FROM ? d LEFT JOIN ? l USING age', [data, result[i]])
    }
    return data.reverse()
  }

}

export default {
  datesRangePosts, datesRangeStories, likes, reposts, comments, views, er,
  posts, subscribers, interactions,
  weekActivity, hourActivity,
  erWeeksTextLen, erHoursTextLen,
  activityWeeksTextLen, activityHoursTextLen,
  textLenCount, textLenEr, textLenActivity,
  contentTypesCount, contentTypesEr, contentTypesActivity,
  hashTagCount, hashTagErAvg, hashTagActivityAvg,
  erProgressive, reach, reach_subscribers, removeUndefined,
  filterCityes, citiesIGFB, agesIGFB, onlineFansIGFB,
  agesVK, countries, ages, stories, growth, mobile_reach,
  SubUnsub
};
