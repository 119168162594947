import module from '../../models/page-utils'
const axios = module.Axios
import store from '../../store'
import { ADMIN_CONFIRM, ADMIN_SET, ADMIN_DELETE, ADMIN_CHANGE_ACCESS } from '../actions/admin'

const state = {
  allowedAccesses: [],
  grantedAccesses: [],
  loaded: false
}

const getters = {
  getAllowedAccesses: state => state.allowedAccesses,
  getGrantedAccesses: state => state.grantedAccesses,
  isAccessLoad: state => state.loaded


}





const actions = {
  [ADMIN_CONFIRM]: ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
      axios.post('confirm-admin', { hash: data }).then(() => {
        resolve()
      })
    })
  },
  [ADMIN_SET]: ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
      axios.post("get-admin-accesses", {}).then(response => {

        const profile = store.getters.getProfile
        const data = {
          allowedAccesses: [{ owner_email: profile.email, access_type: 1 }, ...response.data.allowedAccesses],
          grantedAccesses: response.data.grantedAccesses
        }
        commit(ADMIN_SET, data)

        resolve()
      })
    })
  },
  [ADMIN_DELETE]: ({ commit, dispatch }, data) => {
    commit(ADMIN_DELETE, data)


    axios.post("delete-admin-access", { grantedEmail: data })
  },
  [ADMIN_CHANGE_ACCESS]: ({ commit, dispatch }, data) => {
    commit(ADMIN_CHANGE_ACCESS, data)


    axios.post("change-admin-access", {
      grantedEmail: data.email,
      access_type: data.access_type
    })
  },





}

const mutations = {
  [ADMIN_SET]: (state, resp) => {
    state.allowedAccesses = resp.allowedAccesses
    state.grantedAccesses = resp.grantedAccesses
    state.loaded = true
  },
  [ADMIN_DELETE]: (state, email) => {
    state.grantedAccesses = state.grantedAccesses.filter(access => access.email != email)
  },
  [ADMIN_CHANGE_ACCESS]: (state, resp) => {
    for (const access of state.grantedAccesses) {
      if (access.email == resp.email) access.access_type = resp.access_type
    }
  }


}

export default {
  state,
  getters,
  actions,
  mutations,
}