import * as am4core from "@amcharts/amcharts4/core";

export const stylingTooltip = ({ tooltip }) => {
  // tooltip.label.interactionsEnabled = true;
  tooltip.pointerOrientation = "vertical";
  tooltip.getFillFromObject = false;
  tooltip.background.fill = am4core.color("transparent");
  tooltip.background.fillOpacity = 0;
  let shadow = tooltip.background.filters.getIndex(0);
  shadow.opacity = 0;
  tooltip.background.strokeWidth = 0;
  // tooltip.padding(0, 0, 0, 0)
}


