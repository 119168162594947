import module from '../../models/page-utils'
const axios = module.Axios
import router from '../../router/index'
import qs from 'qs';
import {
  AUTH_REQUEST,
  AUTH_LOGIN,
  AUTH_GOOGLE,
  AUTH_REGISTER,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  AUTH_FORGOT,
  AUTH_CHANGEPASS,
  AUTH_REQUEST_COMPLETE,
  AUTH_SOCIAL_TOKENS,
  AUTH_ACTIVATE,
  AUTH_REMOVE_TOKEN
} from "@/store/actions/auth"


const state = {
  token: localStorage.getItem('user-token') || '',
  status: '',
  profile: {
    email: localStorage.getItem('user-email') || ''
  },
  socialTokens: undefined,
}

const getters = {
  isAuthenticated: state => !!state.token,
  getToken: state => state.token,
  authStatus: state => state.status,
  getProfile: state => state.profile,
  getSocialTokens: state => state.socialTokens,
  getMySocialTokens: state => state.socialTokens[state.profile.email]
}



const authRequest = function (action, data, resolveCb, rejectCb) {
  return new Promise((resolve, reject) => {

    axios.post(action, data).then((response) => {
      resolveCb(response, resolve);
    }, function (response) {
      rejectCb(response, reject);
    })
  });
}

const actions = {
  [AUTH_LOGIN]: ({
    commit,
    dispatch
  }, data) => {
    commit(AUTH_REQUEST, 'login');

    return authRequest('login', data, function (response, resolve) {
      if (this.checked)
        localStorage.setItem('user-token', response.data.token)
      localStorage.setItem('user-email', response.data.email)
      axios.defaults.headers.authorization = `bearer ${response.data.token}`
      commit(AUTH_SUCCESS, response)
      resolve(response)
    }.bind(data), function (error, reject) {
      commit(AUTH_ERROR, error.response.data)
      localStorage.removeItem('user-token')
      localStorage.removeItem('user-email')
      reject(error.response.data)
    });
  },
  [AUTH_GOOGLE]: ({
    commit,
    dispatch
  }, data) => {
    commit(AUTH_REQUEST, 'login');
    return authRequest('google', data, function (response, resolve) {
      localStorage.setItem('user-token', response.data.token)
      localStorage.setItem('user-email', response.data.email)
      axios.defaults.headers.authorization = `bearer ${response.data.token}`
      commit(AUTH_SUCCESS, response)
      resolve(response)
    }.bind(data), function (error, reject) {
      commit(AUTH_ERROR, error.response.data)
      localStorage.removeItem('user-token')
      localStorage.removeItem('user-email')
      reject(error.response.data)
    });
  },  
  [AUTH_REGISTER]: ({
    commit,
    dispatch
  }, data) => {

    return new Promise((resolve, reject) => {

      commit(AUTH_REQUEST, 'signup')

      const debug = process.env.NODE_ENV !== 'production';

      let url;
      if (debug) {
        url = 'http://localhost:9260/signup';
      } else {
        url = 'https://lucmus.com/api/signup';
      }
      const options = {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        },
        data: qs.stringify(data),
        url: "signup",
      };
      axios(options)

        // axios.post('https://lucmus.com/api/login', user)
        .then(resp => {
          // 

          // Here set the header of your ajax library to the token value.
          // example with axios
          // axios.defaults.headers.common['Authorization'] = resp.token
          commit(AUTH_LOGOUT, resp)
          // dispatch(USER_REQUEST)
          resolve(resp)
        })
        .catch(err => {
          commit(AUTH_ERROR, err.response.data)
          localStorage.removeItem('user-token')
          localStorage.removeItem('user-email')
          reject(err.response.data)
        })
    })
  },
  [AUTH_FORGOT]: ({
    commit,
    dispatch
  }, data) => {

    return new Promise((resolve, reject) => {

      commit(AUTH_REQUEST, 'forgot')

      const debug = process.env.NODE_ENV !== 'production';

      let url;
      if (debug) {
        url = 'http://localhost:9260/forgot';
      } else {
        url = 'https://lucmus.com/api/login';
      }

      const options = {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        },
        data: qs.stringify(data),
        url: 'forgot',
      };

      axios(options)
        .then(resp => {
          // commit(AUTH_SUCCESS, resp)
          resolve(resp)
        })
        .catch(err => {
          commit(AUTH_ERROR, err.response.data)
          reject(err.response.data)
        })
    })
  },
  [AUTH_CHANGEPASS]: ({
    commit,
    dispatch
  }, data) => {
    commit(AUTH_REQUEST, 'changepass');
    return authRequest('changepass', data, function (response, resolve) {
      commit(AUTH_REQUEST_COMPLETE)
      resolve(response)
    }, function (error, reject) {
      commit(AUTH_ERROR, error.response.data)
      reject(error.response.data)
    });
  },
  [AUTH_ACTIVATE]: ({
    commit,
    dispatch
  }, data) => {
    return authRequest('activate', data, function (response, resolve) {
      commit(AUTH_REQUEST_COMPLETE)
      resolve(response)
    }, function (error, reject) {
      commit(AUTH_ERROR, error.response.data)
      reject(error.response.data)
    });
  },
  [AUTH_LOGOUT]: ({
    commit,
    dispatch
  }) => {
    return new Promise((resolve, reject) => {
      localStorage.removeItem('user-token')
      localStorage.removeItem('user-email')
      commit(AUTH_LOGOUT)
      commit('RESET_STORE')
      router.push({ path: "/auth/login" })



      resolve()
    })
  },
  [AUTH_SOCIAL_TOKENS]: async ({
    commit,
    dispatch
  }) => {
    await axios.post('get-social-token', state.profile).then(({ data }) => {
      commit(AUTH_SOCIAL_TOKENS, {
        tokens: data.tokens
      })

    })
  },
  [AUTH_REMOVE_TOKEN]: async ({
    commit,
    dispatch
  }, payload) => {
    await axios.post('remove-social-token', payload).then(({ data }) => {
      if (data && data.success && data.success === true) {
        dispatch(AUTH_SOCIAL_TOKENS);
      }
    })
  }
}

const mutations = {
  [AUTH_REQUEST]: (state, type) => {
    state.status = `loading-${type}`;
  },
  [AUTH_SUCCESS]: (state, resp) => {
    state.status = 'success'
    // 
    state.token = resp.data.token
    state.profile.email = resp.data.email
    // state.hasLoadedOnce = true
  },
  [AUTH_REQUEST_COMPLETE]: (state) => {
    state.status = ''
  },
  [AUTH_ERROR]: (state, resp) => {
    state.status = 'error'

  },
  [AUTH_LOGOUT]: (state) => {
    state.token = ''
    state.status = ''
    state.profile = {
      email: ''
    }
  },
  [AUTH_SOCIAL_TOKENS]: (state, resp) => {
    state.socialTokens = resp.tokens
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
