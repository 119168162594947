<template>
  <div>
    <scroll-loader
      class="dash-loader"
      :loader-method="()=> {}"
      :loader-enable="!loadingStatus.data"
    ></scroll-loader>

    <Dash-overview-one v-if="getSelectedPages.length == 1 && loadingStatus.data" />
    <Dash-overview-many v-if="getSelectedPages.length > 1 && loadingStatus.data" />
  </div>
</template>

<script>
import DashOverviewOne from "./Dash-overview-one";
import DashOverviewMany from "./Dash-overview-many";

import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "Dash-overview",
  components: {
    DashOverviewOne,
    DashOverviewMany
  },
  mounted() {},
  computed: {
    ...mapGetters(["getSelectedPages", "loadingStatus"])
  }
};
</script>

<style>
</style>