<template>
  <div>
    <div v-columns="{ columns: 1, breakpoints: { 780: 2 } }" class="audience columns-list">
      <div class="panel" style="min-height: 350px" v-if="canShowCountries">
        <div class="aud-header">
          <h2>{{ $t('DASHBOARD_AUDIENCE_CHARTS_COUNTRIES') }}</h2>
        </div>
        <div class="countries">
          <div
            class="one-country"
            :key="index"
            v-for="(country, index) in topCountries"
          >
            <div class="m-chart">
              <div
                class="chart"
                :ref="`country${index}`"
                style="width: 100px; height: 100px"
              ></div>
            </div>

            <span class="country-code" :title="country.name">{{
              country.name
            }}</span>
          </div>
        </div>
      </div>
      <div class="panel" v-if="canShowAges">
        <div class="aud-header">
          <h2>{{ $t('DASHBOARD_AUDIENCE_CHARTS_AGES') }}</h2>
          <div class="settings">
            <button
              @click="setByAgeSelector(type)"
              :class="isActiveByAgeClass(type)"
              :key="index"
              v-for="(type, index) in typesList"
            >
              {{ type }}
            </button>
          </div>
        </div>
        <div class="cont" v-if="page.social == 'VK'">
          <div class="metrics-list">
            <div>to 18</div>
            <div>18-21</div>
            <div>21-24</div>
            <div>24-27</div>
            <div>27-30</div>
            <div>30-35</div>
            <div>35-45</div>
            <div>45+</div>
          </div>
          <div class="m-chart">
            <div
              class="chart"
              :ref="`ages`"
              style="width: 100%; height: 400px"
            ></div>
          </div>
        </div>
        <div class="cont" v-if="page.social != 'VK'">
          <div class="metrics-list">
            <div>13-17</div>
            <div>18-24</div>
            <div>25-34</div>
            <div>35-44</div>
            <div>45-54</div>
            <div>55-64</div>
            <div>65+</div>
          </div>
          <div class="m-chart">
            <div
              class="chart"
              :ref="`ages`"
              style="width: 100%; height: 350px"
            ></div>
          </div>
        </div>
      </div>

      <div class="panel heatmap" v-if="canShowOnline">
        <div class="aud-header">
          <h2>{{ $t('DASHBOARD_AUDIENCE_CHARTS_ONLINE') }}</h2>
        </div>
        <div class="m-chart">
          <div class="chart" :ref="`online`"></div>
          <div class="heat-legend" :ref="`online-legend`" :data-color-code="page.colorCode"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import pageCharts from "@/models/page-charts";
import RadarOne from "@/models/charts/radar-countries";
import gorizontalColumns from "@/models/charts/gorizontal-columns";
import HeatMap from "@/models/charts/heatMap";
import * as am4core from "@amcharts/amcharts4/core";
const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

import columnsDirective from "@/directives/columnsDirective.js";

export default {
  name: "Dash-audience-one",
  components: {},
  directives: {
    columns: columnsDirective
  },  
  computed: {
    ...mapGetters([
      "getAnalizedPages",
      "getUnionPagesData",
      "getAnalizedAdminPages",
    ]),
    page() {
      return this.getAnalizedPages[0];
    },
    locale() {
      return this.$locale.DASHBOARD.AUDIENCE_CHARTS;
    },
    canShowCountries() {
      return !!(this.page.is_admin && this.page.audienceStats.countries);
    },
    canShowAges() {
      return !!(
        this.page.is_admin &&
        (this.page.audienceStats.sex_age ||
          this.page.audienceStats.page_fans_gender_age)
      );
    },
    canShowOnline() {
      return !!(this.page.is_admin && this.page.audienceStats.online_followers);
    },
    topCountries() {
      const pages = this.getAnalizedAdminPages.filter(
        (page) => page.audienceStats.countries
      );
      const data = pageCharts.countries(pages).map((d) => {
        if (d.code == "RU") return { ...d, name: "Russia" };
        if (d.code == "US") return { ...d, name: "USA" };
        if (d.code == "A1") return { ...d, name: "Anonymous" };
        if (d.code == "EU") return { ...d, name: "EU" };

        const name = countries.getName(d.code, "en");
        if (name.length > 10) return { ...d, name: name.slice(0, 10) + "..." };
        return { ...d, name };
      });
      return data;
    },
  },
  data() {
    return {
      loaded: true,
      byCountrySelector: "All",
      byAgeSelector: "All",
      typesList: ["All", "Men", "Women"],

      charts: {
        countries: [],
        ages: [],
        online: [],
      },
      rendered: false,
      loaded: false
    };
  },
  methods: {
    isActiveByCountryClass(type) {
      if (this.byCountrySelector == type) return "active";
      return "";
    },
    setByCountrySelector(type) {
      this.byCountrySelector = type;
    },
    isActiveByAgeClass(type) {
      if (this.byAgeSelector == type) return "active";
      return "";
    },
    setByAgeSelector(type) {
      this.byAgeSelector = type;
      setTimeout(this.renderAgesChart, 0);
    },
    formatCountryName(name) {},
    async renderRadarCountriesCharts() {
      const pages = this.getAnalizedAdminPages;

      const calculateTotal = this.topCountries.reduce((acc, val) => {
        pages.forEach((_, i) => {
          acc[`count_${i}`]
            ? (acc[`count_${i}`] += val[`count_${i}`])
            : (acc[`count_${i}`] = val[`count_${i}`]);
        });
        return acc;
      }, {});

      const circleColors = [
        "#4979E2",
        "#8C49E2",
        "#F39930",
        "#09ADB8",
        "#E84A88",
        // "#F84E4EL",
        "#FFEC43",
        "#1FDC76",
        "#CDF335",
        "#60D9FF",
        "#1DABD8",
        // "#60D9FF",
        // "#B4ECFE",
        "#779501",
        // "#97BA0A",
        // "#B6DB22",
        "#C8EF2C",
        // "#F84E4E",
        // "#F98F8F",
        "#FAC2C2",        
      ];

      const chartPromises = this.topCountries.map((country, index) => {
        // console.log('country', country, index);
        return RadarOne({
          ref: this.$refs[`country${index}`],
          maxValue: calculateTotal,
          color: circleColors[index],
          country,
          pages,
        });
      });

      this.charts.countries = await Promise.all(chartPromises);
    },

    async renderAgesChart() {
      const { pages, datesRangePosts } = this.getUnionPagesData;
      if (this.charts.ages.length) this.charts.ages.dispose();
      const page = pages[0];
      const data = pageCharts.ages(pages, this.byAgeSelector);
      this.charts.ages = await gorizontalColumns({
        ref: this.$refs.ages,
        data,
        pages,
      });
    },
    renderOnlineChart() {
      if (this.page.social != "VK")
        return HeatMap({
          ref: this.$refs.online,
          page: this.page,
          refLegend: this.$refs["online-legend"],
        });
    },

    async renderCharts() {
      const chartPromises = [];
      
      if (this.canShowAges) {
        chartPromises.push(this.renderAgesChart());
      }
      if (this.canShowOnline) {
        chartPromises.push(this.renderOnlineChart());
      }
      if (this.canShowCountries) {
        chartPromises.push(this.renderRadarCountriesCharts());
      }

      await Promise.all(chartPromises);

    }

  },
  mounted() {
    setTimeout(this.renderCharts, 0);
  },
  updated() {},
  beforeDestroy() {
    setTimeout(am4core.disposeAllCharts, 0);
  },
};
</script>

<style></style>
