<template>
  <div class="sorting-panel">
    <div class="text">Sort By:</div>
    <ul class="sorts">
      <li>
        <button
          @click="DASH_SET_POSTS_SORT_TYPE('date')"
          :class="`btn-st-grey ${isActiveSort('date')}`"
        >
          {{ $t('SORT_DATE') }}
        </button>
      </li>
      <li v-if="visibilityByPages('likes', pageSocials)">
        <button
          @click="DASH_SET_POSTS_SORT_TYPE('likes')"
          :class="`btn-st-grey ${isActiveSort('likes')}`"
        >
          {{ $t('SORT_LIKES') }}
        </button>
      </li>
      <li v-if="visibilityByPages('reposts', pageSocials)">
        <button
          @click="DASH_SET_POSTS_SORT_TYPE('reposts')"
          :class="`btn-st-grey ${isActiveSort('reposts')}`"
        >
          {{ $t('SORT_SHARES') }}
        </button>
      </li>
      <li v-if="visibilityByPages('comments', pageSocials)">
        <button
          @click="DASH_SET_POSTS_SORT_TYPE('comments')"
          :class="`btn-st-grey ${isActiveSort('comments')}`"
        >
          {{ $t('SORT_COMMS') }}
        </button>
      </li>
      <li v-if="visibilityByPages('views', pageSocials)">
        <button
          @click="DASH_SET_POSTS_SORT_TYPE('views')"
          :class="`btn-st-grey ${isActiveSort('views')}`"
        >
          {{ $t('SORT_VIEWS') }}
        </button>
      </li>
      <li v-if="visibilityByPages('erPost', pageSocials)">
        <button
          @click="DASH_SET_POSTS_SORT_TYPE('er')"
          :class="`btn-st-grey ${isActiveSort('er')}`"
        >
          ER
        </button>
      </li>
      <li v-if="visibilityByPages('erView', pageSocials)">
        <button
          @click="DASH_SET_POSTS_SORT_TYPE('er_views')"
          :class="`btn-st-grey ${isActiveSort('er_views')}`"
        >
          ER Views
        </button>
      </li>
      <li v-if="visibilityByAdminPages(pageSocials)">
        <button
          @click="DASH_SET_POSTS_SORT_TYPE('ir')"
          :class="`btn-st-grey ${isActiveSort('ir')}`"
        >
          IR
        </button>
      </li>
      <li v-if="visibilityByAdminPages(pageSocials)">
        <button
          @click="DASH_SET_POSTS_SORT_TYPE('er_progressive')"
          :class="`btn-st-grey ${isActiveSort('er_progressive')}`"
        >
          ER progressive
        </button>
      </li>
      <li v-if="visibilityByAdminPages(pageSocials)">
        <button
          @click="DASH_SET_POSTS_SORT_TYPE('reach_viral')"
          :class="`btn-st-grey ${isActiveSort('reach_viral')}`"
        >
          {{ $t('STATS_POSTS_RICH_VIRAL') }}
        </button>
      </li>
      <li v-if="visibilityByAdminPages(pageSocials)">
        <button
          @click="DASH_SET_POSTS_SORT_TYPE('reach_subscribers')"
          :class="`btn-st-grey ${isActiveSort('reach_subscribers')}`"
        >
          {{ $t('DASHBOARD_REACH_CHARTS_reach_subscribers') }}
        </button>
      </li>
      <li v-if="visibilityByAdminPages(pageSocials)">
        <button
          @click="DASH_SET_POSTS_SORT_TYPE('reach_total')"
          :class="`btn-st-grey ${isActiveSort('reach_total')}`"
        >
          {{ $t('DASHBOARD_REACH_CHARTS_reach') }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { DASH_SET_POSTS_SORT_TYPE } from "../../../store/actions/dash";

import pageUtils from "@/models/page-utils";

export default {
  name: "Dash-posts-feed-sort-panel",
  computed: {
    ...mapGetters(["getPostsSortStatus", "getPostsSortActiveType"]),
  },
  methods: {
    ...mapMutations([DASH_SET_POSTS_SORT_TYPE]),
    isActiveSort(type) {
      if (type != this.getPostsSortActiveType) return "";
      if (!this.getPostsSortStatus) return "active negative";
      return "active positive";
    },
    visibilityByPages: pageUtils.visibilityByPages,
    visibilityByAdminPages: pageUtils.visibilityByAdminPages,
  },
  props: {
    pageSocials: Array,
  },
};
</script>

<style>
</style>