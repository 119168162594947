import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { stylingTooltip } from "../chart-utils";
//import am4themes_animated from "@amcharts/amcharts4/themes/animated";

const create = function ({ ref, country, color, maxValue, pages }) {
  return new Promise((resolve, rejects) => {

    // container.hoverOnFocus = true
    am4core.options.queue = true;
    const chart = am4core.create(ref[0], am4charts.RadarChart);

    const data = []
    let commonTooltipHtml = ''

    chart.padding(0, 0, 0, 0);

    chart.colors.step = 2;

    chart.innerRadius = am4core.percent(100 + pages.length * 20);

    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";


    categoryAxis.renderer.labels.template.location = 0.5;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.strokeOpacity = 0.07;
    categoryAxis.mouseEnabled = false;

    categoryAxis.renderer.grid.template.strokeOpacity = 2;



    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.labels.template.horizontalCenter = "left";
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;
    valueAxis.renderer.minGridDistance = 10;
    valueAxis.renderer.axisFills.template.disabled = true;
    valueAxis.mouseEnabled = false;
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.tooltip.disabled = true;
    valueAxis.cursorTooltipEnabled = false;

    let series1, series2
    pages.forEach((page, i) => {
      const persentofTotal =
        parseFloat(((country[`count_${i}`] / maxValue[`count_${i}`]) * 100).toFixed(0))
      data.push({
        category: page.owner_name,
        [`value1_${i}`]: persentofTotal,
        [`value2_${i}`]: 100 - persentofTotal
      })
      chart.colors.list.push(am4core.color(page.colorCode));
      // if (page.stats.total[name])
      commonTooltipHtml += `<li>
    						<div class="soc-sea ${page.colorName
        }"><span class="soc-ico soc-${page.social.toLowerCase()}"></span>${page.owner_name_medium}</div>${persentofTotal}%
    					</li>`;
      series1 = chart.series.push(new am4charts.RadarColumnSeries());
      // series1.name = `${country.name}`;
      series1.dataFields.categoryY = "category";
      series1.dataFields.valueX = `value1_${i}`;
      series1.stacked = true;
      series1.columns.template.fill = color ? am4core.color(color) : am4core.color(page.colorCode)
      series1.columns.template.strokeWidth = 0;
      // series1.columns.template.strokeWidth = 
      series1.columns.template.width = am4core.percent(50);


      series2 = chart.series.push(new am4charts.RadarColumnSeries());

      series2.value = persentofTotal
      series2.dataFields.categoryY = "category";
      series2.dataFields.valueX = `value2_${i}`;
      series2.stacked = true;
      series2.columns.template.fill = am4core.color('#F0F1F4')
      series2.columns.template.strokeWidth = 0;
      series2.columns.template.width = am4core.percent(50);
      series1.columns.template.radarColumn.cornerRadius = 30;

      series2.columns.template.radarColumn.cornerRadius = 30;

    })


    chart.data = data


    chart.seriesContainer.zIndex = 1;

    chart.cursor = new am4charts.RadarCursor();
    chart.cursor.lineY.disabled = true;
    chart.cursor.lineX.disabled = true

    if (pages.length == 1) {
      let valueLabel = chart.createChild(am4core.Label);
      valueLabel.text = `${data[0]['value1_0']}%`;
      valueLabel.fontSize = 12;
      valueLabel.align = "center";
      valueLabel.color = "#325256";
      valueLabel.y = am4core.percent(50);
      valueLabel.x = am4core.percent(50);
      valueLabel.isMeasured = false;
      valueLabel.horizontalCenter = "middle";
      valueLabel.verticalCenter = "middle";
      valueLabel.dy = 2;
      valueLabel.zIndex = 2

    } else {

      chart.plotContainer.tooltipHTML =
        `<div class='tooltip'><span class='date'>{name}</span><ul class='tooltip-list'>` +
        commonTooltipHtml +
        "</ul></div>";
      stylingTooltip(chart.plotContainer)
      chart.plotContainer.tooltip.dy = 50 + pages.length * 7;



      chart.cursor.dy = -25
      chart.cursor.dx = -25
      chart.cursor.behavior = "none";

      // chart.cursor.fullWidthLineY = true;
      // chart.cursor.lineY.strokeWidth = 0;
      // chart.cursor.lineY.fill = am4core.color("#565656");
      // chart.cursor.lineY.fillOpacity = 0.1;
      // chart.cursor.lineX.strokeWidth = 0;
      // chart.cursor.lineX.fill = am4core.color("#565656");
      // chart.cursor.lineX.fillOpacity = 0.1;

      chart.cursor.events.on("cursorpositionchanged", function (ev) {
        chart.cursor.triggerMove(ev.target.point, "soft");
      });

    }



    chart.events.on("ready", () => {
      resolve(chart)
    });
  })


}

export default create;