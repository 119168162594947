import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

const create = function ({ ref, chartData, chartColumns, colors, pages }) {
  return new Promise((resolve, rejects) => {
    am4core.options.queue = true;
    const chart = am4core.create(ref[0], am4charts.RadarChart);

    const data = []
    let commonTooltipHtml = ''

    chart.padding(0, 0, 0, 0);
    am4core.options.autoSetClassName = true;
    am4core.options.classNamePrefix = "lucmus-";
    // chart.colors.step = 2;

    // Object.values(colors).map(color => chart.colors.list.push(am4core.color(color)));

    chart.innerRadius = am4core.percent(100 + pages.length * 20);

    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.labels.template.location = 0.5;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.tooltipLocation = 0.5;
    categoryAxis.renderer.grid.template.strokeOpacity = 0.07;
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.mouseEnabled = false;
    categoryAxis.tooltip.disabled = true;
    categoryAxis.renderer.labels.template.disabled = true;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.grid.template.strokeOpacity = 0;
    categoryAxis.cursorTooltipEnabled = false;

    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.labels.template.horizontalCenter = "left";
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;
    valueAxis.renderer.maxLabelPosition = 0.99;
    valueAxis.renderer.minGridDistance = 10;
    valueAxis.renderer.grid.template.strokeOpacity = 0.07;
    valueAxis.renderer.axisFills.template.disabled = true;
    valueAxis.mouseEnabled = false;
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.renderer.grid.template.strokeOpacity = 0;
    valueAxis.cursorTooltipEnabled = false;
    // valueAxis.tooltip.disabled = true;
    valueAxis.renderer.labels.template.fontSize = 8
    valueAxis.renderer.labels.template.fontWeight = '700'
    let series
    const totalValue = chartColumns.reduce((acc, val) => acc + chartData.reduce((a, v) => a + v[val], 0), 0)
    pages.forEach((page, i) => {
      let persentofTotal
      if (chartData[i]) {
        persentofTotal =
          parseFloat(((chartData[i][chartColumns[0]] / totalValue) * 100).toFixed(0))
      } else {
        persentofTotal = 0
      }

      if (isNaN(persentofTotal)) persentofTotal = 0

      data.push({
        category: i,
        [`value_${i}`]: persentofTotal,
      })

      const series1 = chart.series.push(new am4charts.RadarColumnSeries());
      series1.dataFields.categoryY = "category";
      series1.dataFields.valueX = `value_${i}`;
      series1.stacked = true;
      series1.columns.template.strokeWidth = 0;
      series1.columns.template.fill = am4core.color(page.colorCode);

      series1.columns.template.radarColumn.cornerRadius = 30;
      series1.columns.template.width = am4core.percent(60);
      var hoverState = series1.columns.template.states.create("hover");
      hoverState.properties.strokeWidth = 2;
      hoverState.properties.stroke = am4core.color(page.colorCode);
    })

    chart.data = data

    chart.seriesContainer.zIndex = 1;
    chart.cursor = new am4charts.RadarCursor();
    chart.cursor.behavior = "none";
    chart.cursor.lineY.disabled = true;
    chart.cursor.lineX.disabled = true

    function removeClipPaths(element) {
      element.removeAttribute('clip-path');
      const children = element.querySelectorAll('[clip-path]');
      children.forEach(child => {
        child.removeAttribute('clip-path');
      });
    }
    function removeAllClipPaths() {
      const radarChartGroups = document.querySelectorAll('.lucmus-RadarChart-group');
      radarChartGroups.forEach(group => {
        removeClipPaths(group);
      });
    }

    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'clip-path') {
          removeAllClipPaths();
        }
      });
    });

    observer.observe(chart.svgContainer.SVGContainer, {
      attributes: true,
      subtree: true
    });

    chart.events.on("inited", () => {
      removeAllClipPaths();
      resolve({ chart, colors: pages.map(p => p.colorCode) });
    });
  })


}

export default create;