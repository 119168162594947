<template>
  <div>
    <scroll-loader
      class="dash-loader"
      :loader-method="()=> {}"
      :loader-enable="!loadingStatus.data || !loadingStatus.storiesAttachments"
    ></scroll-loader>

    <Dash-stories-feed-one
      v-if="
      getSelectedPages.length == 1 
      && loadingStatus.data 
      && loadingStatus.storiesAttachments"
    />
    <Dash-stories-feed-many
      v-if="
      getSelectedPages.length > 1 
      && loadingStatus.data 
      && loadingStatus.storiesAttachments"
    />
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import DashStoriesFeedOne from "./Dash-stories-feed-one";
import DashStoriesFeedMany from "./Dash-stories-feed-many";
import { DASH_LOAD_STORIES_ATTACHMENTS } from "../../../store/actions/dash";

export default {
  name: "Dash-posts-feed",
  computed: {
    ...mapGetters(["getSelectedPages", "loadingStatus", "getAnalizedPages"]),
    isDataLoaded() {
      return this.loadingStatus.data;
    },
  },
  methods: {
    ...mapActions([DASH_LOAD_STORIES_ATTACHMENTS]),
  },
  watch: {
    isDataLoaded(newCount, oldCount) {
      if (newCount == true) this.DASH_LOAD_STORIES_ATTACHMENTS();
    },
  },
  components: {
    DashStoriesFeedOne,
    DashStoriesFeedMany,
  },
  mounted() {
    if (this.isDataLoaded) this.DASH_LOAD_STORIES_ATTACHMENTS();
  },
};
</script>

<style>
</style>