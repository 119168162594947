import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { stylingTooltip } from "../chart-utils";
//import am4themes_animated from "@amcharts/amcharts4/themes/animated";

const formatLabels = (data) => {
  return data.map(day => {
    return {
      ...day,
      unix: day.date,
      date: moment(day.date).format("MMM DD"),

    }
  }).sort((a, b) => a.unix - b.unix)
} 

const create = ({ page, ref, chartData, fields, category, color }) => {
  return new Promise((resolve, rejects) => {
    am4core.options.queue = true;
    const container = am4core.create(ref[0], am4core.Container);
    container.width = am4core.percent(100);
    container.height = am4core.percent(100);
    container.hoverOnFocus = true;
    const chart = container.createChild(am4charts.XYChart);
    container.padding = (0, 0, 0, 0)
    chart.data = formatLabels(chartData);

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = category;
    categoryAxis.renderer.minGridDistance = 50;
    categoryAxis.renderer.grid.template.location = 0.5;
    categoryAxis.renderer.grid.template.disabled = false;
    categoryAxis.renderer.grid.template.strokeDasharray = "";
    categoryAxis.renderer.labels.template.disabled = true;
    categoryAxis.cursorTooltipEnabled = false;
    categoryAxis.renderer.labels.template.disabled = false;
    categoryAxis.renderer.labels.template.fontSize = 10
    categoryAxis.renderer.labels.template.fontWeight = '700'

    categoryAxis.renderer.labels.template.events.on("over", function (ev) {
      var point = categoryAxis.categoryToPoint(ev.target.dataItem.category);
      chart.cursor.triggerMove(point, "soft");
    });

    categoryAxis.renderer.labels.template.events.on("out", function (ev) {
      var point = categoryAxis.categoryToPoint(ev.target.dataItem.category);
      chart.cursor.triggerMove(point, "none");
    });

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.cursorTooltipEnabled = false;
    valueAxis.renderer.grid.template.disabled = false;
    valueAxis.renderer.grid.template.strokeDasharray= "1,8";
    valueAxis.renderer.labels.template.fontSize = 8
    valueAxis.renderer.labels.template.fontWeight = '700'
    valueAxis.renderer.minGridDistance = 40;
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.baseGrid.disabled = true;

    chart.colors.list = [];

    fields.forEach((item, index) => {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.columns.template.width = am4core.percent(70);
      series.dataFields.valueY = item.field;
      series.dataFields.categoryX = category;
      series.dataFields.categoryY = 'formatDate'
      series.name = item.field;
      series.stacked = item.stacked;

      const columnTemplate = series.columns.template;
      columnTemplate.column.cornerRadius(3, 3, 3, 3);
      columnTemplate.column.padding(1, 0, 1, 0);
      columnTemplate.strokeOpacity = 0;
      columnTemplate.maxWidth = 20
      
      var bullet = series.bullets.push(new am4charts.LabelBullet);
      series.fill = am4core.color(page.colorCode)
      bullet.label.truncate = false;
      bullet.label.hideOversized = false;
      bullet.label.horizontalCenter = "left";
      bullet.locationY = 1;
      bullet.dy = 10;
      bullet.fontSize = 9

      series.columns.template.adapter.add("fill", function(fill, target) {
        if (target.dataItem && (target.dataItem.valueY < 0)) {
          return am4core.color(page.colorCode).brighten(-0.2).lighten(-0.2);
        }
        else {
          return fill;
        }
      });

      series.tooltip.animationDuration = 400;
      series.tooltip.defaultState.transitionDuration = 400;
      series.tooltip.hiddenState.transitionDuration = 400;

    });

    const firstSeries = chart.series.getIndex(0);

    let innerTooltipHtml = '';

    fields.forEach((item, index) => {

      innerTooltipHtml += `<li data-value="{${item.field}}">
          <div class="soc-sea ${color}">
            ${item.field}
          </div>{${item.field}}</li>`
    })

    firstSeries.tooltipHTML = `<div class='tooltip'>
    <span class='date'>{${category != 'growth' ? 'categoryX' : 'categoryY'}} 
      <span class="soc-sea ${color}">
        <!--<span class="soc-ico soc-${page.social.toLowerCase()}"></span>${page.owner_name_medium}-->
      </span>
    </span>
    
    
    <ul class='tooltip-list'>` +
      innerTooltipHtml +
      "</ul></div>";

    firstSeries.tooltip.label.adapter.add("html", function (text, target) {
      return text
    });
    stylingTooltip(firstSeries)

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.disabled = true;
    chart.cursor.xAxis = categoryAxis;
    chart.cursor.lineY.disabled = true;
    chart.cursor.events.on("cursorpositionchanged", function (ev) {
      chart.cursor.triggerMove(ev.target.point, "soft");
    });

    container.events.on("ready", () => {
      resolve(container)
    });

  })

}

export default create;