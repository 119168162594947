<template>
  <div ref="div">
    <a
      :href="item.type === 'reel' ? item.post_url : item.image_url"
      :class="item.type !== 'reel' ? 'glightbox-' + tuid : ''"
      v-for="(item, idx) in items"
      :key="idx"
      :data-gallery="tuid"
      :data-type="item.type == 'photo' ? 'image' : item.type"
      :target="item.type === 'reel' ? '_blank' : ''"
      rel="noopener noreferrer"
    >
      <img :src="item.image_url" loading="lazy"/>
      <div class="video-play" v-if="item.type === 'video' || item.type === 'reel'">
        <span></span>
      </div>
    </a>
  </div>
</template>

<script>
import GLightbox from "glightbox";

export default {
  name: "CollageItems",
  props: ["items", "className", "padding", "tuid", "befLoad"],
  data() {
    return {
      resizeTimeout: null
    };
  },
  mounted() {
    const promises = [];

    if (!this.$options.propsData?.items.length) return

    this.$refs.div.className = this.$options.propsData.className;

    Array.from(this.$refs.div.children).forEach((gl) => {
      promises.push(
        new Promise((resolve) => {
          gl.children[0].onload = resolve;
        })
      );
    });

    Promise.all(promises).then(function() {
      this.createCollage();
      const myGallery = GLightbox({
        selector: `.glightbox-${this.$options.propsData.tuid}`,
      });
      if (this.befLoad) {
        myGallery.on("slide_before_load", this.$options.propsData.befLoad);
      }
    }.bind(this));

    window.addEventListener("resize", this.resizes);
  },
  methods: {
    createCollage() {
      if (this.$refs.div === null) return;
      const settings = {
        albumWidth: 0,
        allowPartialLastRow: false,
        direction: "vertical",
        display: "inline-block",
        effect: "default",
        fadeSpeed: 2000,
        padding: 0,
        targetHeight: 200,
      };
      function resizeRow(obj, row, settings, rownum) {
        const imageExtras =
          settings.padding * (obj.length - 1) + obj.length * obj[0][3];
        const albumWidthAdjusted = settings.albumWidth - imageExtras;
        const overPercent = albumWidthAdjusted / (row - imageExtras);
        let trackWidth = imageExtras;
        const lastRow = row < settings.albumWidth ? true : false;
        for (let i = 0; i < obj.length; i++) {
          // const $obj = $(obj[i][0]);
          let fw = Math.floor(obj[i][1] * overPercent);
          let fh = Math.floor(obj[i][2] * overPercent);
          const isNotLast = !!(i < obj.length - 1);
          if (settings.allowPartialLastRow === true && lastRow === true) {
            fw = obj[i][1];
            fh = obj[i][2];
          }
          trackWidth += fw;
          if (!isNotLast && trackWidth < settings.albumWidth) {
            if (settings.allowPartialLastRow === true && lastRow === true) {
              // fw = fw;
            } else {
              fw = fw + (settings.albumWidth - trackWidth);
            }
          }
          fw--;
          obj[i][0].style.width = fw + "px";
          obj[i][0].style.height = fh + "px";
          obj[i][0].style["margin-bottom"] = settings.padding + "px";
          obj[i][0].style["margin-right"] =
            (isNotLast ? settings.padding : 0) + "px";
          obj[i][0].style["display"] = settings.display;
          obj[i][0].style["vertical-align"] = "bottom";
          obj[i][0].style["overflow"] = "hidden";
        }
      }
      let row = 0,
        rownum = 1;
      let elements = [];
      settings.padding = this.$options.propsData.padding ? this.$options.propsData.padding : 0;
      settings.albumWidth = this.$refs.div.clientWidth;
      [...this.$refs.div.children].forEach((gl, index) => {
        const img = gl.children[0];

        img.style.width = "";
        img.style.height = "";
        img.style["margin-bottom"] = "";
        img.style["margin-right"] = "";
        img.style["display"] = "";
        img.style["vertical-align"] = "";
        img.style["overflow"] = "";

        const w = img.width;
        const bw =
          parseFloat(
            img.style["border-left-width"] ? img.style["border-left-width"] : 0
          ) +
          parseFloat(
            img.style["border-right-width"]
              ? img.style["border-right-width"]
              : 0
          );
        const h = img.height;
        const bh =
          parseFloat(
            img.style["border-top-width"] ? img.style["border-top-width"] : 0
          ) +
          parseFloat(
            img.style["border-bottom-width"]
              ? img.style["border-bottom-width"]
              : 0
          );
        //   img["data-w"] = w;
        //   img["data-h"] = h;

        const nw = Math.ceil((w / h) * settings.targetHeight);
        const nh = Math.ceil(settings.targetHeight);

        elements.push([img, nw, nh, bw, bh]);

        row += nw + bw + settings.padding;

        if (row > settings.albumWidth && elements.length !== 0) {
          resizeRow(elements, row - settings.padding, settings, rownum);
          row = 0;
          elements = [];
          rownum += 1;
        }
        if (
          this.$refs.div.children.length - 1 == index &&
          elements.length != 0
        ) {
          resizeRow(elements, row, settings, rownum);
          row = 0;
          elements = [];
          rownum += 1;
        }
      });
    },
    resizeRow(obj, row, settings, rownum) {
      const imageExtras =
        settings.padding * (obj.length - 1) + obj.length * obj[0][3];
      const albumWidthAdjusted = settings.albumWidth - imageExtras;
      const overPercent = albumWidthAdjusted / (row - imageExtras);
      let trackWidth = imageExtras;
      const lastRow = row < settings.albumWidth ? true : false;
      for (let i = 0; i < obj.length; i++) {
        // const $obj = $(obj[i][0]);
        let fw = Math.floor(obj[i][1] * overPercent);
        let fh = Math.floor(obj[i][2] * overPercent);
        const isNotLast = !!(i < obj.length - 1);
        if (settings.allowPartialLastRow === true && lastRow === true) {
          fw = obj[i][1];
          fh = obj[i][2];
        }
        trackWidth += fw;
        if (!isNotLast && trackWidth < settings.albumWidth) {
          if (settings.allowPartialLastRow === true && lastRow === true) {
            // fw = fw;
          } else {
            fw = fw + (settings.albumWidth - trackWidth);
          }
        }
        fw--;
        obj[i][0].style.width = fw + "px";
        obj[i][0].style.height = fh + "px";
        obj[i][0].style["margin-bottom"] = settings.padding + "px";
        obj[i][0].style["margin-right"] =
          (isNotLast ? settings.padding : 0) + "px";
        obj[i][0].style["display"] = settings.display;
        obj[i][0].style["vertical-align"] = "bottom";
        obj[i][0].style["overflow"] = "hidden";
      }
    },
    resizes() {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        this.createCollage();
      }, 300);
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.resizes);
  },
};
</script>
