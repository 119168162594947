<template >
  <div class="drpdown mx-datepicker">
    <label @click.stop.prevent="openCalendar">{{ label }}</label>
    <span class="expand"></span>

    <date-range-picker
      :autoApply="true"
      ref="picker"
      v-model="dateRange"
      @update="periodChanged"
      :date-format="dateFormat"
      :ranges="ranges"
    >
      <template #ranges="ranges">
        <div class="ranges">
          <ul>
            <li
              v-for="(range, name) in ranges.ranges"
              :key="name"
              @click="ranges.clickRange(range)"
            >
              <b>{{ name }}</b>
              <small class="text-muted"
                >{{ range[0].toDateString() }} -
                {{ range[1].toDateString() }}</small
              >
            </li>
          </ul>
        </div>
      </template>
    </date-range-picker>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import DateRangePicker from "vue2-daterange-picker";
import { INTRO_SET_PERIOD } from "../store/actions/intro";

export default {
  name: "calendar",
  components: {
    DateRangePicker,
  },
  props: {
    callback: Function,
    disabledDates: Function,
  },
  computed: {
    ...mapGetters(["getSelectedPages", "getPeriod"]),
    label() {
      return `${moment(this.getPeriod.start).format("DD/MM/YY")} - ${moment(
        this.getPeriod.end
      ).format("DD/MM/YY")}`;
    },
  },
  methods: {
    ...mapMutations([INTRO_SET_PERIOD]),
    dateFormat(classes, date) {
      if (!classes.disabled) {
        if (this.disabledDates) classes.disabled = this.disabledDates(date);
        else classes.disabled = false;
      }
      return classes;
    },
    periodChanged(date) {
      const { startDate: start, endDate: end } = date;
      this.INTRO_SET_PERIOD({
        start: moment(start).format("YYYY-MM-DD"),
        end: moment(end).format("YYYY-MM-DD"),
      });
      this.callback();
    },
    openCalendar: function (ev) {
      this.$refs.picker.togglePicker();
    },
  },
  mounted() {
    this.dateRange = {
      startDate: moment(this.getPeriod.start).format("YYYY-MM-DD"),
      endDate: moment(this.getPeriod.end).format("YYYY-MM-DD"),
    };
  },
  data() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);

    const lastWeek = new Date();
    lastWeek.setDate(yesterday.getDate() - 6);
    lastWeek.setHours(0, 0, 0, 0);

    const lastTwoWeeks = new Date();
    lastTwoWeeks.setDate(yesterday.getDate() - 13);
    lastTwoWeeks.setHours(0, 0, 0, 0);

    const lastMonth = new Date();
    lastMonth.setDate(yesterday.getDate() - 27);
    lastMonth.setHours(0, 0, 0, 0);

    return {
      ranges: {
        "Last 7d": [
          new Date(
            lastWeek.getFullYear(),
            lastWeek.getMonth(),
            lastWeek.getDate()
          ),
          new Date(
            yesterday.getFullYear(),
            yesterday.getMonth(),
            yesterday.getDate()
          ),
        ],
        "Last 14d": [
          new Date(
            lastTwoWeeks.getFullYear(),
            lastTwoWeeks.getMonth(),
            lastTwoWeeks.getDate()
          ),
          new Date(
            yesterday.getFullYear(),
            yesterday.getMonth(),
            yesterday.getDate()
          ),
        ],

        "This month": [
          new Date(
            lastMonth.getFullYear(),
            lastMonth.getMonth(),
            lastMonth.getDate()
          ),
          new Date(
            yesterday.getFullYear(),
            yesterday.getMonth(),
            yesterday.getDate()
          ),
        ],
      },
      isShowCalendar: false,
      dateRange: {
        startDate: moment().subtract(7, "day").format("YYYY-MM-DD"),
        endDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
      },
    };
  },
};
</script>

<style>
</style>