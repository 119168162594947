import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { stylingTooltip } from "../chart-utils";
//import am4themes_animated from "@amcharts/amcharts4/themes/animated";

// am4core.useTheme(am4themes_animated);

const create = function ({ ref, chartData, chartColumns, colors }) {
  return new Promise((resolve, rejects) => {
    am4core.options.autoSetClassName = true;
    am4core.options.classNamePrefix = "lucmus-";
    am4core.options.queue = true;
    const chart = am4core.create(ref[0], am4charts.RadarChart);

    const data = []
    let commonTooltipHtml = ''

    chart.padding(0, 0, 0, 0);

    // chart.colors.step = 2;

    Object.values(colors).map(color => chart.colors.list.push(am4core.color(color)));

    chart.innerRadius = am4core.percent(100 + chartColumns.length * 25);

    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.labels.template.location = 0.5;
    categoryAxis.renderer.labels.template.horizontalCenter = "right";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.tooltipLocation = 0.5;
    categoryAxis.renderer.grid.template.strokeOpacity = 0.07;
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.mouseEnabled = false;
    categoryAxis.tooltip.disabled = true;
    categoryAxis.renderer.labels.template.disabled = true;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.grid.template.strokeOpacity = 0;
    categoryAxis.cursorTooltipEnabled = false;
    // categoryAxis.tooltip.disabled = true;

    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.renderer.labels.template.horizontalCenter = "left";
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;
    valueAxis.renderer.maxLabelPosition = 0.99;
    valueAxis.renderer.minGridDistance = 10;
    valueAxis.renderer.grid.template.strokeOpacity = 0.07;
    valueAxis.renderer.axisFills.template.disabled = true;
    valueAxis.mouseEnabled = false;
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.renderer.grid.template.strokeOpacity = 0;
    valueAxis.cursorTooltipEnabled = false;
    valueAxis.renderer.labels.template.fontSize = 8
    valueAxis.renderer.labels.template.fontWeight = '700'
    // valueAxis.tooltip.disabled = true;
    let series
    // 
    const totalValue = chartColumns.reduce((acc, val) => acc + chartData[val], 0)
    chartColumns.forEach((column, i) => {
      const persentofTotal =
        parseFloat(((chartData[column] / totalValue) * 100).toFixed(0))
      data.push({
        category: column,
        [`value_${i}`]: persentofTotal,
      })

      var series1 = chart.series.push(new am4charts.RadarColumnSeries());
      series1.dataFields.categoryY = "category";
      series1.dataFields.valueX = `value_${i}`;
      series1.stacked = true;
      series1.columns.template.strokeWidth = 0;
      series1.columns.template.radarColumn.cornerRadius = 30;
      series1.columns.template.height = 10

      series = series1;
      var hoverState = series.columns.template.states.create("hover");
        hoverState.properties.strokeWidth = 2;

    })

    chart.data = data
    chart.seriesContainer.zIndex = 1;

    chart.cursor = new am4charts.RadarCursor();
    chart.cursor.lineY.disabled = true;
    chart.cursor.behavior = "none";
    chart.cursor.lineX.disabled = true

    chart.events.on("ready", () => {

      resolve({ chart, colors })
    });
  })


}

export default create;