<template>
  <div
    class="header"
    v-click-outside="
      () => {
        showDropdown = false;
      }
    "
  >
    <div class="logo" @click="redirectToIntro()">
      <img src="@/assets/images/Main_Black.svg" alt />
    </div>
    <span class="dev"></span>
    <div class="search-panel" v-if="showInput">
      <i class="bi bi-search"></i>
      <input
        type="text"
        v-model="searchInput"
        class="search-panel"
        :placeholder="$t('HEADER_SEARCH')"
        size="auto"
      />
    </div>
    <div class="profile-panel">
      <div class="drpdown">
        <input type="checkbox" name id="z1" v-model="showDropdown" />
        <label for="z1" class="profiles-list">{{ getProfile.email }}</label>
        <span class="expand"></span>
        <ul class="drp-profile">
          <div class="plan-info">
            <div class="active-plan">
              {{ $t('PRC_PLN') }}: <span>Agency<!--тут тебе надо менять название плана по translations--></span>
            </div>
            <span class="plan-details">{{ $t('PRC_USED') }}: 5/10</span>
            <span class="exp-plan">{{ $t('PRC_EXPR') }}: 25.09.2024</span>
          </div>
          <div class="plan-info">
            <div class="active-plan">
              {{ $t('PRC_PLN') }}: <span>Free</span>
            </div>
            <span class="plan-details">{{ $t('PRC_USED') }}: 2/2</span>
            <a class="btn-st-red" href="/plans" target="_self">{{ $t('PRC_UPGR') }}</a>
          </div>
          <li
            @click.stop="
              INTRO_SET_ACCESS_MANAGER_VISIBLE(true);
              showDropdown = false;
            "
          >
            {{ $t('HEADER_ACCESS_MANAGE') }}
          </li>
          <!-- <li
            @click.stop="
              $router.push({ path: '/reports' });
              showDropdown = false;
            "
          >
            {{ $t('HEADER_REPORTS_MANAGE') }}
          </li> -->
          <li @click.stop="AUTH_LOGOUT">{{ $t('HEADER_LOG_OUT') }}</li>
          <div class="drp-options">
            <label for="io8" class="lang-set">
              <input type="checkbox" name="io8" id="io8">
              <i class="bi bi-globe"></i>
              <ul class="lang-list">
                <li @click="changeLanguage('en')">English</li>
                <li @click="changeLanguage('de')">Deutsch</li>
                <li @click="changeLanguage('es')">Español</li>
                <li @click="changeLanguage('pt')">Português</li>
                <li @click="changeLanguage('ru')">Русский</li>
              </ul>
            </label>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import {
  INTRO_SET_ACCESS_MANAGER_VISIBLE,
  INTRO_SET_SEARCH_INPUT,
  INTRO_SET_PAGES,
} from "../store/actions/intro";
import { AUTH_LOGOUT } from "@/store/actions/auth";
import ClickOutside from "vue-click-outside";

export default {
  name: "Header",
  props: {
    showInput: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["getProfile", "getSearchInputText"]),
    locale() {
    },
    searchInput: {
      get() {
        return this.getSearchInputText;
      },
      set(value) {
        this.INTRO_SET_SEARCH_INPUT(value);
      },
    },
  },
  data() {
    return {
      showDropdown: false,
    };
  },
  mounted() {
    this.popupItem = this.$el;
  },
  methods: {
    ...mapMutations([INTRO_SET_ACCESS_MANAGER_VISIBLE, INTRO_SET_SEARCH_INPUT]),
    ...mapActions([AUTH_LOGOUT, INTRO_SET_PAGES]),
    changeLanguage(lang) {
      this.$i18n.locale = lang;
    },
    redirectToIntro() {
      if (this.$route.path == "/main") this.INTRO_SET_PAGES();
      else this.$router.push({ path: "/main" });
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style></style>