<template>
  <div>
    <div class="post-stats" v-columns="{ columns: 1, breakpoints: { 550: 2, 1200: 3 } }">

      <div
        :class="chart.type == 'radar' ? 'panel' : 'panel types-radial'"
        :key="index"
        v-for="(chart, index) in chartNames"
      >
        <div class="panel-content" v-if="chart.type == 'radarMany'">
          <h2>{{ $t(`DASHBOARD_POSTS_STATS_CHARTS_${chart.name}`) }}</h2>
          <div
            class="content radar-chart"
            :key="i"
            v-for="(column, i) in [...chart.columns].reverse()"
          >
            <div class="m-chart">
              <div
                class="chart"
                :ref="`${chart.name}_${i}`"
              ></div>
            </div>
            <div class="p-stats-cont list" v-if="chart.data.length">
              <p>{{ column }}</p>
              <ul class="tooltip-list">
                <li :key="y" v-for="(page, y) in pagesWithPosts" @mouseover="onTextBlockMouseOver(chart, i, y, true)" @mouseleave="onTextBlockMouseLeave(chart, i)">
                  <div :class="`soc-sea ${page.colorName}`">
                    <span
                      :class="`soc-ico soc-${page.social.toLowerCase()}`"
                    ></span>
                    <span class="page-caption">{{ page.owner_name_medium }}</span>
                  </div>
                  {{ chart.data[y][[...chart.columnDataNames][i]] + "%" }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          v-if="chart.type == 'stacked' || chart.type == 'columns'"
          class="panel-content"
        >
          <h2>{{ $t(`DASHBOARD_POSTS_STATS_CHARTS_${chart.name}`) }}</h2>
          <div class="content bar-chart">
            <div class="m-chart">
              <div class="chart" :ref="chart.name"></div>
            </div>
          </div>
        </div>
        <div v-if="chart.type == 'radar'" class="panel-content">
          <h2>{{ $t(`DASHBOARD_POSTS_STATS_CHARTS_${chart.name}`) }}</h2>
          <div
            class="content radar-chart"
            :key="i"
            v-for="(page, i) in pagesWithPosts"
            v-show="chart.data[i]"
          >
            <div class="m-chart">
              <div
                class="chart"
                :ref="`${chart.name}_${i}`"
              ></div>
            </div>
            <div class="p-stats-cont" :style="`color: ${colorsByPages[i]}`">
              <div
                class="type"
                :key="chart.name + y"
                v-for="(column, y) in chart.columns"
                @mouseover="onTextBlockMouseOver(chart, i, y, false)"
                @mouseleave="onTextBlockMouseLeave(chart, i)"
              >
                <span v-if="chart.data[i]"
                  >{{ formatLabelStat(chart.data[i][chart.columnDataNames[y]])
                  }}{{ chart.percent ? "%" : "" }}</span
                >
                <b :style="`opacity: ${1 - (y / 10) * 2}`">{{ column }}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import * as am4core from "@amcharts/amcharts4/core";
import RadarPosts from "@/models/charts/radar-posts-stats";
import RadarManyPosts from "@/models/charts/radar-posts-stats-many-many";
import ColumnsPosts from "@/models/charts/columns";
import StackedPosts from "@/models/charts/stacked";
import columnsDirective from "@/directives/columnsDirective.js";

import pageCharts from "@/models/page-charts";

const columnContent = ["TEXT", "PHOTO", "VIDEO", "LINK"];
const columnLength = ["long", "medium", "short"];
const columnLengthEr = ["Short, ER", "Medium, ER", "Long, ER"];
const colors = [
  "#4979E2",
  "#8C49E2",
  "#F39930",
  "#09ADB8",
  "#E84A88",
  "#FFEC43",
  "#1FDC76",
  "#CDF335",
  "#60D9FF",
  "#1DABD8",
  "#779501",
  "#C8EF2C",
  "#FAC2C2",
];
export default {
  name: "Dash-posts-stats-many",
  components: {},
  directives: {
    columns: columnsDirective
  },  
  computed: {
    ...mapGetters([
      "getAnalizedPages",
      "getUnionPagesData",
      "getSocialOfAdminPageInAnalize",
      "getAnalizedAdminPages",
    ]),
    locale() {
      return this.$locale.DASHBOARD.POSTS_STATS_CHARTS;
    },
    commonColorsValues() {
      return Object.values(this.$colors);
    },
    pagesWithPosts() {
      return this.getAnalizedPages.filter((page) => page.posts.length);
    },
  },
  data() {
    return {
      loaded: false,
      colorsByPages: [],
      chartNames: [
        {
          name: "textLenCount",
          chart: RadarPosts,
          type: "radar",
          columns: columnLength,
          columnDataNames: ["l_cnt", "m_cnt", "s_cnt"],
          data: [],
          instances: null,
        },
        {
          name: "textLenEr",
          chart: RadarManyPosts,
          type: "radarMany",
          columns: columnLengthEr,
          columnDataNames: ["long_er", "medium_er", "short_er"],
          data: [],
          instances: null,
        },
        {
          name: "contentTypesCount",
          chart: RadarPosts,
          type: "radar",
          columns: columnContent,
          columnDataNames: ["text_cnt", "photo_cnt", "video_cnt", "link_cnt"],
          data: [],
          instances: null,
        },
        {
          name: "textLenActivity",
          chart: RadarPosts,
          type: "radar",
          columns: columnLength,
          columnDataNames: [
            "l_avg_activity",
            "m_avg_activity",
            "s_avg_activity",
          ],
          percent: true,
          data: [],
          instances: null,
        },
        {
          name: "contentTypesEr",
          chart: RadarManyPosts,
          type: "radarMany",
          columns: columnContent,
          columnDataNames: ["text_cnt", "photo_cnt", "video_cnt", "link_cnt"],
          data: [],
          instances: null,
        },
        {
          name: "contentTypesActivity",
          chart: RadarPosts,
          type: "radar",
          columns: columnContent,
          columnDataNames: [
            "t_avg_activity",
            "p_avg_activity",
            "v_avg_activity",
            "l_avg_activity",
          ],
          percent: true,
          data: [],
          instances: null,
        },
        {
          name: "hourActivity",
          chart: ColumnsPosts,
          type: "columns",
          columns: columnContent,
          field: "hour_activity",
          category: "label",
          data: [],
          instances: null,
        },
        {
          name: "weekActivity",
          chart: ColumnsPosts,
          type: "columns",
          columns: columnContent,
          category: "label",
          field: "week_activity",
          data: [],
          instances: null,
        },
        {
          name: "activityHoursTextLen",
          chart: StackedPosts,
          type: "stacked",
          columns: columnContent,
          category: "text",
          data: [],
          instances: null,
        },
        {
          name: "activityWeeksTextLen",
          chart: StackedPosts,
          type: "stacked",
          columns: columnContent,
          category: "text",
          data: [],
          instances: null,
        },
        {
          name: "erHoursTextLen",
          chart: StackedPosts,
          type: "stacked",
          columns: columnContent,
          category: "text",
          data: [],
          instances: null,
        },
        {
          name: "erWeeksTextLen",
          chart: StackedPosts,
          type: "stacked",
          columns: columnContent,
          category: "text",
          data: [],
          instances: null,
        },
        {
          name: "hashTagCount",
          chart: ColumnsPosts,
          type: "columns",
          columns: columnContent,
          field: "hashtag_cnt",
          category: "hashtag",
          data: [],
          instances: null,
        },
        {
          name: "hashTagErAvg",
          chart: ColumnsPosts,
          type: "columns",
          columns: columnContent,
          field: "hashtag_er_avg",
          category: "hashtag",
          data: [],
          instances: null,
        },
        {
          name: "hashTagActivityAvg",
          chart: ColumnsPosts,
          type: "columns",
          columns: columnContent,
          field: "hashtag_activity_avg",
          category: "hashtag",
          data: [],
          instances: null,
        },
      ],
    };
  },
  methods: {
    formatLabelStat(n) {
      return n && (n % 1 === 0 ? n : n.toFixed(2));
    },
    getColorCode(index) {
      const colors = Object.values(this.$colors);
      return colors[index];
    },
    prepareData() {
      const { posts } = this.getUnionPagesData;
      this.chartNames = this.chartNames
        .map((chart) => {
          let data;
          if (chart.type != "stacked" && chart.type != "columns")
            data = pageCharts[chart.name](posts);
          else data = pageCharts[chart.name](this.pagesWithPosts, posts);

          return {
            ...chart,
            data,
          };
        })
        .filter((chart) => Object.entries(chart.data).length);
    },
    async renderCharts() {
      this.colorsByPages = this.pagesWithPosts.map((p) => p.colorCode);
      this.prepareData();
      const that = this;

      const allChartPromises = this.chartNames.map((chart, i) => {
        const chartColumns = chart.columnDataNames;

        if (chart.type === "radar") {
          return that.pagesWithPosts.map((page, y) => {
            return chart.chart({
              ref: that.$refs[`${chart.name}_${y}`],
              pages: [that.pagesWithPosts[y]],
              chartData: chart.data[y],
              chartColumns,
              color: this.colorsByPages[y],
              field: chart.field,
              category: chart.category,
              title: that.locale[chart.name],
            });
          });
        } else if (chart.type === "radarMany") {
          return chart.columns.map((column, y) => {
            return chart.chart({
              ref: that.$refs[`${chart.name}_${y}`],
              pages: that.pagesWithPosts,
              chartData: chart.data,
              chartColumns: [chartColumns[y]],
              color: that.getColorCode(y),
              colors: that.$colors,
              field: chart.field,
              category: chart.category,
              title: that.locale[chart.name],
            });
          });
        } else if (chart.type === "stacked" || chart.type === "columns") {
          return chart.chart({
            ref: that.$refs[chart.name],
            pages: that.pagesWithPosts,
            chartData: chart.data,
            chartColumns,
            color: that.getColorCode(i),
            colors: that.$colors,
            field: chart.field,
            category: chart.category,
            title: that.locale[chart.name],
          });
        }
      });

      for (const [i, chartPromises] of allChartPromises.entries()) {
        if (Array.isArray(chartPromises)) {
          const chartInstances = await Promise.all(chartPromises);
          this.chartNames[i].instances = chartInstances;
        } else {
          this.chartNames[i].instances = await chartPromises;    
        }
      }

      this.loaded = true;
      setTimeout(() => {
        const el = document.getElementsByClassName("post-stats");
        if (el && el[0]) {
          el[0].classList.add("compare-pages");
        }
        // this.resizeAllGridItems();
      }, 0);
    },
    onTextBlockMouseOver(chart, panelIndex, seriesIndex, colorFlag) {
      let series = chart.instances[panelIndex].chart.series.getIndex(seriesIndex);
      if (series) {
        series.columns.template.strokeWidth = 2;
        series.columns.template.scale = 1.03;
        if (!colorFlag) {
          series.columns.template.stroke = am4core.color(chart.instances[panelIndex].color).lighten(seriesIndex*0.25);        
        } else {
          series.columns.template.stroke = am4core.color(chart.instances[panelIndex].colors[seriesIndex]);        
        }
      }
    },
    onTextBlockMouseLeave(chart, panelIndex, colorFlag) {
      chart.instances[panelIndex].chart.series.each((series, index) => {
        series.columns.template.scale = 1;
        if (chart.chart === RadarPosts) {
          series.columns.template.strokeWidth = 2;
          series.columns.template.stroke = am4core.color("#ffffff");
        } else {
          series.columns.template.strokeWidth = 0;
        }
      });
    },
  },
  mounted() {
    am4core.options.queue = true;
    setTimeout(this.renderCharts, 0);
    window.addEventListener("resize", this.resizeAllGridItems);
    setTimeout(() => {
      const el = document.getElementsByClassName("post-stats");
      if (el && el[0]) {
        el[0].classList.add("compare-pages");
      }
      // this.resizeAllGridItems();
    }, 0);
  },
  beforeDestroy() {
    setTimeout(() => {
      for (const chart of am4core.registry.baseSprites) {
        chart.disposeChildren();
        chart.dispose();
      }
    }, 0);
  },  
};
</script>

<style></style>
