<template>
  <div>
    <Dash-activity-body v-if="loadingStatus.data" />
    <scroll-loader
      class="dash-loader"
      :loader-method="()=> {}"
      :loader-enable="!loadingStatus.data"
    ></scroll-loader>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import DashActivityBody from "./Dash-activity-body";

export default {
  name: "Dash-activity",
  computed: {
    ...mapGetters(["getSelectedPages", "loadingStatus", "getAnalizedPages"])
  },
  components: {
    DashActivityBody
  }
};
</script>

<style>
</style>