<template>
  <paginate
    v-if="total>1"
    v-model="page"
    :page-count="total"
    :click-handler="click"
    :prev-text="'Prev'"
    :next-text="'Next'"
    :container-class="'pagination'"
    :page-class="'page-item'"
  ></paginate>
</template>

<script>
import Paginate from "vuejs-paginate";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { DASH_SET_PAGINATION_POSTS } from "../store/actions/dash";

export default {
  name: "pagination",
  components: {
    Paginate,
  },
  props: {
    callback: Function,
    total: Number,
  },
  methods: {
    ...mapMutations([DASH_SET_PAGINATION_POSTS]),

    click(num) {
      this.callback(num);
    },
  },
  computed: {
    ...mapGetters(["getPostsPageNum"]),
    page: {
      get() {
        return this.getPostsPageNum;
      },
      set(value) {
        this.DASH_SET_PAGINATION_POSTS(value);
      },
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="css">
</style>