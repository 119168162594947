<template>
  <div class="dash-body">
    <Dash-navbar />
    <Dash-overview v-if="getActiveTab == 'Overview'" />
    <Dash-activity v-if="getActiveTab == 'Activity'" />
    <Dash-posts-stats v-if="getActiveTab == 'Posts stats'" />
    <Dash-reach v-if="getActiveTab == 'Reach'" />
    <Dash-audience v-if="getActiveTab == 'Audience'" />
    <Dash-posts-feed v-if="getActiveTab == 'Posts Feed'" />
    <Dash-stories-feed v-if="getActiveTab == 'Stories Feed'" />
  </div>
</template>

<script>
import DashNavbar from "./Dash-navbar";
import DashOverview from "./Overview/Dash-overview";
import DashActivity from "./Activity/Dash-activity";
import DashPostsStats from "./PostsStats/Dash-posts-stats";
import DashReach from "./Reach/Dash-reach";
import DashPostsFeed from "./PostsFeed/Dash-posts-feed";
import DashStoriesFeed from "./StoriesFeed/Dash-stories-feed";
import DashAudience from "./Audience/Dash-audience";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "Dash-body",
  components: {
    DashNavbar,
    DashOverview,
    DashActivity,
    DashPostsStats,
    DashReach,
    DashPostsFeed,
    DashStoriesFeed,
    DashAudience,
  },
  computed: {
    ...mapGetters(["getActiveTab"]),
  },
};
</script>

<style>
</style>