import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import PageCharts from '../page-charts'
import { stylingTooltip } from "../chart-utils";

import i18n from '@/i18nSetup';



const agregate = (pages, datesRangePosts, datesRangeStories, name) => {
  const days = PageCharts[name]({ pages, datesRangePosts, datesRangeStories }).flat();
  for (const day of days) {
    day.formatDate = moment(day.date).format("MMM DD");
  }

  // if (name != 'subscribers') return days.slice(0, -1)
  return days;
}

const create = function ({ pages, datesRangePosts, datesRangeStories, name, ref, activePages, color, type }) {
  return new Promise((resolve, rejects) => {

    am4core.options.queue = true;
    if (activePages) {
      pages = pages.filter(p => activePages[p.hash_id])
    }
    const chart = am4core.create(
      ref,
      am4charts.XYChart
    );

    chart.paddingTop = 0;
    chart.paddingRight = 12;
    chart.paddingBottom = 0;
    chart.paddingLeft = 0;
    chart.colors.list = [];

    chart.numberFormatter.numberFormat = "#,###."
    

    if (type == 'overview-one')
      chart.colors.list = [am4core.color(window.colors.green)];
    else if ((type == 'activity' || type == 'reach') && pages.length === 1)
      chart.colors.list = [am4core.color(color.value)]


    // Add data
    chart.data = agregate(pages, datesRangePosts, datesRangeStories, name);
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.renderer.grid.template.location = 0.5;
    dateAxis.startLocation = 0.5;
    dateAxis.endLocation = 0.5;
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.labels.template.disabled = true;
    dateAxis.cursorTooltipEnabled = false;

    dateAxis.renderer.labels.template.disabled = false;
    dateAxis.renderer.labels.template.fontSize = 10
    dateAxis.renderer.labels.template.fontWeight = '700'

    // Create value axis
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.disabled = false;
    valueAxis.renderer.grid.template.strokeDasharray= "1,8";
    valueAxis.cursorTooltipEnabled = false;
    valueAxis.renderer.labels.template.fontSize = 8
    valueAxis.renderer.labels.template.fontWeight = '700'


    let series = [];

    // Create series
    if (chart.data.length) {
      pages.forEach((page, i) => {

        if (chart.data.some(item => item[`${name}_${i}`])) {

          series = chart.series.push(new am4charts.LineSeries());
          // series.dataFields.valueY = `${this.refName}_${i}`;
          series.dataFields.valueY = `${name}_${i}`;

          series.dataFields.dateX = "date";
          series.dataFields.categoryZ = 'formatDate'
          series.tooltip.animationDuration = 400;
          series.tooltip.defaultState.transitionDuration = 400;
          series.tooltip.hiddenState.transitionDuration = 400;
          series.strokeWidth = 1;
          series.tensionX = 0.8;
          series.tensionY = 0.9;
          series.connect = true;
          series.name = i;
          series.tooltip.pointerOrientation = 'right';
          if (

            (type == 'activity' || type == 'reach') && pages.length > 1 ||
            type == 'overview-many'
          ) {
            series.fill = am4core.color(page.colorCode)
            series.stroke = am4core.color(page.colorCode)
          }

          stylingTooltip(series);
          var bullet = series.bullets.push(new am4charts.CircleBullet());
          bullet.circle.strokeWidth = 10;
          bullet.circle.strokeOpacity = 0;
          bullet.fillOpacity = 0;
          bullet.strokeOpacity = 0;
          bullet.circle.radius = 4;

          var bulletState = bullet.states.create("hover");
          bulletState.properties.fillOpacity = 1;
          bulletState.properties.strokeOpacity = 1;
          bulletState.strokeOpacity = 0.3;

          var circleState = bullet.circle.states.create("hover");
          circleState.properties.strokeOpacity = 0.3;



          bullet.events.on('over', ({ target }) => {
            target.circle.isHover = true
          })
          bullet.events.on('out', ({ target }) => {
            target.circle.isHover = false
          })

          if (pages.length == 1) {
            const sorted = chart.data.map(d => d[`${name}_${i}`]).sort();
            series.fillOpacity = 0.1;
            const fillModifier = new am4core.LinearGradientModifier();
            const opacityMap = {};
            const opacityStep = Number(((1 / chart.data.length) * 0.5).toFixed(2));
            sorted.forEach((d, i) => {
              opacityMap[d] = Number((opacityStep * (i + 1)).toFixed(2));
            });
            const opacityArray = chart.data.map(d => opacityMap[d.name]);

            fillModifier.opacities = opacityArray;

            series.segments.template.fillModifier = fillModifier;

            series.fillModifier = fillModifier;
            // series.tooltip.pointerOrientation = 'right'

          }

        }

      });



      const firstSeries = chart.series.getIndex(0);

      if (firstSeries) {
        let innerTooltipHtml = '';


        if (type == 'overview-one') {
          innerTooltipHtml += `<li data-value-lines="{subscribers_0}">
          <div class="soc-sea ${pages[0].colorName
            }">${ i18n.t('INTRO_TABS_FOLLOWERS') }</div>{subscribers_0} 
        </li>` //*Archi - $t не работает
          firstSeries.tooltipHTML = "<div class='tooltip'><span class='date'>{categoryZ}</span><ul class='tooltip-list'>" +
            innerTooltipHtml +
            "</ul></div>";

          stylingTooltip(firstSeries);
        }

        else if ((type == 'activity' || type == 'reach') && pages.length === 1) {
          pages.forEach((page, i) => {
            innerTooltipHtml += `<li data-value-lines="{${name}_${i}}">
								<div class="soc-sea ${color.key
              }"><span class="soc-ico soc-${page.social.toLowerCase()}"></span>${page.owner_name_medium}</div>{${name}_${i}}
              </li>`
          })
          firstSeries.tooltipHTML = "<div class='tooltip'><span class='date'>{categoryZ}</span><ul class='tooltip-list'>" +
            innerTooltipHtml +
            "</ul></div>";

          stylingTooltip(firstSeries);
        } else if ((type == 'activity' || type == 'reach') && pages.length > 1 || type == 'overview-many') {
          pages.forEach((page, i) => {
            innerTooltipHtml += `<li data-value-lines="{${name}_${i}}">
								<div class="soc-sea ${page.colorName
              }"><span class="soc-ico soc-${page.social.toLowerCase()}"></span>${page.owner_name_medium}</div>{${name}_${i}}
              </li>`
          })
          chart.series.each((series) => {
            stylingTooltip(series)

            series.adapter.add("tooltipHTML", (tooltipHTML, target) => {

              if (target.tooltipDataItem && target.tooltipDataItem.dataContext) {

                const { day, date, formatDate, ...values } = target.tooltipDataItem.dataContext
                if (Object.entries(values).length == 1) {
                  tooltipHTML = "<div class='tooltip'><span class='date'>{categoryZ}</span><ul class='tooltip-list'>" +
                    innerTooltipHtml +
                    "</ul></div>";
                  return tooltipHTML
                } else if (Object.entries(values).length > 1) {
                  const firstKey = Object.entries(values).filter(([key, value]) => !!value).map(([key, _]) => key).sort().pop()
                  if (`${name}_${target.name}` === firstKey) {
                    tooltipHTML = "<div class='tooltip'><span class='date'>{categoryZ}</span><ul class='tooltip-list'>" +
                      innerTooltipHtml +
                      "</ul></div>";
                  }
                  return tooltipHTML
                }
              }

              return tooltipHTML;
            });
          });
        }




      }


    }
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.opacity = 0;
    chart.cursor.lineX.strokeDasharray = "";
    chart.cursor.lineX.stroke = am4core.color("#4979E2");
    chart.cursor.lineY.disabled = true;

    chart.cursor.xAxis = dateAxis;

    chart.cursor.lineY.disabled = true;




    chart.cursor.events.on("cursorpositionchanged", function (ev) {
      chart.cursor.triggerMove(ev.target.point, "soft");
      const xAxis = ev.target.chart.xAxes.getIndex(0);
      const xCoord = xAxis.positionToCoordinate(xAxis.toAxisPosition(ev.target.xPosition))
      // 
      chart.series.values.forEach(s => {
        if (xCoord < 100) s.tooltip.pointerOrientation = 'left'
        else s.tooltip.pointerOrientation = 'right'
      })

    });
    chart.events.on("ready", () => {
      // this.loading = true;
      resolve(chart)
    });
  })


}

export default create;