<template>
  <div>
    <Dash-audience-one
      v-if="getSelectedPages.length == 1 && loadingStatus.data"
    />
    <Dash-audience-many
      v-if="getSelectedPages.length > 1 && loadingStatus.data"
    />
    <scroll-loader
      class="dash-loader"
      :loader-method="() => {}"
      :loader-enable="!loadingStatus.data"
    ></scroll-loader>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import DashAudienceOne from "./Dash-audience-one";
import DashAudienceMany from "./Dash-audience-many";

export default {
  name: "Dash-audience",
  computed: {
    ...mapGetters(["getSelectedPages", "loadingStatus", "getAnalizedPages"]),
  },
  components: {
    DashAudienceOne,
    DashAudienceMany,
  },
};
</script>

<style>
</style>