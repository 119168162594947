<template>
  <div>
    <scroll-loader
      class="dash-loader"
      :loader-method="()=> {}"
      :loader-enable="!loadingStatus.data"
    ></scroll-loader>

    <Dash-posts-feed-one v-if="getSelectedPages.length == 1 && loadingStatus.data" />
    <Dash-posts-feed-many v-if="getSelectedPages.length > 1 && loadingStatus.data" />
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import DashPostsFeedOne from "./Dash-posts-feed-one";
import DashPostsFeedMany from "./Dash-posts-feed-many";

export default {
  name: "Dash-posts-feed",
  computed: {
    ...mapGetters(["getSelectedPages", "loadingStatus", "getAnalizedPages"])
  },
  components: {
    DashPostsFeedOne,
    DashPostsFeedMany
  }
};
</script>

<style>
</style>