import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import PageCharts from '../page-charts'
import { stylingTooltip } from "../chart-utils";
//import am4themes_animated from "@amcharts/amcharts4/themes/animated";

// am4core.useTheme(am4themes_animated);

const agregateFunctions = (names) => {
  return names.map(name => PageCharts[name])
}


const agregate = (pages, datesRangePosts, datesRangeStories, names) => {
  const days = agregateFunctions(names)
    .map(f => f({ pages, datesRangePosts, datesRangeStories })).flat();
  const result = {};


  for (const day of days) {
    day.formatDate = moment(day.date).format("MMM DD")


    result[day.formatDate] = {
      ...result[day.formatDate],
      ...day,
    };
  }
  return Object.values(result);
}

const create = ({ pages, datesRangePosts, datesRangeStories, names, ref, colors }) => {
  return new Promise((resolve, rejects) => {

    am4core.options.queue = true;
    const chart = am4core.create(
      ref,
      am4charts.XYChart
    );

    chart.marginRight = 400;

    // Add data
    chart.data = agregate(pages, datesRangePosts, datesRangeStories, names);


    // Create axes
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "formatDate";
    categoryAxis.renderer.labels.template.disabled = false;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.cursorTooltipEnabled = false;
      
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 60;
    categoryAxis.renderer.labels.template.disabled = false;
    categoryAxis.renderer.labels.template.fontSize = 10
    categoryAxis.renderer.labels.template.fontWeight = '700'


    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.cursorTooltipEnabled = false;
    valueAxis.renderer.grid.template.disabled = false;
    valueAxis.renderer.grid.template.strokeDasharray= "1,8";
    valueAxis.renderer.baseGrid.disabled = true;
    valueAxis.renderer.labels.template.fontSize = 8
    valueAxis.renderer.labels.template.fontWeight = '700'
    // Create series
    chart.colors.list = [];
    // 
    for (const color of colors) {
      chart.colors.list.push(am4core.color(color.value));
    }

    let firstSeries,
      commonTooltipHtml = "";

    if (chart.data.length) {
      for (const [index, value] of names.entries()) {
        const series = chart.series.push(new am4charts.ColumnSeries());
        // series.renderer.axisFills.template.fill = am4core.color("red");
        series.columns.template.width = am4core.percent(20);
        series.dataFields.valueY = `${value}_0`;
        series.dataFields.categoryX = "formatDate";
        series.name = value;


        if (index == 0) {
          firstSeries = series;
        }
        // 
        commonTooltipHtml += `<li>
								<div class="soc-sea ${colors[index].key
          }">${value}</div>{${value}_0}
							</li>`;


        series.stacked = true;
        const columnTemplate = series.columns.template;
        columnTemplate.column.cornerRadius(60, 60, 10, 10);
        columnTemplate.column.padding(1, 0, 1, 0);
        columnTemplate.strokeOpacity = 0;
        columnTemplate.maxWidth = 8
        series.tooltip.animationDuration = 400;
        series.tooltip.defaultState.transitionDuration = 400;
        series.tooltip.hiddenState.transitionDuration = 400;
      }


    }
    firstSeries.tooltipHTML =
      "<div class='tooltip'><span class='date'>{categoryX}</span><ul class='tooltip-list'>" +
      commonTooltipHtml +
      "</ul></div>";

    stylingTooltip(firstSeries)
    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.disabled = true;
    chart.cursor.lineX.stroke = am4core.color("#4979E2");


    chart.cursor.xAxis = categoryAxis;

    // chart.cursor.behavior = "selectX";
    chart.cursor.lineY.disabled = true;


    chart.cursor.events.on("cursorpositionchanged", function (ev) {
      chart.cursor.triggerMove(ev.target.point, "soft");
    });

    chart.events.on("ready", () => {
      resolve(chart)
    });

  })


}

export default create;