const translations = {
  "greeting": {
    "en": 'Hello, {name}!',
    "ru": 'Привет, {name}!',
  },
  "farewell": {
    "en": 'Goodbye, {name}!',
    "ru": 'До свидания, {name}!',
  },
  "AUTH_ERROR_LOGIN_FAILED_WRONG_PASS": {
    "en": "Wrong password, try again.",
    "ru": "Неверный пароль, попробуйте снова.",
    "es": "Contraseña incorrecta, inténtalo de nuevo.",
    "pt": "Senha errada, tente novamente.",
    "de": "Falsches Passwort, versuchen Sie es erneut."
  },
  "AUTH_ERROR_LOGIN_FAILED_USER_REGISTRATION_NOT_COMPLETED": {
    "en": "Registration is not complete. Check email.",
    "ru": "Регистрация не завершена. Проверьте электронную почту.",
    "es": "La registración no está completa. Verifica tu correo electrónico.",
    "pt": "O registro não está completo. Verifique o e-mail.",
    "de": "Registrierung ist nicht abgeschlossen. Überprüfen Sie die E-Mail."
  },
  "AUTH_ERROR_LOGIN_FAILED_USER_NOT_FOUND": {
    "en": "User not found",
    "ru": "Пользователь не найден",
    "es": "Usuario no encontrado",
    "pt": "Usuário não encontrado",
    "de": "Benutzer nicht gefunden"
  },
  "AUTH_ERROR_REGISTER_FAILED_USER_EXISTS": {
    "en": "User already register.",
    "ru": "Пользователь уже зарегистрирован.",
    "es": "El usuario ya está registrado.",
    "pt": "Usuário já registrado.",
    "de": "Benutzer ist bereits registriert."
  },
  "AUTH_COMMON_DONT_HAVE_ACCOUNT": {
    "en": "Don’t have an account?",
    "ru": "Нет аккаунта?",
    "es": "¿No tienes una cuenta?",
    "pt": "Não tem uma conta?",
    "de": "Haben Sie kein Konto?"
  },
  "AUTH_COMMON_EMAIL": {
    "en": "Email",
    "ru": "Email",
    "es": "E-mail",
    "pt": "E-mail",
    "de": "E-Mail"
  },
  "AUTH_COMMON_PASSWORD": {
    "en": "Password",
    "ru": "Пароль",
    "es": "Contraseña",
    "pt": "Senha",
    "de": "Passwort"
  },
  "AUTH_COMMON_CONFIRM": {
    "en": "Confirm password",
    "ru": "Подтвердите пароль",
    "es": "Confirmar contraseña",
    "pt": "Confirme a senha",
    "de": "Passwort bestätigen"
  },
  "AUTH_COMMON_SIGNIN_TO": {
    "en": "Sign in",
    "ru": "Войти",
    "es": "Iniciar sesión",
    "pt": "Entrar",
    "de": "Anmelden"
  },
  "AUTH_COMMON_SIGN_UP": {
    "en": "Sign up",
    "ru": "Зарегистрироваться",
    "es": "Registrarse",
    "pt": "Inscrever-se",
    "de": "Registrieren"
  },
  "AUTH_COMMON_ENTER": {
    "en": "Sign in",
    "ru": "Войти",
    "es": "Iniciar sesión",
    "pt": "Iniciar sessão",
    "de": "Anmelden"
  },
  "AUTH_COMMON_CONTINUE": {
    "en": "Continue",
    "ru": "Продолжить",
    "es": "Continuar",
    "pt": "Continuar",
    "de": "Weitermachen"
  },
  "AUTH_COMMON_VALID_EMAIL": {
    "en": "Should be valid email.",
    "ru": "Должен быть действительный адрес электронной почты.",
    "es": "Debe ser un correo electrónico válido.",
    "pt": "Deve ser um email válido.",
    "de": "Sollte eine gültige E-Mail sein."
  },
  "AUTH_COMMON_VALID_PASSWORD": {
    "en": "Password should contain at least 7 symbols and no space characters.",
    "ru": "Пароль должен содержать не менее 7 символов и не содержать пробелов.",
    "es": "La contraseña debe contener al menos 7 símbolos y no caracteres de espacio.",
    "pt": "A senha deve conter pelo menos 7 símbolos e nenhum caractere de espaço.",
    "de": "Das Passwort sollte mindestens 7 Symbole enthalten und keine Leerzeichen."
  },
  "AUTH_SIGNUP_CREATE": {
    "en": "Create your account",
    "ru": "Создайте свой аккаунт",
    "es": "Crea tu cuenta",
    "pt": "Crie sua conta",
    "de": "Erstelle deinen Account"
  },
  "AUTH_SIGNUP_ALREADY_HAVE": {
    "en": "Already have an account?",
    "ru": "Уже есть аккаунт?",
    "es": "¿Ya tienes una cuenta?",
    "pt": "Já tem uma conta?",
    "de": "Haben Sie schon ein Konto?"
  },
  "AUTH_SIGNUP_SENT": {
    "en": "Activation mail has been sent. Check your email",
    "ru": "Письмо с активацией отправлено. Проверьте свою электронную почту",
    "es": "El correo de activación ha sido enviado. Verifica tu correo electrónico",
    "pt": "O e-mail de ativação foi enviado. Verifique seu e-mail",
    "de": "Aktivierungsmail wurde gesendet. Überprüfen Sie Ihre E-Mails"
  },
  "AUTH_SIGNUP_NOT_EQUAL": {
    "en": "Passwords are not the same.",
    "ru": "Пароли не совпадают.",
    "es": "Las contraseñas no son las mismas.",
    "pt": "As senhas não são iguais.",
    "de": "Passwörter sind nicht gleich."
  },
  "AUTH_SIGNIN_SIGNIN_TO": {
    "en": "Sign in to your account",
    "ru": "Войдите в свой аккаунт",
    "es": "Inicia sesión en tu cuenta",
    "pt": "Entre na sua conta",
    "de": "Melden Sie sich bei Ihrem Konto an"
  },
  "AUTH_COMMON_FORGOT_YOUR_PASS": {
    "en": "Forgot your password?",
    "ru": "Забыли пароль?",
    "es": "¿Olvidaste tu contraseña?",
    "pt": "Esqueceu sua senha?",
    "de": "Passwort vergessen?"
  },
  "AUTH_SIGNIN_STAY_IN": {
    "en": "Stay signed in",
    "ru": "Оставаться в системе",
    "es": "Permanecer conectado",
    "pt": "Permanecer conectado",
    "de": "Angemeldet bleiben"
  },
  "AUTH_FORGOT_RECOVERY": {
    "en": "Password recovery",
    "ru": "Восстановление пароля",
    "es": "Recuperación de contraseña",
    "pt": "Recuperação de senha",
    "de": "Passwort-Wiederherstellung"
  },
  "AUTH_FORGOT_RESET": {
    "en": "To reset your password, enter the email associated with your account.",
    "ru": "Чтобы сбросить пароль, введите адрес электронной почты, связанный с вашей учетной записью.",
    "es": "Para restablecer tu contraseña, ingresa el correo electrónico asociado a tu cuenta.",
    "pt": "Para redefinir sua senha, insira o e-mail associado à sua conta.",
    "de": "Um Ihr Passwort zurückzusetzen, geben Sie die E-Mail-Adresse ein, die mit Ihrem Konto verbunden ist."
  },
  "AUTH_FORGOT_SENT": {
    "en": "Request for change password sent to your email.",
    "ru": "Запрос на изменение пароля отправлен на вашу электронную почту.",
    "es": "Solicitud para cambiar la contraseña enviada a tu correo electrónico.",
    "pt": "Solicitação para alterar a senha enviada para o seu e-mail.",
    "de": "Anfrage zur Passwortänderung an Ihre E-Mail gesendet."
  },
  "AUTH_CHANGE_NEW_PASS": {
    "en": "Write your new password",
    "ru": "Введите новый пароль",
    "es": "Escribe tu nueva contraseña",
    "pt": "Escreva sua nova senha",
    "de": "Schreiben Sie Ihr neues Passwort"
  },
  "AUTH_CHANGE_SENT": {
    "en": "Your password was changed. You will be redirected to login. Or you can do it directly by link",
    "ru": "Ваш пароль был изменен. Вы будете перенаправлены на страницу входа. Или вы можете сделать это по ссылке",
    "es": "Tu contraseña fue cambiada. Serás redirigido a iniciar sesión. O puedes hacerlo directamente por enlace",
    "pt": "Sua senha foi alterada. Você será redirecionado para o login. Ou você pode fazer isso diretamente por link",
    "de": "Ihr Passwort wurde geändert. Sie werden zum Login weitergeleitet. Oder Sie können es direkt per Link tun"
  },
  "AUTH_ACTIVATE_ACTIVATING": {
    "en": "Account is activating",
    "ru": "Аккаунт активируется",
    "es": "La cuenta se está activando",
    "pt": "A conta está ativando",
    "de": "Konto wird aktiviert"
  },
  "AUTH_ACTIVATE_ACTIVATED": {
    "en": "Your account is activated. You will be redirected to login page",
    "ru": "Ваш аккаунт активирован. Вы будете перенаправлены на страницу входа",
    "es": "Tu cuenta está activada. Serás redirigido a la página de inicio de sesión",
    "pt": "Sua conta está ativada. Você será redirecionado para a página de login",
    "de": "Ihr Konto ist aktiviert. Sie werden zur Login-Seite weitergeleitet"
  },
  "AUTH_ACTIVATE_NO_REDIRECT": {
    "en": "If it hasn't happened, click to",
    "ru": "Если этого не произошло, нажмите на",
    "es": "Si no ha sucedido, haz clic en",
    "pt": "Se isso não aconteceu, clique em",
    "de": "Wenn es nicht passiert ist, klicken Sie auf"
  },
  "HEADER_SEARCH": {
    "en": "Search...",
    "ru": "Поиск...",
    "es": "Buscar...",
    "pt": "Pesquisar...",
    "de": "Suche..."
  },
  "HEADER_ACCESS_MANAGE": {
    "en": "Manage accesses",
    "ru": "Управление доступом",
    "es": "Gestionar accesos",
    "pt": "Gerenciar acessos",
    "de": "Zugriffe verwalten"
  },
  "HEADER_REPORTS_MANAGE": {
    "en": "Manage reports",
    "ru": "Управление отчетами",
    "es": "Gestionar informes",
    "pt": "Gerenciar relatórios",
    "de": "Berichte verwalten"
  },
  "HEADER_LOG_OUT": {
    "en": "Log out",
    "ru": "Выйти",
    "es": "Cerrar sesión",
    "pt": "Sair",
    "de": "Abmelden"
  },
  "INTRO_START_EMPTY_LIST": {
    "en": "The pages list is empty",
    "ru": "Список страниц пуст",
    "es": "La lista de páginas está vacía",
    "pt": "A lista de páginas está vazia",
    "de": "Die Seitenliste ist leer"
  },"INTRO_START_START_JOURNEY": {
    "en": "Start using Lucmus right now with adding first page",
    "ru": "Начните использовать Lucmus прямо сейчас, добавив первую страницу",
    "es": "Comience a usar Lucmus ahora mismo agregando la primera página",
    "pt": "Comece a usar o Lucmus agora mesmo adicionando a primeira página",
    "de": "Beginnen Sie jetzt mit der Nutzung von Lucmus, indem Sie die erste Seite hinzufügen"
  },
  "INTRO_START_CURRENTLY_PLAN": {
    "en": "You are currently using FREE plan, so you can add only 1 page. Want more?",
    "ru": "Вы используете наш бесплатный план, поэтому вы можете добавить только одну страницу. Нужно больше?",
    "es": "Actualmente está utilizando el plan GRATUITO, por lo que solo puede agregar 2 páginas. ¿Quieres más?",
    "pt": "Você está usando o plano GRATUITO, então só pode adicionar 2 páginas. Quer mais?",
    "de": "Sie verwenden derzeit den KOSTENLOSEN Plan, sodass Sie nur 2 Seiten hinzufügen können. Möchten Sie mehr?"
  },
  "INTRO_START_PRICING": {
    "en": "See our pricing plans",
    "ru": "Посмотрите наши тарифные планы",
    "es": "Ver nuestros planes de precios",
    "pt": "Veja nossos planos de preços",
    "de": "Sehen Sie sich unsere Preispläne an"
  },
  "INTRO_START_ADD_PAGE": {
    "en": "+ Add page",
    "ru": "+ Добавить страницу",
    "es": "+ Agregar página",
    "pt": "+ Adicionar página",
    "de": "+ Seite hinzufügen"
  },
  "INTRO_MAIN_SOCIAL_MEDIA": {
    "en": "Social media",
    "ru": "Социальные сети",
    "es": "Redes sociales",
    "pt": "Mídia social",
    "de": "Soziale Medien"
  },
  "INTRO_MAIN_DISPLAYING_BY": {
    "en": "Displaying by:",
    "ru": "Группировка по:",
    "es": "Mostrando por:",
    "pt": "Exibindo por:",
    "de": "Anzeigen nach:"
  },
  "INTRO_MAIN_BY_NAME": {
    "en": "By Name",
    "ru": "По имени",
    "es": "Por nombre",
    "pt": "Por nome",
    "de": "Nach Name"
  },
  "INTRO_MAIN_BY_SOCIAL": {
    "en": "By Social",
    "ru": "По соц. сети",
    "es": "Por social",
    "pt": "Por social",
    "de": "Nach sozial"
  },
  "INTRO_MAIN_BY_OWNER": {
    "en": "By Owner",
    "ru": "По владельцу",
    "es": "Por propietario",
    "pt": "Por proprietário",
    "de": "Nach Besitzer"
  },
  "INTRO_TABS_NAME": {
    "en": "Page name",
    "ru": "Страница",
    "es": "Nombre de la página",
    "pt": "Nome da página",
    "de": "Seitenname"
  },
  "INTRO_TABS_FOLLOWERS": {
    "en": "Followers",
    "ru": "Подписчики",
    "es": "Seguidores",
    "pt": "Seguidores",
    "de": "Anhänger"
  },
  "INTRO_TABS_GROWTH": {
    "en": "Growth",
    "ru": "Рост",
    "es": "Crecimiento",
    "pt": "Crescimento",
    "de": "Wachstum"
  },
  "INTRO_TABS_REACH": {
    "en": "Reach",
    "ru": "Охват",
    "es": "Alcance",
    "pt": "Alcance",
    "de": "Erreichen"
  },
  "INTRO_TABS_ER": {
    "en": "ER",
    "ru": "ER",
    "es": "ER",
    "pt": "ER",
    "de": "ER"
  },
  "INTRO_TABS_SOCIAL": {
    "en": "Social",
    "ru": "Соц. сеть",
    "es": "Social",
    "pt": "Social",
    "de": "Sozial"
  },
  "INTRO_TABS_LINK": {
    "en": "Link",
    "ru": "Ссылка",
    "es": "Enlace",
    "pt": "Link",
    "de": "Verknüpfung"
  },
  "INTRO_TABS_DELETE": {
    "en": "Delete",
    "ru": "Удалить",
    "es": "Eliminar",
    "pt": "Excluir",
    "de": "Löschen"
  },
  "INTRO_NOTIFICATIONS_NOTIFICATION_TITLE": {
    "en": "Analysis is completed.",
    "ru": "Анализ завершен.",
    "es": "El análisis está completo.",
    "pt": "A análise está concluída.",
    "de": "Analyse ist abgeschlossen."
  },
  "DASHBOARD_TABS_OVERVIEW": {
    "en": "Overview",
    "ru": "Обзор",
    "es": "Visión general",
    "pt": "Visão geral",
    "de": "Überblick"
  },
  "DASHBOARD_TABS_ACTIVITY": {
    "en": "Activity",
    "ru": "Активность",
    "es": "Actividad",
    "pt": "Atividade",
    "de": "Aktivität"
  },
  "DASHBOARD_TABS_POSTS_STATS": {
    "en": "Posts stats",
    "ru": "Статистика постов",
    "es": "Estadísticas de publicaciones",
    "pt": "Estatísticas de postagens",
    "de": "Beitragsstatistiken"
  },
  "DASHBOARD_TABS_REACH": {
    "en": "Reach",
    "ru": "Охват",
    "es": "Alcance",
    "pt": "Alcance",
    "de": "Erreichen"
  },
  "DASHBOARD_TABS_AUDIENCE": {
    "en": "Audience",
    "ru": "Аудитория",
    "es": "Audiencia",
    "pt": "Audiência",
    "de": "Publikum"
  },
  "DASHBOARD_TABS_POSTS_FEED": {
    "en": "Posts feed",
    "ru": "Лента постов",
    "es": "Feed de publicaciones",
    "pt": "Feed de postagens",
    "de": "Beitrags-Feed"
  },
  "DASHBOARD_TABS_STORIES_FEED": {
    "en": "Stories feed",
    "ru": "Лента историй",
    "es": "Feed de historias",
    "pt": "Feed de histórias",
    "de": "Story-Feed"
  },
  "DASHBOARD_ACTIVITY_CHARTS_likes": {
    "en": "Likes",
    "ru": "Лайки",
    "es": "Me gusta",
    "pt": "Curtidas",
    "de": "Likes"
  },
  "DASHBOARD_ACTIVITY_CHARTS_comments": {
    "en": "Comments",
    "ru": "Комментарии",
    "es": "Comentarios",
    "pt": "Comentários",
    "de": "Kommentare"
  },
  "DASHBOARD_ACTIVITY_CHARTS_reposts": {
    "en": "Shares",
    "ru": "Репосты",
    "es": "Compartidos",
    "pt": "Compartilhamentos",
    "de": "Teilen"
  },
  "DASHBOARD_ACTIVITY_CHARTS_views": {
    "en": "Views",
    "ru": "Просмотры",
    "es": "Vistas",
    "pt": "Visualizações",
    "de": "Ansichten"
  },
  "DASHBOARD_ACTIVITY_CHARTS_posts": {
    "en": "Posts",
    "ru": "Посты",
    "es": "Publicaciones",
    "pt": "Postagens",
    "de": "Beiträge"
  },
  "DASHBOARD_ACTIVITY_CHARTS_stories": {
    "en": "Stories",
    "ru": "Истории",
    "es": "Historias",
    "pt": "Histórias",
    "de": "Geschichten"
  },
  "DASHBOARD_ACTIVITY_CHARTS_interactions": {
    "en": "Interactions",
    "ru": "Взаимодействия",
    "es": "Interacciones",
    "pt": "Interações",
    "de": "Interaktionen"
  },
  "DASHBOARD_ACTIVITY_CHARTS_subunsub": {
    "en": "Subscribed / Unsubscribed",
    "ru": "Подписки / Отписки",
    "es": "Suscrito / No suscrito",
    "pt": "Inscrito / Não inscrito",
    "de": "Abonniert / Abgemeldet"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_textLenCount": {
    "en": "Count by text length",
    "ru": "Количество по длине текста",
    "es": "Contar por longitud del texto",
    "pt": "Contagem por comprimento de texto",
    "de": "Anzahl nach Textlänge"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_textLenEr": {
    "en": "ER by text length",
    "ru": "ER по длине текста",
    "es": "ER por longitud del texto",
    "pt": "ER por comprimento de texto",
    "de": "ER nach Textlänge"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_contentTypesCount": {
    "en": "Count by content type",
    "ru": "Количество по типу контента",
    "es": "Contar por tipo de contenido",
    "pt": "Contagem por tipo de conteúdo",
    "de": "Anzahl nach Inhaltstyp"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_textLenActivity": {
    "en": "Text length relative activity",
    "ru": "Относительная активность по длине текста",
    "es": "Actividad relativa por longitud del texto",
    "pt": "Atividade relativa por comprimento de texto",
    "de": "Relative Aktivität nach Textlänge"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_contentTypesEr": {
    "en": "ER by content type",
    "ru": "ER по типу контента",
    "es": "ER por tipo de contenido",
    "pt": "ER por tipo de conteúdo",
    "de": "ER nach Inhaltstyp"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_contentTypesActivity": {
    "en": "Content type relative activity",
    "ru": "Относительная активность по типу контента",
    "es": "Actividad relativa por tipo de contenido",
    "pt": "Atividade relativa por tipo de conteúdo",
    "de": "Relative Aktivität nach Inhaltstyp"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_hourActivity": {
    "en": "Activity by hours of day",
    "ru": "Активность по часам суток",
    "es": "Actividad por horas del día",
    "pt": "Atividade por horas do dia",
    "de": "Aktivität nach Stunden des Tages"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_weekActivity": {
    "en": "Activity by days of week",
    "ru": "Активность по дням недели",
    "es": "Actividad por días de la semana",
    "pt": "Atividade por dias da semana",
    "de": "Aktivität nach Wochentagen"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_activityHoursTextLen": {
    "en": "Text length activity by hours of day",
    "ru": "Активность по длине текста по часам суток",
    "es": "Actividad por longitud del texto por horas del día",
    "pt": "Atividade por comprimento de texto por horas do dia",
    "de": "Aktivität nach Textlänge nach Stunden des Tages"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_activityWeeksTextLen": {
    "en": "Text length activity by days of week",
    "ru": "Активность по длине текста по дням недели",
    "es": "Actividad por longitud del texto por días de la semana",
    "pt": "Atividade por comprimento de texto por dias da semana",
    "de": "Aktivität nach Textlänge nach Wochentagen"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_erHoursTextLen": {
    "en": "Text length ER by hours of day",
    "ru": "ER по длине текста по часам суток",
    "es": "ER por longitud del texto por horas del día",
    "pt": "ER por comprimento de texto por horas do dia",
    "de": "ER nach Textlänge nach Stunden des Tages"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_erWeeksTextLen": {
    "en": "Text length ER by days of week",
    "ru": "ER по длине текста по дням недели",
    "es": "ER por longitud del texto por días de la semana",
    "pt": "ER por comprimento de texto por dias da semana",
    "de": "ER nach Textlänge nach Wochentagen"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_hashTagCount": {
    "en": "Tags count",
    "ru": "Количество тегов",
    "es": "Conteo de etiquetas",
    "pt": "Contagem de tags",
    "de": "Anzahl der Tags"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_hashTagErAvg": {
    "en": "ER by tags",
    "ru": "ER по тегам",
    "es": "ER por etiquetas",
    "pt": "ER por tags",
    "de": "ER nach Tags"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_hashTagActivityAvg": {
    "en": "Tags activity",
    "ru": "Активность тегов",
    "es": "Actividad de etiquetas",
    "pt": "Atividade de tags",
    "de": "Aktivität der Tags"
  },
  "DASHBOARD_REACH_CHARTS_reach": {
    "en": "Reach",
    "ru": "Охват",
    "es": "Alcance",
    "pt": "Alcance",
    "de": "Reichweite"
  },
  "DASHBOARD_REACH_CHARTS_reach_subscribers": {
    "en": "Reach followers",
    "ru": "Охват подписчиков",
    "es": "Alcanzar seguidores",
    "pt": "Alcance de seguidores",
    "de": "Follower erreichen"
  },
  "DASHBOARD_REACH_CHARTS_mobile_reach": {
    "en": "Mobile Reach",
    "ru": "Мобильный охват",
    "es": "Alcance móvil",
    "pt": "Alcance móvel",
    "de": "Mobile Reichweite"
  },
  "DASHBOARD_AUDIENCE_CHARTS_COUNTRIES": {
    "en": "By countries",
    "ru": "По странам",
    "es": "Por países",
    "pt": "Por países",
    "de": "Nach Ländern"
  },
  "DASHBOARD_AUDIENCE_CHARTS_AGES": {
    "en": "By ages",
    "ru": "По возрастам",
    "es": "Por edades",
    "pt": "Por idades",
    "de": "Nach Altersgruppen"
  },
  "DASHBOARD_DELETED": {
    "en": "Deleted pages",
    "ru": "Удаленные страницы",
    "es": "Páginas eliminadas",
    "pt": "Páginas excluídas",
    "de": "Gelöschte Seiten"
  },
  "DASHBOARD_AUDIENCE_CHARTS_ONLINE": {
    "en": "Online heatmap",
    "ru": "Онлайн карта активности",
    "es": "Mapa de calor en línea",
    "pt": "Mapa de calor online",
    "de": "Online-Heatmap"
  },
  "ADD_PAGE_SEARCH": {
    "en": "Search",
    "ru": "Поиск",
    "es": "Buscar",
    "pt": "Pesquisar",
    "de": "Suche"
  },
  "ADD_PAGE_FOUND": {
    "en": "FOUND",
    "ru": "НАЙДЕНО",
    "es": "ENCONTRADO",
    "pt": "ENCONTRADO",
    "de": "GEFUNDEN"
  },
  "ADD_PAGE_WANT_ADD": {
    "en": "List of pages you probably want to add",
    "ru": "Список страниц, которые вы, возможно, хотите добавить",
    "es": "Lista de páginas que probablemente desees agregar",
    "pt": "Lista de páginas que você provavelmente deseja adicionar",
    "de": "Liste von Seiten, die Sie wahrscheinlich hinzufügen möchten"
  },
  "ADD_PAGE_SHOW_PREVIOUS": {
    "en": "Show previous",
    "ru": "Показать предыдущие",
    "es": "Mostrar anteriores",
    "pt": "Mostrar anteriores",
    "de": "Vorherige anzeigen"
  },
  "ADD_PAGE_SELECT_PAGE": {
    "en": "Add",
    "ru": "Добавить",
    "es": "Agregar",
    "pt": "Adicionar",
    "de": "Hinzufügen"
  },
  "ADD_PAGE_DELETE_PAGE": {
    "en": "Delete",
    "ru": "Удалить",
    "es": "Eliminar",
    "pt": "Excluir",
    "de": "Löschen"
  },
  "ADD_PAGE_SHOW_NEXT": {
    "en": "Show next",
    "ru": "Показать следующие",
    "es": "Mostrar siguiente",
    "pt": "Mostrar próximo",
    "de": "Nächste anzeigen"
  },
  "ADD_PAGE_SIGN_IN_TOKENS": {
    "en": "Sign in with socials to connect the pages to your profile:",
    "ru": "Войдите через социальные сети, чтобы подключить страницы к своему профилю:",
    "es": "Inicia sesión con redes sociales para conectar las páginas a tu perfil:",
    "pt": "Faça login com redes sociais para conectar as páginas ao seu perfil:",
    "de": "Melden Sie sich mit sozialen Netzwerken an, um die Seiten mit Ihrem Profil zu verbinden:"
  },
  "ADD_PAGE_CURRENT_ACCOUNT": {
    "en": "Using account:",
    "ru": "Используемый аккаунт:",
    "es": "Usando cuenta:",
    "pt": "Usando conta:",
    "de": "Verwendetes Konto:"
  },
  "ADD_PAGE_COUNT_TO_ADD": {
    "en": "ADD {0} PAGE",
    "ru": "ДОБАВИТЬ {0} СТРАНИЦУ",
    "es": "AGREGAR {0} PÁGINA",
    "pt": "ADICIONAR {0} PÁGINA",
    "de": "{0} SEITE HINZUFÜGEN"
  },
  "ADD_PAGE_WAS_SELECTED": {
    "en": "Selected",
    "ru": "Выбрано",
    "es": "Seleccionado",
    "pt": "Selecionado",
    "de": "Ausgewählt"
  },
  "ADD_PAGE_PUT_URL": {
    "en": "Put the URL of the page",
    "ru": "Введите URL страницы",
    "es": "Pon la URL de la página",
    "pt": "Coloque o URL da página",
    "de": "Geben Sie die URL der Seite ein"
  },
  "ADD_PAGE_ERROR_NOTIFICATION_ERROR_FOUNDED_ACCOUNT_NOT_BUSINESS": {
    "en": "It's a private profile and can't be analyzed.",
    "ru": "Это частный профиль, и его нельзя проанализировать.",
    "es": "Es un perfil privado y no se puede analizar.",
    "pt": "É um perfil privado e não pode ser analisado.",
    "de": "Es ist ein privates Profil und kann nicht analysiert werden."
  },
  "ADD_PAGE_ERROR_NOTIFICATION_ERROR_UNEXPECTED": {
    "en": "Something happen with find this page. We will fix it ASAP.",
    "ru": "Что-то пошло не так при поиске этой страницы. Мы исправим это как можно скорее.",
    "es": "Ocurrió algo al encontrar esta página. Lo solucionaremos lo antes posible.",
    "pt": "Algo aconteceu com encontrar esta página. Vamos consertar o mais rápido possível.",
    "de": "Beim Finden dieser Seite ist etwas passiert. Wir werden es so schnell wie möglich beheben."
  },
  "ADD_PAGE_ERROR_NOTIFICATION_ERROR_FOUNDED_FB_GROUPS": {
    "en": "Fb groups cant be analyzed.",
    "ru": "Группы Fb не могут быть проанализированы.",
    "es": "No se pueden analizar los grupos de Fb.",
    "pt": "Grupos do Fb não podem ser analisados.",
    "de": "Fb-Gruppen können nicht analysiert werden."
  },
  "ADD_PAGE_ERROR_NOTIFICATION_ERROR_ACCOUNT_NO_BUSINESS": {
    "en": "Your account is not business. Change it in instagram settings and try again.",
    "ru": "Ваш аккаунт не бизнес. Измените это в настройках Instagram и попробуйте снова.",
    "es": "Tu cuenta no es de negocios. Cámbialo en la configuración de Instagram y vuelve a intentarlo.",
    "pt": "Sua conta não é comercial. Altere isso nas configurações do Instagram e tente novamente.",
    "de": "Ihr Konto ist kein Geschäftskonto. Ändern Sie dies in den Instagram-Einstellungen und versuchen Sie es erneut."
  },
  "ACCESS_MANAGER_RIGHTS_MANAGER": {
    "en": "Access rights manager",
    "ru": "Менеджер прав доступа",
    "es": "Gerente de derechos de acceso",
    "pt": "Gerenciador de direitos de acesso",
    "de": "Zugriffsrechte-Manager"
  },
  "ACCESS_MANAGER_GRANT_ACCESS": {
    "en": "Grant access",
    "ru": "Предоставить доступ",
    "es": "Conceder acceso",
    "pt": "Conceder acesso",
    "de": "Zugriff gewähren"
  },
  "ACCESS_MANAGER_FULL_ACCESS": {
    "en": "Full access",
    "ru": "Полный доступ",
    "es": "Acceso completo",
    "pt": "Acesso total",
    "de": "Voller Zugriff"
  },
  "ACCESS_MANAGER_READ_ONLY": {
    "en": "Read only",
    "ru": "Только чтение",
    "es": "Solo lectura",
    "pt": "Apenas leitura",
    "de": "Nur lesen"
  },
  "ACCESS_MANAGER_EMAIL": {
    "en": "Email",
    "ru": "E-mail",
    "es": "E-mail",
    "pt": "E-mail",
    "de": "E-Mail"
  },
  "ACCESS_MANAGER_ACCESS": {
    "en": "Access",
    "ru": "Доступ",
    "es": "Acceso",
    "pt": "Acesso",
    "de": "Zugriff"
  },
  "ACCESS_MANAGER_ACTION": {
    "en": "Action",
    "ru": "Действие",
    "es": "Acción",
    "pt": "Ação",
    "de": "Aktion"
  },
  "ACCESS_MANAGER_REMOVE_ACCESS": {
    "en": "Remove access",
    "ru": "Удалить доступ",
    "es": "Eliminar acceso",
    "pt": "Remover acesso",
    "de": "Zugriff entfernen"
  },
  "ACCESS_MANAGER_GIVE_ACCESS": {
    "en": "Give access",
    "ru": "Предоставить доступ",
    "es": "Dar acceso",
    "pt": "Dar acesso",
    "de": "Zugriff geben"
  },
  "ACCESS_MANAGER_CLOSE": {
    "en": "CLOSE",
    "ru": "ЗАКРЫТЬ",
    "es": "CERRAR",
    "pt": "FECHAR",
    "de": "SCHLIESSEN"
  },
  "COMMON_SOCIALS_IG": {
    "en": "Instagram",
    "ru": "Instagram",
    "es": "Instagram",
    "pt": "Instagram",
    "de": "Instagram"
  },
  "COMMON_SOCIALS_FB": {
    "en": "Facebook",
    "ru": "Facebook",
    "es": "Facebook",
    "pt": "Facebook",
    "de": "Facebook"
  },
  "COMMON_SOCIALS_VK": {
    "en": "VK",
    "ru": "VK",
    "es": "VK",
    "pt": "VK",
    "de": "VK"
  },
  "ERRORS_PAGE_LIMIT": {
    "en": "You can't compare more than 5 pages at once",
    "ru": "Вы не можете сравнивать более 5 страниц одновременно",
    "es": "No puedes comparar más de 5 páginas a la vez",
    "pt": "Você não pode comparar mais de 5 páginas de uma vez",
    "de": "Sie können nicht mehr als 5 Seiten gleichzeitig vergleichen"
  },
  "STATS_POSTS_ER_POST": {
    "en": "ER post, %",
    "ru": "ER post, %",
    "es": "ER post, %",
    "pt": "ER post, %",
    "de": "ER post, %"
  },
  "STATS_POSTS_ER_VIEW": {
    "en": "ER view, %",
    "ru": "ER view, %",
    "es": "ER view, %",
    "pt": "ER view, %",
    "de": "ER view, %"
  },
  "STATS_POSTS_IR": {
    "en": "IR, %",
    "ru": "IR, %",
    "es": "IR, %",
    "pt": "IR, %",
    "de": "IR, %"
  },
  "STATS_POSTS_RICH_VIRAL": {
    "en": "Reach viral",
    "ru": "Вирусный охват",
    "es": "Alcance viral",
    "pt": "Alcance viral",
    "de": "Virale Reichweite"
  },
  "STATS_POSTS_RICH_SUBSCR": {
    "en": "Reach subscr",
    "ru": "Охват подписчиков",
    "es": "Alcance suscriptores",
    "pt": "Alcance de assinantes",
    "de": "Abonnentenreichweite"
  },
  "STATS_STORIES_REPLIES": {
    "en": "Replies",
    "ru": "Ответы",
    "es": "Respuestas",
    "pt": "Respostas",
    "de": "Antworten"
  },
  "STATS_STORIES_TAPS_FORWARD": {
    "en": "Forward",
    "ru": "Вперед",
    "es": "Adelante",
    "pt": "Avançar",
    "de": "Vorwärts"
  },
  "STATS_STORIES_TAPS_BACK": {
    "en": "Back",
    "ru": "Назад",
    "es": "Atrás",
    "pt": "Voltar",
    "de": "Zurück"
  },
  "STATS_STORIES_EXITS": {
    "en": "Exited",
    "ru": "Выходы",
    "es": "Salidas",
    "pt": "Saídas",
    "de": "Ausgestiegen"
  },
  "STATS_STORIES_REACH": {
    "en": "Reached",
    "ru": "Охвачено",
    "es": "Alcanzado",
    "pt": "Alcançado",
    "de": "Erreicht"
  },
  "STATS_STORIES_VIEWS": {
    "en": "Views",
    "ru": "Просмотры",
    "es": "Vistas",
    "pt": "Visualizações",
    "de": "Ansichten"
  },
  "STATS_TABLE_TOTAL_ALL_LIKES": {
    "en": "Likes",
    "ru": "Лайки",
    "es": "Me gusta",
    "pt": "Curtidas",
    "de": "Gefällt mir"
  },
  "STATS_TABLE_TOTAL_ALL_REPOSTS": {
    "en": "Shares",
    "ru": "Поделились",
    "es": "Compartidos",
    "pt": "Compartilhamentos",
    "de": "Geteilt"
  },
  "STATS_TABLE_TOTAL_ALL_COMMENTS": {
    "en": "Comments",
    "ru": "Комментарии",
    "es": "Comentarios",
    "pt": "Comentários",
    "de": "Kommentare"
  },
  "STATS_TABLE_TOTAL_ALL_VIEWS": {
    "en": "Views",
    "ru": "Просмотры",
    "es": "Vistas",
    "pt": "Visualizações",
    "de": "Ansichten"
  },
  "STATS_TABLE_TOTAL_ALL_RECORDS": {
    "en": "Posts",
    "ru": "Публикации",
    "es": "Publicaciones",
    "pt": "Postagens",
    "de": "Beiträge"
  },
  "STATS_TABLE_TOTAL_STORIES": {
    "en": "Stories",
    "ru": "Истории",
    "es": "Historias",
    "pt": "Histórias",
    "de": "Geschichten"
  },
  "STATS_TABLE_TOTAL_INTERACTIONS": {
    "en": "Interactions",
    "ru": "Взаимодействия",
    "es": "Interacciones",
    "pt": "Interações",
    "de": "Interaktionen"
  },
  "STATS_TABLE_AVERAGE_REACH_POST_AVG": {
    "en": "Reach post",
    "ru": "Охват поста",
    "es": "Alcance publicación",
    "pt": "Alcance da postagem",
    "de": "Beitragsreichweite"
  },
  "STATS_TABLE_AVERAGE_LIKES_AVG": {
    "en": "Likes",
    "ru": "Лайки",
    "es": "Me gusta",
    "pt": "Curtidas",
    "de": "Gefällt mir"
  },
  "STATS_TABLE_AVERAGE_INTERACTIONS_AVG": {
    "en": "Interactions",
    "ru": "Взаимодействия",
    "es": "Interacciones",
    "pt": "Interações",
    "de": "Interaktionen"
  },
  "STATS_TABLE_AVERAGE_REPOSTS_AVG": {
    "en": "Shares",
    "ru": "Поделились",
    "es": "Compartidos",
    "pt": "Compartilhamentos",
    "de": "Geteilt"
  },
  "STATS_TABLE_AVERAGE_VIEWS_AVG": {
    "en": "Views",
    "ru": "Просмотры",
    "es": "Vistas",
    "pt": "Visualizações",
    "de": "Ansichten"
  },
  "STATS_TABLE_AVERAGE_STORIES_REACH_AVG": {
    "en": "Reach stories",
    "ru": "Охват историй",
    "es": "Alcance historias",
    "pt": "Alcance das histórias",
    "de": "Reichweite der Geschichten"
  },
  "STATS_TABLE_AVERAGE_COMMENTS_AVG": {
    "en": "Comments",
    "ru": "Комментарии",
    "es": "Comentarios",
    "pt": "Comentários",
    "de": "Kommentare"
  },
  "STATS_TABLE_AVERAGE_PAGE_REACH_AVG": {
    "en": "Daily reach",
    "ru": "Дневной охват",
    "es": "Alcance diario",
    "pt": "Alcance diário",
    "de": "Tägliche Reichweite"
  },
  "STATS_TABLE_AVERAGE_PAGE_REACH_MOBILE_AVG": {
    "en": "Daily mob. reach",
    "ru": "Мобильный охват",
    "es": "Alcance diario móvil",
    "pt": "Alcance diário móvel",
    "de": "Tägliche mob. Reichweite"
  },
  "STATS_TABLE_ENGAGEMENT_IRR_POST_AVG": {
    "en": "IRR post, %",
    "ru": "IRR post, %",
    "es": "Publicación IRR, %",
    "pt": "Postagem IRR, %",
    "de": "IRR Beitrag, %"
  },
  "STATS_TABLE_FOLLOWERS_SUBSCRIBERS": {
    "en": "Followers",
    "ru": "Подписчики",
    "es": "Seguidores",
    "pt": "Seguidores",
    "de": "Abonnenten"
  },
  "STATS_TABLE_FOLLOWERS_GROWTH": {
    "en": "Growth",
    "ru": "Рост",
    "es": "Crecimiento",
    "pt": "Crescimento",
    "de": "Wachstum"
  },
  "STATS_TABS_COMMON_TITLE": {
    "en": "Common",
    "ru": "Общее",
    "es": "Común",
    "pt": "Comum",
    "de": "Allgemein"
  },
  "STATS_TABS_COMMON_SUBSCRIBERS": {
    "en": "Followers",
    "ru": "Подписчики",
    "es": "Seguidores",
    "pt": "Seguidores",
    "de": "Abonnenten"
  },
  "STATS_TABS_COMMON_LIKES": {
    "en": "Likes",
    "ru": "Лайки",
    "es": "Me gusta",
    "pt": "Curtidas",
    "de": "Gefällt mir"
  },
  "STATS_TABS_COMMON_REPOSTS": {
    "en": "Shares",
    "ru": "Поделились",
    "es": "Compartidos",
    "pt": "Compartilhamentos",
    "de": "Geteilt"
  },
  "STATS_TABS_COMMON_COMMENTS": {
    "en": "Comments",
    "ru": "Комментарии",
    "es": "Comentarios",
    "pt": "Comentários",
    "de": "Kommentare"
  },
  "STATS_TABS_COMMON_VIEWS": {
    "en": "Views",
    "ru": "Просмотры",
    "es": "Vistas",
    "pt": "Visualizações",
    "de": "Ansichten"
  },
  "STATS_TABS_COMMON_ER_PROGRESSIVE": {
    "en": "ER progressive",
    "ru": "ER прогрессивный",
    "es": "ER progresivo",
    "pt": "ER progressivo",
    "de": "ER progressiv"
  },
  "STATS_TABS_COMMON_POST_COUNT": {
    "en": "Posts count",
    "ru": "Количество постов",
    "es": "Conteo de publicaciones",
    "pt": "Contagem de postagens",
    "de": "Anzahl der Beiträge"
  },
  "STATS_TABS_PUBLICATIONS_TITLE": {
    "en": "Posts",
    "ru": "Посты",
    "es": "Publicaciones",
    "pt": "Postagens",
    "de": "Beiträge"
  },
  "STATS_TABS_PUBLICATIONS_AMT_BY_TEXT_LEN": {
    "en": "Count by text length",
    "ru": "Количество по длине текста",
    "es": "Conteo por longitud de texto",
    "pt": "Contagem por comprimento do texto",
    "de": "Anzahl nach Textlänge"
  },
  "STATS_TABS_PUBLICATIONS_ER_BY_TEXT_LEN": {
    "en": "ER by text length",
    "ru": "ER по длине текста",
    "es": "ER por longitud de texto",
    "pt": "ER por comprimento do texto",
    "de": "ER nach Textlänge"
  },
  "STATS_TABS_PUBLICATIONS_REL_ACT_BY_TEXT_LEN": {
    "en": "Text length relative activity",
    "ru": "Относительная активность по длине текста",
    "es": "Actividad relativa por longitud de texto",
    "pt": "Atividade relativa por comprimento do texto",
    "de": "Relative Aktivität nach Textlänge"
  },
  "STATS_TABS_PUBLICATIONS_AMT_BY_CONTENT_TYPE": {
    "en": "Count by content type",
    "ru": "Количество по типу контента",
    "es": "Conteo por tipo de contenido",
    "pt": "Contagem por tipo de conteúdo",
    "de": "Anzahl nach Inhaltstyp"
  },
  "STATS_TABS_PUBLICATIONS_ER_BY_CONTENT_TYPE": {
    "en": "ER by content type",
    "ru": "ER по типу контента",
    "es": "ER por tipo de contenido",
    "pt": "ER por tipo de conteúdo",
    "de": "ER nach Inhaltstyp"
  },
  "STATS_TABS_PUBLICATIONS_REL_ACT_BY_CONTENT_TYPE": {
    "en": "Content type relative activity",
    "ru": "Относительная активность по типу контента",
    "es": "Actividad relativa por tipo de contenido",
    "pt": "Atividade relativa por tipo de conteúdo",
    "de": "Relative Aktivität nach Inhaltstyp"
  },
  "STATS_TABS_PUBLICATIONS_HASHTAGS": {
    "en": "Tags",
    "ru": "Теги",
    "es": "Etiquetas",
    "pt": "Tags",
    "de": "Tags"
  },
  "STATS_TABS_PUBLICATIONS_HASHTAGS_ER": {
    "en": "ER by tags",
    "ru": "ER по тегам",
    "es": "ER por etiquetas",
    "pt": "ER por tags",
    "de": "ER nach Tags"
  },
  "STATS_TABS_PUBLICATIONS_REL_ACT_BY_HASHTAGS": {
    "en": "Relative tags activity",
    "ru": "Относительная активность по тегам",
    "es": "Actividad relativa por etiquetas",
    "pt": "Atividade relativa por tags",
    "de": "Relative Aktivität nach Tags"
  },
  "STATS_TABS_PUBLICATIONS_HOURS": {
    "en": "Hours of day",
    "ru": "Время суток",
    "es": "Horas del día",
    "pt": "Horas do dia",
    "de": "Stunden des Tages"
  },
  "STATS_TABS_PUBLICATIONS_DAYS_TEXT": {
    "en": "Activity by days of week",
    "ru": "Активность по дням недели",
    "es": "Actividad por días de la semana",
    "pt": "Atividade por dias da semana",
    "de": "Aktivität nach Wochentagen"
  },
  "STATS_TABS_PUBLICATIONS_HOURS_TEXT": {
    "en": "Activity by hours of day",
    "ru": "Активность по часам суток",
    "es": "Actividad por horas del día",
    "pt": "Atividade por horas do dia",
    "de": "Aktivität nach Stunden des Tages"
  },"STATS_TABS_PUBLICATIONS_REL_ACT_DAYS_TEXT": {
    "en": "Text length activity by days of week",
    "ru": "Активность по длине текста по дням недели",
    "es": "Actividad de longitud de texto por días de la semana",
    "pt": "Atividade de comprimento do texto por dias da semana",
    "de": "Textlänge Aktivität nach Wochentagen"
  },
  "STATS_TABS_PUBLICATIONS_REL_ACT_HOURS_TEXT": {
    "en": "Text length activity by hours of day",
    "ru": "Активность по длине текста по часам суток",
    "es": "Actividad de longitud de texto por horas del día",
    "pt": "Atividade de comprimento do texto por horas do dia",
    "de": "Textlänge Aktivität nach Stunden des Tages"
  },
  "STATS_TABS_REACH_TITLE": {
    "en": "Reach",
    "ru": "Охват",
    "es": "Alcance",
    "pt": "Alcance",
    "de": "Reichweite"
  },
  "STATS_TABS_REACH_REACH": {
    "en": "Reach",
    "ru": "Охват",
    "es": "Alcance",
    "pt": "Alcance",
    "de": "Reichweite"
  },
  "STATS_TABS_REACH_REACH_SUBSCRIBERS": {
    "en": "Reach followers",
    "ru": "Охват подписчиков",
    "es": "Alcance de seguidores",
    "pt": "Alcance de seguidores",
    "de": "Abonnenten erreichen"
  },
  "STATS_TABS_REACH_REACH_DAYS": {
    "en": "Daily reach",
    "ru": "Ежедневный охват",
    "es": "Alcance diario",
    "pt": "Alcance diário",
    "de": "Tägliche Reichweite"
  },
  "STATS_TABS_SOCIAL_TITLE": {
    "en": "Audience",
    "ru": "Аудитория",
    "es": "Audiencia",
    "pt": "Audiência",
    "de": "Publikum"
  },
  "STATS_TABS_SOCIAL_TOWNS": {
    "en": "Cities",
    "ru": "Города",
    "es": "Ciudades",
    "pt": "Cidades",
    "de": "Städte"
  },
  "STATS_TABS_SOCIAL_AGES": {
    "en": "Ages",
    "ru": "Возраст",
    "es": "Edades",
    "pt": "Idades",
    "de": "Alter"
  },
  "COMMON_WEEKS": {
    "en": [
      { "week": 0, "text": "Su" },
      { "week": 1, "text": "Mo" },
      { "week": 2, "text": "Tu" },
      { "week": 3, "text": "We" },
      { "week": 4, "text": "Th" },
      { "week": 5, "text": "Fr" },
      { "week": 6, "text": "Sa" }
    ],
    "ru": [
      { "week": 0, "text": "Вс" },
      { "week": 1, "text": "Пн" },
      { "week": 2, "text": "Вт" },
      { "week": 3, "text": "Ср" },
      { "week": 4, "text": "Чт" },
      { "week": 5, "text": "Пт" },
      { "week": 6, "text": "Сб" }
    ],
    "es": [
      { "week": 0, "text": "Do" },
      { "week": 1, "text": "Lu" },
      { "week": 2, "text": "Ma" },
      { "week": 3, "text": "Mi" },
      { "week": 4, "text": "Ju" },
      { "week": 5, "text": "Vi" },
      { "week": 6, "text": "Sa" }
    ],
    "pt": [
      { "week": 0, "text": "Do" },
      { "week": 1, "text": "Se" },
      { "week": 2, "text": "Te" },
      { "week": 3, "text": "Qa" },
      { "week": 4, "text": "Qi" },
      { "week": 5, "text": "Se" },
      { "week": 6, "text": "Sa" }
    ],
    "de": [
      { "week": 0, "text": "So" },
      { "week": 1, "text": "Mo" },
      { "week": 2, "text": "Di" },
      { "week": 3, "text": "Mi" },
      { "week": 4, "text": "Do" },
      { "week": 5, "text": "Fr" },
      { "week": 6, "text": "Sa" }
    ]
  },
  "PRC_HEADER": {
    "en": "Pricing",
    "ru": "Цены",
    "es": "Precios",
    "pt": "Preços",
    "de": "Preise"
  },
  "PRC_YEAR": {
    "en": "Billed Yearly",
    "ru": "Выставляется ежегодно",
    "es": "Facturado anualmente",
    "pt": "Faturado anualmente",
    "de": "Jährlich abgerechnet"
  },
  "PRC_MNTH": {
    "en": "Billed Monthly",
    "ru": "Выставляется ежемесячно",
    "es": "Facturado mensualmente",
    "pt": "Faturado mensalmente",
    "de": "Monatlich abgerechnet"
  },
  "PRC_DSCNT": {
    "en": "Save",
    "ru": "Сохранить",
    "es": "Guardar",
    "pt": "Salvar",
    "de": "Speichern"
  },
  "PRC_FREE": {
    "en": "Free",
    "ru": "Бесплатно",
    "es": "Gratis",
    "pt": "Grátis",
    "de": "Kostenlos"
  },
  "PRC_PRD": {
    "en": "month",
    "ru": "месяц",
    "es": "mes",
    "pt": "mês",
    "de": "Monat"
  },"PRC_ACT": {
    "en": "Let's started",
    "ru": "Давайте начнем",
    "es": "Empecemos",
    "pt": "Vamos começar",
    "de": "Lasst uns anfangen"
  },
  "PRC_USR": {
    "en": "Users",
    "ru": "Пользователи",
    "es": "Usuarios",
    "pt": "Usuários",
    "de": "Benutzer"
  },
  "PRC_SMP": {
    "en": "Social Media Pages",
    "ru": "Страницы в социальных сетях",
    "es": "Páginas de redes sociales",
    "pt": "Páginas de mídia social",
    "de": "Soziale Medien Seiten"
  },
  "PRC_DEPTH": {
    "en": "Depth analysis",
    "ru": "Глубокий анализ",
    "es": "Análisis de profundidad",
    "pt": "Análise de profundidade",
    "de": "Tiefenanalyse"
  },
  "PRC_14": {
    "en": "Last 14 days",
    "ru": "Последние 14 дней",
    "es": "Últimos 14 días",
    "pt": "Últimos 14 dias",
    "de": "Letzte 14 Tage"
  },
  "PRC_SPP": {
    "en": "Support",
    "ru": "Поддержка",
    "es": "Soporte",
    "pt": "Suporte",
    "de": "Unterstützung"
  },
  "PRC_NO": {
    "en": "No",
    "ru": "Нет",
    "es": "No",
    "pt": "Não",
    "de": "Nein"
  },
  "PRC_ACS": {
    "en": "",
    "ru": "",
    "es": "",
    "pt": "",
    "de": ""
  },
  "PRC_CMPR": {
    "en": "Comparing pages",
    "ru": "Сравнение страниц",
    "es": "Comparando páginas",
    "pt": "Comparando páginas",
    "de": "Seiten vergleichen"
  },
  "PRC_STR": {
    "en": "Stories stats for VK & Instagram",
    "ru": "Статистика историй для VK и Instagram",
    "es": "Estadísticas de historias para VK e Instagram",
    "pt": "Estatísticas de histórias para VK e Instagram",
    "de": "Geschichtenstatistiken für VK & Instagram"
  },
  "PRC_INN": {
    "en": "Inner social stats",
    "ru": "Внутренняя социальная статистика",
    "es": "Estadísticas sociales internas",
    "pt": "Estatísticas sociais internas",
    "de": "Innere soziale Statistiken"
  },
  "PRC_EXPORT": {
    "en": "Exports reports to",
    "ru": "Экспорт отчетов в",
    "es": "Exporta informes a",
    "pt": "Exporta relatórios para",
    "de": "Berichte exportieren nach"
  },
  "PRC_AREML": {
    "en": "Auto reports to email",
    "ru": "Автоматические отчеты на почту",
    "es": "Informes automáticos al correo electrónico",
    "pt": "Relatórios automáticos para e-mail",
    "de": "Automatische Berichte per E-Mail"
  },
  "PRC_API": {
    "en": "Access via API",
    "ru": "Доступ через API",
    "es": "Acceso a través de API",
    "pt": "Acesso via API",
    "de": "Zugang über API"
  },
  "PRC_RC": {
    "en": "Pay Russian card",
    "ru": "Оплата российской картой",
    "es": "Pagar con tarjeta rusa",
    "pt": "Pagar com cartão russo",
    "de": "Mit russischer Karte bezahlen"
  },
  "PRC_FC": {
    "en": "Pay Foreign card",
    "ru": "Оплата иностранной картой",
    "es": "Pagar con tarjeta extranjera",
    "pt": "Pagar com cartão estrangeiro",
    "de": "Mit ausländischer Karte bezahlen"
  },
  "PRC_AGNC": {
    "en": "Agency",
    "ru": "Агентство",
    "es": "Agencia",
    "pt": "Agência",
    "de": "Agentur"
  },
  "PRC_CMPN": {
    "en": "Company",
    "ru": "Компания",
    "es": "Compañía",
    "pt": "Empresa",
    "de": "Firma"
  },
  "PRC_EXC": {
    "en": "Exceptional",
    "ru": "Исключительный",
    "es": "Excepcional",
    "pt": "Excepcional",
    "de": "Außergewöhnlich"
  },
  "PRC_EXPR": {
    "en": "Expires",
    "ru": "Истекает",
    "es": "Expira",
    "pt": "Expira",
    "de": "Läuft ab"
  },
  "PRC_PLN": {
    "en": "Plan",
    "ru": "Тариф",
    "es": "Plan",
    "pt": "Plano",
    "de": "Plan"
  },
  "PRC_UPGR": {
    "en": "Upgrade",
    "ru": "Обновление",
    "es": "Mejorar",
    "pt": "Atualizar",
    "de": "Aktualisieren"
  },
  "PRC_USED": {
    "en": "Total used",
    "ru": "Использовано",
    "es": "Total usado",
    "pt": "Total usado",
    "de": "Insgesamt verwendet"
  },
  "REPORTS_TO": {
    "en": "Send reports to",
    "ru": "Отправить отчеты на",
    "es": "Enviar informes a",
    "pt": "Enviar relatórios para",
    "de": "Berichte senden an"
  },
  "REPORTS_REG": {
    "en": "Regularity",
    "ru": "Как часто",
    "es": "Regularidad",
    "pt": "Regularidade",
    "de": "Regelmäßigkeit"
  },
  "REPORTS_REG_WK": {
    "en": "Weekly",
    "ru": "Еженедельно",
    "es": "Semanalmente",
    "pt": "Semanalmente",
    "de": "Wöchentlich"
  },
  "REPORTS_REG_MN": {
    "en": "Monthly",
    "ru": "Ежемесячно",
    "es": "Mensualmente",
    "pt": "Mensalmente",
    "de": "Monatlich"
  },
  "REPORTS_FORM": {
    "en": "Report export formats",
    "ru": "Форматы экспорта отчетов",
    "es": "Formatos de exportación de informes",
    "pt": "Formatos de exportação de relatórios",
    "de": "Berichtsexportformate"
  },"REPORTS_SAVE": {
    "en": "Save and close",
    "ru": "Сохранить и закрыть",
    "es": "Guardar y cerrar",
    "pt": "Salvar e fechar",
    "de": "Speichern und schließen"
  },
  "REPORTS_CPTN": {
    "en": "Automatic reports manager",
    "ru": "Менеджер автоматических отчетов",
    "es": "Administrador de informes automáticos",
    "pt": "Gerenciador de relatórios automáticos",
    "de": "Automatischer Bericht-Manager"
  },
  "BTN_COMPARE": {
    "en": "Compare",
    "ru": "Сравнить",
    "es": "Comparar",
    "pt": "Comparar",
    "de": "Vergleichen"
  },
  "BTN_AUTO": {
    "en": "Auto reports",
    "ru": "Авто отчеты",
    "es": "Informes automáticos",
    "pt": "Relatórios automáticos",
    "de": "Automatische Berichte"
  },
  "DASH_SELECTED": {
    "en": "Selected pages",
    "ru": "Выбранные страницы",
    "es": "Páginas seleccionadas",
    "pt": "Páginas selecionadas",
    "de": "Ausgewählte Seiten"
  },
  "DASH_TOTAL": {
    "en": "Total",
    "ru": "Итого",
    "es": "Total",
    "pt": "Total",
    "de": "Gesamt"
  },
  "DASH_ENG": {
    "en": "Engagement",
    "ru": "Вовлеченность",
    "es": "Compromiso",
    "pt": "Engajamento",
    "de": "Engagement"
  },
  "DASH_AVE": {
    "en": "Average",
    "ru": "Средний",
    "es": "Promedio",
    "pt": "Média",
    "de": "Durchschnitt"
  },
  "DASH_GRW": {
    "en": "growth",
    "ru": "Рост",
    "es": "Crecimiento",
    "pt": "Crescimento",
    "de": "Wachstum"
  },
  "REPORTS_MANAGE": {
    "en": "Manage auto reports",
    "ru": "Управление автоматическими отчетами",
    "es": "Administrar informes automáticos",
    "pt": "Gerenciar relatórios automáticos",
    "de": "Automatische Berichte verwalten"
  },
  "REPORTS_REPORT": {
    "en": "Report",
    "ru": "Отчет",
    "es": "Informe",
    "pt": "Relatório",
    "de": "Bericht"
  },
  "REPORTS_LIST": {
    "en": "Report for pages",
    "ru": "Отчет для страниц",
    "es": "Informe para páginas",
    "pt": "Relatório para páginas",
    "de": "Bericht für Seiten"
  },
  "REPORTS_LIST_PRD": {
    "en": "for period",
    "ru": "за период",
    "es": "por período",
    "pt": "por período",
    "de": "für Zeitraum"
  },
  "REPORTS_LIST_WAS": {
    "en": "was generated",
    "ru": "был сгенерирован",
    "es": "fue generado",
    "pt": "foi gerado",
    "de": "wurde generiert"
  },
  "REPORTS_DOWNLD": {
    "en": "Download report",
    "ru": "Скачать отчет",
    "es": "Descargar informe",
    "pt": "Baixar relatório",
    "de": "Bericht herunterladen"
  },
  "SORT_DATE": {
    "en": "Date",
    "ru": "Дата",
    "es": "Fecha",
    "pt": "Data",
    "de": "Datum"
  },
  "SORT_LIKES": {
    "en": "Likes",
    "ru": "Лайки",
    "es": "Me gusta",
    "pt": "Curtidas",
    "de": "Likes"
  },
  "SORT_BY": {
    "en": "Sort By",
    "ru": "Сортировать по",
    "es": "Ordenar por",
    "pt": "Ordenar por",
    "de": "Sortieren nach"
  },
  "SORT_SHARES": {
    "en": "Shares",
    "ru": "Поделились",
    "es": "Compartidos",
    "pt": "Compartilhamentos",
    "de": "Teilen"
  },
  "SORT_COMMS": {
    "en": "Comments",
    "ru": "Комментарии",
    "es": "Comentarios",
    "pt": "Comentários",
    "de": "Kommentare"
  },
  "SORT_VIEWS": {
    "en": "Views",
    "ru": "Просмотры",
    "es": "Vistas",
    "pt": "Visualizações",
    "de": "Ansichten"
  },
  "POST_MORE": {
    "en": "view more",
    "ru": "еще",
    "es": "ver más",
    "pt": "ver mais",
    "de": "mehr anzeigen"
  },
  "POST_LESS": {
    "en": "show less",
    "ru": "меньше",
    "es": "ver menos",
    "pt": "mostrar menos",
    "de": "weniger anzeigen"
  },
  "POST_POSTED": {
    "en": "Posted",
    "ru": "Опубликовано",
    "es": "Publicado",
    "pt": "Publicado",
    "de": "Gepostet"
  },
  "POST_INTER": {
    "en": "Interactions",
    "ru": "Взаимодействия",
    "es": "Interacciones",
    "pt": "Interações",
    "de": "Interaktionen"
  },
  "POST_DISCOV": {
    "en": "Discovery",
    "ru": "Открытие",
    "es": "Descubrimiento",
    "pt": "Descoberta",
    "de": "Entdeckung"
  },
  "POST_NAVIG": {
    "en": "Navigation",
    "ru": "Навигация",
    "es": "Navegación",
    "pt": "Navegação",
    "de": "Navigation"
  },
  "DASH_COMPARE": {
    "en": "Compare Pages",
    "ru": "Сравнить страницы",
    "es": "Comparar páginas",
    "pt": "Comparar páginas",
    "de": "Seiten vergleichen"
  },
  "USR_CONT": {
    "en": "Contact us",
    "ru": "Свяжитесь с нами",
    "es": "Contáctenos",
    "pt": "Contate-nos",
    "de": "Kontaktieren Sie uns"
  },
  "USR_PRVC": {
    "en": "Privacy Policy",
    "ru": "Политика конфиденциальности",
    "es": "Política de privacidad",
    "pt": "Política de Privacidade",
    "de": "Datenschutz-Bestimmungen"
  },
  "USR_TERMS": {
    "en": "Terms",
    "ru": "Условия",
    "es": "Términos",
    "pt": "Termos",
    "de": "Begriffe"
  },
  "USR_SAV": {
    "en": "Saving tokens",
    "ru": "Сохранение токенов",
    "es": "Guardando tokens",
    "pt": "Salvando tokens",
    "de": "Tokens speichern"
  },
  "DASH_EXPORT": {
    "en": "Export",
    "ru": "Экспорт",
    "es": "Exportar",
    "pt": "Exportar",
    "de": "Export"
  },
  "DASH_EXP_AS": {
    "en": "Export as",
    "ru": "Экспортировать как",
    "es": "Exportar como",
    "pt": "Exportar como",
    "de": "Exportieren als"
  },
  "ALERT_INVALID": {
    "en": "token for some pages is invalid. It's required to refresh social auth to continue pages analysis.",
    "ru": "токен для некоторых страниц недействителен. Требуется обновить авторизацию, чтобы продолжить анализ страниц.",
    "es": "el token para algunas páginas no es válido. Es necesario actualizar la autenticación social para continuar con el análisis de páginas.",
    "pt": "o token para algumas páginas é inválido. É necessário atualizar a autenticação social para continuar a análise das páginas.",
    "de": "Token für einige Seiten ist ungültig. Es ist erforderlich, die soziale Authentifizierung zu aktualisieren, um die Seitenanalyse fortzusetzen."
  },
  "ALERT_F5_BTN": {
    "en": "Refresh token",
    "ru": "Обновить токен",
    "es": "Actualizar token",
    "pt": "Atualizar token",
    "de": "Token aktualisieren"
  },
  "PAY_SUC": {
    "en": "Successful payment!",
    "ru": "Успешный платеж!",
    "es": "¡Pago exitoso!",
    "pt": "Pagamento bem-sucedido!",
    "de": "Erfolgreiche Zahlung!"
  },
  "PAY_FAIL": {
    "en": "Failed!",
    "ru": "Ошибка!",
    "es": "¡Falló!",
    "pt": "Falhou!",
    "de": "Gescheitert!"
  },
  "PAY_SUC_TXT": {
    "en": "You successfully bought an access to Lucmus. You can continue using the app on the Main page",
    "ru": "Вы успешно приобрели доступ к Lucmus. Вы можете продолжить использование приложения на Главной странице",
    "es": "Has comprado exitosamente acceso a Lucmus. Puedes continuar usando la aplicación en la página principal",
    "pt": "Você comprou com sucesso um acesso ao Lucmus. Você pode continuar usando o aplicativo na página principal",
    "de": "Sie haben erfolgreich Zugriff auf Lucmus gekauft. Sie können die App auf der Hauptseite weiter verwenden"
  },
  "PAY_FAIL_TXT": {
    "en": "The payment was declined. Check your bank card details and notifications to fix the problem.",
    "ru": "Платеж был отклонен. Проверьте данные вашей банковской карты и уведомления, чтобы исправить проблему.",
    "es": "El pago fue rechazado. Verifique los detalles de su tarjeta bancaria y las notificaciones para solucionar el problema.",
    "pt": "O pagamento foi recusado. Verifique os detalhes do seu cartão bancário e notificações para resolver o problema.",
    "de": "Die Zahlung wurde abgelehnt. Überprüfen Sie die Angaben zu Ihrer Bankkarte und die Benachrichtigungen, um das Problem zu beheben."
  },
  "PAY_MAIN": {
    "en": "Go to Main page",
    "ru": "Перейти на главную страницу",
    "es": "Ir a la página principal",
    "pt": "Ir para a página principal",
    "de": "Zur Hauptseite gehen"
  },
  "PAY_PRIC": {
    "en": "Back to Pricing page",
    "ru": "Назад к Тарифам",
    "es": "Volver a la página de precios",
    "pt": "Voltar à página de preços",
    "de": "Zurück zur Preisseite"
  },
  "AUTH_OR": {
    "en": "or",
    "ru": "или",
    "es": "o",
    "pt": "ou",
    "de": "oder"
  },
  "AUTH_COMMON_SIGNIN_WITH_GOOGLE": {
    "en": "Sign in with Google",
    "ru": "Войти через Google",
    "es": "Iniciar sesión con Google",
    "pt": "Entrar com o Google",
    "de": "Mit Google anmelden"
  },
  "AUTH_COMMON_SIGNUP_WITH_GOOGLE": {
    "en": "Sign up with Google",
    "ru": "Зарегистрироваться через Google",
    "es": "Registrarse con Google",
    "pt": "Inscrever-se com o Google",
    "de": "Mit Google registrieren"
  },
  "STATS_TABLE_ENGAGEMENT_ER_DAY": "ER day, %",
  "STATS_TABLE_ENGAGEMENT_ER_POST": "ER post, %",
  "STATS_TABLE_ENGAGEMENT_ER_VIEW": "ER view, %",
  "STATS_TABLE_ENGAGEMENT_LR": "LR, %",
  "STATS_TABLE_ENGAGEMENT_TR": "TR, %",
  "STATS_TABLE_ENGAGEMENT_REACH_RATE_AVG": "RR, % ",
  "STATS_TABLE_ENGAGEMENT_ERR": "ERR, %",
  "STATS_TABLE_ENGAGEMENT_VIS_RATE": "Vis. Rate, %",
  "STATS_TABLE_ENGAGEMENT_VIEWS_RATE": "Views Rate",
  "STATS_TABLE_ENGAGEMENT_IR_POST_AVG": "IR post, %  ",
  "STATS_TABLE_ENGAGEMENT_IRR_POST_AVG": "IRR post, %  ",
  "STATS_TABLE_ENGAGEMENT_ER_PROGRESSIVE": "ER Progressive, %",
  "POSTS_FEED_ER": "ER post",
  "POSTS_FEED_ER_VIEWS": "ER view",
  "POSTS_FEED_IR": "IR",
  "POSTS_FEED_ER_PROGRESSIVE": "ER prog",
  "POSTS_FEED_REACH_VIRAL": "Viral Reach",
  "POSTS_FEED_REACH_SUBSCRIBERS": "Reach Followers",
  "POSTS_FEED_REACH_TOTAL": "Reach",
  "POSTS_FEED_ERR": "ERR",
  "POSTS_FEED_REACH": "Reach"
  }
  ;
  
  export default translations;
  