<template>
  <div class="dash-header">
    <div class="dash-page" v-if="getSelectedPages.length == 1">
      <span @click="$router.push('/main')" class="go-back"></span>
      <span class="soc-block">
        <span :class="`soc-ico soc-${page.social.toLowerCase()}`"></span>
      </span>
      <img :src="page.owner_avatar" alt class />
      <div class="d-page-cred">
        <p>{{ page.owner_name }}</p>
        <span><i class="bi bi-people-fill"></i>{{ formatNum(page.followers) }}</span>
      </div>
    </div>
    <div v-else class="dash-page">
      <span @click="$router.push('/main')" class="go-back"></span>
      <div class="dash-caption">{{ $t('DASH_COMPARE') }}</div>
    </div>
    <div class="dash-tabs">
      <button
        :class="isActiveTab('Overview')"
        @click="DASH_SET_ACTIVE_TAB_ACTION('Overview')"
      >
        {{ $t('DASHBOARD_TABS_OVERVIEW') }}
      </button>
      <button
        :class="isActiveTab('Activity')"
        v-if="canShowActivity"
        @click="DASH_SET_ACTIVE_TAB_ACTION('Activity')"
      >
        {{ $t('DASHBOARD_TABS_ACTIVITY') }}
      </button>
      <button
        :class="isActiveTab('Posts stats')"
        v-if="canShowPosts"
        @click="DASH_SET_ACTIVE_TAB_ACTION('Posts stats')"
      >
        {{ $t('DASHBOARD_TABS_POSTS_STATS') }}
      </button>
      <button
        v-if="canShowReach"
        :class="isActiveTab('Reach')"
        @click="DASH_SET_ACTIVE_TAB_ACTION('Reach')"
      >
        {{ $t('DASHBOARD_TABS_REACH') }}
      </button>
      <button
        v-if="canShowAudience"
        :class="isActiveTab('Audience')"
        @click="DASH_SET_ACTIVE_TAB_ACTION('Audience')"
      >
        {{ $t('DASHBOARD_TABS_AUDIENCE') }}
      </button>
      <button
        v-if="canShowPosts"
        :class="isActiveTab('Posts Feed')"
        @click="DASH_SET_ACTIVE_TAB_ACTION('Posts Feed')"
      >
        {{ $t('DASHBOARD_TABS_POSTS_FEED') }}
      </button>
      <button
        v-if="canShowStories"
        :class="isActiveTab('Stories Feed')"
        @click="DASH_SET_ACTIVE_TAB_ACTION('Stories Feed')"
      >
        {{ $t('DASHBOARD_TABS_STORIES_FEED') }}
      </button>
    </div>
    <div class="dash-options">
      <calendar
        :callback="DASH_LOAD_DATA_ACTION"
        :disabledDates="getDisabledDatesPosts"
      />
      <report-button />
    </div>
    
  </div>
</template>

<script>
import pageUtils from "@/models/page-utils";
import { mapGetters, mapMutations, mapActions } from "vuex";
import {
  DASH_SET_ACTIVE_TAB_ACTION,
  DASH_LOAD_DATA_ACTION,
} from "../../store/actions/dash";
import Calendar from "../../components/Calendar";
import ReportButton from "../../components/ReportButton";

export default {
  name: "Dash-navbar",
  components: {
    Calendar,
    ReportButton,
  },
  computed: {
    ...mapGetters([
      "getSelectedPages",
      "getPeriod",
      "getActiveTab",
      "getUnionPagesData",
      "getDisabledDatesPosts",
    ]),
    canShowActivity() {
      const { posts } = this.getUnionPagesData;
      return !!posts.length;
    },
    canShowReach() {
      const { pages, posts } = this.getUnionPagesData;
      if (!pages.length) return false;
      const canShow = pages
        .map((page) => page.is_admin && !!page.posts.length)
        .some((value) => value);
      return canShow;
    },
    canShowPosts() {
      const { posts } = this.getUnionPagesData;
      return !!posts.length;
    },
    canShowStories() {
      const { stories } = this.getUnionPagesData;
      return !!stories.length;
    },
    canShowAudience() {
      const { pages } = this.getUnionPagesData;
      const canShow = pages
        .map((page) => page.is_admin && page.audienceStats)
        .some((value) => value);
      return canShow;
    },
    page() {
      return this.getSelectedPages[0];
    },
  },
  methods: {
    ...mapActions([DASH_SET_ACTIVE_TAB_ACTION, DASH_LOAD_DATA_ACTION]),
    formatNum(num) {
      return pageUtils.formatNumbers(num);
    },
    isActiveTab(tabName) {
      if (this.getActiveTab == tabName) return "btn-st-grey active";
      return "btn-st-grey ";
    },
  },
  beforeDestroy() {
    // this.DASH_SET_ACTIVE_TAB_ACTION("Overview");
  },
  data() {
    return {};
  },
};
</script>

<style>
</style>