import Vue from 'vue';
import VueI18n from 'vue-i18n';
import translations from './translations';

Vue.use(VueI18n);

function transformTranslations(translations) {
  const result = {};

  Object.keys(translations).forEach(key => {
    if (typeof translations[key] === 'object') {
      // This key has language-specific translations
      Object.keys(translations[key]).forEach(lang => {
        if (!result[lang]) {
          result[lang] = {};
        }
        result[lang][key] = translations[key][lang];
      });
    } else {
      // This key should be used as-is for all languages
      const value = translations[key];
      Object.keys(result).forEach(lang => {
        if (!result[lang]) {
          result[lang] = {};
        }
        result[lang][key] = value;
      });
    }
  });

  return result;
}

const i18nMessages = transformTranslations(translations);

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'ru',
  messages: i18nMessages
});

export default i18n;
