<template>
  <div class="dash-footer">
    <div class="sel-pages">
      <!--<p>Select pages to compare</p>-->
      <p>{{ $t('DASH_SELECTED') }}:</p>
      <div class="sel-list">
        <div
          class="sel-pg"
          :key="index"
          v-for="(page, index) in getSelectedPages"
        >
          <span :class="`indicator ${page.colorName}-back`"></span>
          <img :src="page.owner_avatar" alt />
          <div>
            <p>{{ page.owner_name }}</p>
            <span v-if="page.social == 'FB'">Facebook</span>
            <span v-if="page.social == 'IG'">Instagram</span>
            <span v-if="page.social == 'VK'">VK</span>
          </div>
          <span class="close" @click="INTRO_SET_SELECTED(page)"><i class="bi bi-x-lg"></i></span>
        </div>
      </div>
      
    </div>
    <div class="foo-btns">
      <!-- <button
        type="button"
        class="btn-st-viol"
        @click="INTRO_SET_AUTOREPORTS_SETTINGS_VISIBLE(true)"
      >
        {{ $t('BTN_AUTO') }}
      </button> -->
      <button
        type="button"
        :class="`btn-st-red ${getSelectedPages.length ? 'active' : 'unactive'}`"
        @click="$router.push({ path: '/dashboard' })"
      >
        {{ $t('BTN_COMPARE') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import {
  INTRO_SET_AUTOREPORTS_SETTINGS_VISIBLE,
  INTRO_SET_SELECTED,
} from "../store/actions/intro";

export default {
  name: "Header",
  computed: {
    ...mapGetters(["getSelectedPages"]),
  },
  methods: {
    ...mapMutations([INTRO_SET_AUTOREPORTS_SETTINGS_VISIBLE]),
    ...mapActions([INTRO_SET_SELECTED]),
  },
  mounted() {},
};
</script>

<style>
</style>