<template>
  <div>
    <div class="line-charts">
      <div class="panel" :key="index" v-for="(name, index) in canShowChart">
        <h2>{{ $t(`DASHBOARD_ACTIVITY_CHARTS_${name}`) }}</h2>
        <div class="m-chart">
          <div
            class="chart"
            :ref="`activity${name}`"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </div>

      <div class="panel">
        <h2>{{ $t('DASH_GRW') }}</h2>
        <div class="m-chart">
          <div
            class="chart"
            :ref="`activitygrowth`"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </div>
      <div
        class="panel"
        :key="page.hash_id"
        v-for="(page, index) in getAnalizedAdminPages"
      >
        <div class="aud-header">
          <h2>{{ $t('DASHBOARD_ACTIVITY_CHARTS_subunsub') }}</h2>
          <div :class="`soc-sea ${page.colorName}`">
            <span :class="`soc-ico soc-${page.social.toLowerCase()}`"></span>
            {{ page.owner_name_medium }}
          </div>
        </div>
        <div class="m-chart">
          <div
            class="chart"
            :ref="`activitysubunsub${index}`"></div>
          <!-- <div
            class="chart"
            :ref="`online-legend${index}`"
            style="height: 2px; padding: 0; text-align: center"
          ></div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import PageCharts from "@/models/page-charts";
import linesChart from "@/models/charts/lines";
import Columns from "@/models/charts/columns";
import ColumnsOne from "@/models/charts/columns-one";
import * as am4core from "@amcharts/amcharts4/core";

export default {
  name: "Dash-activity-body",
  computed: {
    ...mapGetters([
      "getSelectedPages",
      "loadingStatus",
      "getAnalizedPages",
      "getUnionPagesData",
      "getSocialOfPageInAnalize",
      "getAnalizedAdminPages",
    ]),
    locale() {
      return this.$locale.DASHBOARD.ACTIVITY_CHARTS;
    },
    canShowChart() {
      const charts = [
        "likes",
        "comments",
        "reposts",
        "views",
        "posts",
        "stories",
        "interactions",
      ];
      const socials = this.getSocialOfPageInAnalize;
      const isAdmins = !!this.getAnalizedAdminPages.length;
      return charts.filter((name) => {
        if (name == "reposts") {
          if (socials.VK || socials.FB) return true;
          else return false;
        } else if (name == "stories") {
          const hasStories = this.getAnalizedAdminPages.some(
            (page) => page.stories.length
          );
          if ((socials.VK || socials.IG) && isAdmins && hasStories) return true;
          else return false;
        } else if (name == "interactions") {
          if (isAdmins) return true;
          else return false;
        } else if (name == "views") {
          if (socials.VK) return true;
          else return false;
        } else return true;
      });
    },
  },

  components: {},
  data() {
    return {
      chartNames: [],
      loaded: false,
      charts: [],
    };
  },
  methods: {
    async renderCharts() {
      const { pages, datesRangePosts, datesRangeStories } =
        this.getUnionPagesData;
      const that = this;
      const colors = Object.entries(this.$colors).map(([key, value]) => {
        return { key, value };
      });

      for (const [index, name] of this.canShowChart.entries()) {
        this.charts.push(
          await linesChart({
            pages,
            datesRangePosts,
            datesRangeStories,
            name,
            ref: that.$refs[`activity${name}`].pop(),
            color: colors[index],
            type: "activity",
          })
        );
      }

      const dataGrowth = PageCharts.growth({ pages });
      // console.log("dataGrowth", dataGrowth);
      this.charts.push(
        await Columns({
          pages,
          ref: [that.$refs[`activitygrowth`]],
          chartData: dataGrowth,
          field: "growth",
          category: "date",
          colors: that.$colors,
        })
      );

      this.getAnalizedAdminPages.forEach(async (page, idx) => {
        const subUnsub = PageCharts.SubUnsub({ page });
        // console.log("subUnsub", subUnsub);

        this.charts.push(
          await ColumnsOne({
            page,
            ref: that.$refs[`activitysubunsub${idx}`],
            chartData: subUnsub,
            fields: [
              { field: "subscribed", stacked: false },
              { field: "unsubscribed", stacked: true },
            ],
            category: "date",
            color: page.colorName,
          })
        );
      });
    },
  },
  async mounted() {
    setTimeout(this.renderCharts, 0);
  },

  beforeDestroy() {
    setTimeout(am4core.disposeAllCharts, 0);
  },
};
</script>

<style></style>
