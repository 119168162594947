


<template>
  <li>
    <div class="stories-face">
      <a :href="image" :class="'stories-' + tuid">
        <img :src="image" alt />
      </a>
      

      <span class="content-type picture"></span>
    </div>
    <div class="stories-stats">
      <ul class="stories-block">
        <li>
          <b>{{ $t('POST_POSTED') }}</b>
          <span>
            <b>{{getTime}} {{getDate}}</b>
          </span>
        </li>
        <li>
          <b>{{ $t('STATS_STORIES_VIEWS') }}</b>
          <span :key="i" v-for="(i, stat) in story.formatedStats.views">
            <b>{{story.formatedStats.views[stat]}}</b>
          </span>
        </li>
      </ul>
      <ul class="stories-block">
        <li>
          <b>{{ $t('POST_INTER') }}</b>
          <span>
            <span class="m-metric" title="interactions"><i class="bi bi-lightning-charge"></i></span>
            <b>{{calcTotal(story.formatedStats.interactions)}}</b>
          </span>
        </li>
        <li :key="index" v-for="(index, stat) in story.formatedStats.interactions">
          <!-- {{story.stat.stats.formatedStats[]}} -->
          {{$t('STATS_STORIES_'+stat)}}
          <span>{{story.formatedStats.interactions[stat]}}</span>
        </li>
      </ul>
      <ul class="stories-block">
        <li>
          <b>{{ $t('POST_DISCOV') }}</b>
          <span>
            <span class="m-metric" title="discovery"><i class="bi bi-globe-americas"></i></span>
            <b>{{calcTotal(story.formatedStats.discovery)}}</b>
          </span>
        </li>
        <li :key="index" v-for="(index, stat) in story.formatedStats.discovery">
          <!-- {{story.stat.stats.formatedStats[]}} -->
          {{stat}}
          <span>{{story.formatedStats.discovery[stat]}}</span>
        </li>
      </ul>
      <ul class="stories-block">
        3
        <li>
          <b>{{ $t('POST_NAVIG') }}</b>
          <span>
            <b>{{calcTotal(story.formatedStats.navigation)}}</b>
          </span>
        </li>
        <li :key="index" v-for="(index, stat) in story.formatedStats.navigation">
          {{stat}}
          <span>{{story.formatedStats.navigation[stat]}}</span>
        </li>
      </ul>
    </div>
  </li>
</template>

<script>
import GLightbox from "glightbox";
export default {
  name: "Dash-stories-card",
  data() {
    return {
      tuid: null
    }
  },
  props: {
    story: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.tuid = this.randomHash();
  },
  updated() {
    GLightbox({ selector: `.stories-${this.tuid}` });
  },
  computed: {
    getTime() {
      const date = new Date(this.story.date_from * 1000);
      return `${date.getHours()}:${
        (date.getMinutes() < 10 ? "0" : "") + date.getMinutes()
      }`;
    },
    getDate() {
      const date = this.story.date_from * 1000;

      return this.moment(date).format("DD/MM/YYYY");
    },
    image() {
      if (this.story.image) {
        return (
          "data:image/jpeg;base64," +
          Buffer.from(this.story.image, "binary").toString("base64")
        );
      } else {
        return this.story.stat.photo.sizes[
          this.story.stat.photo.sizes.length - 1
        ].url;
      }
    },
  },
  methods: {
    calcTotal(stat) {
      return Object.values(stat).reduce((a, b) => a + b, 0);
    },
    randomHash() {
      return Math.random().toString(36).substr(2, 8);
    },    
  },
};
</script>

<style>
</style>