<template>
  <transition>
    <div class="mod-dial" @click="INTRO_SET_ACCESS_MANAGER_VISIBLE(false)">
      <div class="mod-cont" @click.stop>
        <span class="close" @click="INTRO_SET_ACCESS_MANAGER_VISIBLE(false)"
          ><i class="bi bi-x-lg"></i></span
        >
        <div class="mod-body mod-access">
          <div class="r18">{{ $t('ACCESS_MANAGER_RIGHTS_MANAGER') }}</div>

          <div class="mod-inner">
            <div class="b16">{{ $t('ACCESS_MANAGER_GRANT_ACCESS') }}</div>
            <form action class="inp-rad">
              <input
                type="radio"
                name="radio"
                id="x1"
                @click="access_type = true"
                :checked="access_type"
              />
              <label for="x1">{{ $t('ACCESS_MANAGER_FULL_ACCESS') }}</label>
              <input
                type="radio"
                name="radio"
                id="x2"
                @click="access_type = false"
                :checked="!access_type"
              />
              <label for="x2">{{ $t('ACCESS_MANAGER_READ_ONLY') }}</label>
            </form>
            <form action class="inp-btn">
              <input
                type="text"
                class="inp-st"
                placeholder="User' email"
                v-model="admin_email"
              />
              <button class="btn-st-red" type="button" @click="giveAccess">
                {{ $t('ACCESS_MANAGER_GIVE_ACCESS') }}
              </button>
            </form>
          </div>
          <table class="acc-tab">
            <thead>
              <tr>
                <th>{{ $t('ACCESS_MANAGER_EMAIL') }}</th>
                <th>{{ $t('ACCESS_MANAGER_ACCESS') }}</th>
                <th>{{ $t('ACCESS_MANAGER_ACTION') }}</th>
              </tr>
            </thead>
            <tbody :key="index" v-for="(admin, index) in getGrantedAccesses">
              <tr>
                <td>{{ admin.email }}</td>
                <td>
                  <div class="drpdown">
                    <input type="checkbox" name id="z1" :checked="checked" />
                    <label for="z1">{{
                      admin.access_type ? "Full Access" : "Read Only"
                    }}</label>
                    <span class="expand"></span>
                    <ul>
                      <li
                        v-if="!admin.access_type"
                        @click="
                          changeAccess({ email: admin.email, access_type: 1 })
                        "
                      >
                        {{ $t('ACCESS_MANAGER_FULL_ACCESS') }}
                      </li>
                      <li
                        v-if="admin.access_type"
                        @click="
                          changeAccess({ email: admin.email, access_type: 0 })
                        "
                      >
                        {{ $t('ACCESS_MANAGER_READ_ONLY') }}
                      </li>
                    </ul>
                  </div>
                </td>
                <td>
                  <span class="remove" @click="deleteAdmin(admin.email)">{{
                    $t('ACCESS_MANAGER_REMOVE_ACCESS')
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <button
            class="btn-st-viol btn-st-bg"
            @click="INTRO_SET_ACCESS_MANAGER_VISIBLE(false)"
          >
            {{ $t('ACCESS_MANAGER_CLOSE') }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import { INTRO_SET_ACCESS_MANAGER_VISIBLE } from "../../store/actions/intro";
import {
  ADMIN_DELETE,
  ADMIN_SET,
  ADMIN_CHANGE_ACCESS,
} from "../../store/actions/admin";

export default {
  name: "AccessManager",
  computed: {
    ...mapGetters(["getGrantedAccesses"]),
  },

  data() {
    return {
      access_type: false,
      admin_email: undefined,
    };
  },
  mounted() {},
  methods: {
    ...mapActions({
      deleteAdmin: ADMIN_DELETE,
      changeAccess: ADMIN_CHANGE_ACCESS,
    }),
    ...mapMutations([INTRO_SET_ACCESS_MANAGER_VISIBLE]),

    giveAccess() {
      this.$Axios
        .post("create-admin-access", {
          access_type: this.access_type,
          admin_email: this.admin_email,
        })
        .then(() => {
          this.$store.dispatch(ADMIN_SET);
        });
    },
  },
};
</script>
