export const DASH_SET_ACTIVE_TAB_ACTION = 'DASH_SET_ACTIVE_TAB_ACTION'
export const DASH_LOAD_DATA_ACTION = 'DASH_LOAD_DATA_ACTION'
export const DASH_SET_DATA_MUTATION = 'DASH_SET_DATA_MUTATION'
export const DASH_RELOAD_DATA_MUTATION = 'DASH_RELOAD_DATA_MUTATION'
export const DASH_SET_LOADING_MUTATION = 'DASH_SET_LOADING_MUTATION'
export const DASH_CLEAR_STATE = 'DASH_CLEAR_STATE'
export const DASH_SET_POSTS_SORT_TYPE = 'DASH_SET_POSTS_SORT_TYPE'
export const DASH_SET_STORIES_SORT_TYPE = 'DASH_SET_STORIES_SORT_TYPE'
export const DASH_UPDATE_ATTACHES = 'DASH_UPDATE_ATTACHES'
export const DASH_POST_SHOW_TEXT = 'DASH_POST_SHOW_TEXT'
export const DASH_LOAD_STORIES_ATTACHMENTS = 'DASH_LOAD_STORIES_ATTACHMENTS'
export const DASH_SET_PAGINATION_POSTS = 'DASH_SET_PAGINATION_POSTS'