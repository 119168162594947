<template>
  <div
    ref="top"
    v-infinite-scroll="loadMore"
    :infinite-scroll-disabled="busy"
    :infinite-scroll-immediate-check="false"
    :infinite-scroll-listen-for-event="false"
  >
    <Dash-posts-feed-sort-panel :pageSocials="pageSocials" />
    <pagination :total="totalPostsPages" :callback="setPage"></pagination>

    <div class="com-pages posts-compare">
      <template v-for="(page, index) in getAnalizedPages">
        <div
          v-if="paginationPosts(page).length"
          :key="index"
          class="posts-com-feed panel"
        >
          <div>
            <div class="page-caption-posts">
              <img :src="page.owner_avatar" alt />

              <div>
                <p>{{ page.owner_name }}</p>
                <span v-if="page.social == 'FB'">Facebook</span>
                <span v-if="page.social == 'IG'">Instagram</span>
                <span v-if="page.social == 'VK'">VK</span>
              </div>
            </div>
            <ul class="posts">
              <Dash-post-card
                :key="`${post.local_id}${page.owner_id}`"
                :post="post"
                :page="page"
                :index="i"
                v-for="(post, i) in paginationPosts(page)"
              />
            </ul>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import DashPostsFeedSortPanel from "./Dash-posts-feed-sort-panel";
import DashPostCard from "./Dash-posts-feed-card";
import Pagination from "../../../components/Pagination";
import infiniteScroll from "vue-infinite-scroll";
import { DASH_SET_PAGINATION_POSTS } from "../../../store/actions/dash";
export default {
  name: "Dash-posts-feed-many",
  directives: { infiniteScroll },

  computed: {
    ...mapGetters(["getAnalizedPages", "getPostsPageNum"]),
    totalPostsPages() {
      const posts = Math.max(
        ...this.getAnalizedPages.map((p) => p.posts.length)
      );
      return Math.ceil(posts / 10);
    },
    pageSocials() {
      return this.getAnalizedPages.map((item) => ({
        social: item.social,
        isAdmin: item.is_admin,
      }));
    },
  },
  methods: {
    ...mapMutations([DASH_SET_PAGINATION_POSTS]),

    setPage(num) {
      const el = this.$refs.top;
      el.scrollIntoView();

      this.$nextTick(() => {
        this.start = num * 10 - 10;
        this.end = num * 10;
      });
    },
    loadMore() {
      this.busy = true;
      this.end = this.end + 10;
      this.busy = false;
      this.DASH_SET_PAGINATION_POSTS(this.getPostsPageNum + 1);
    },
    paginationPosts(page) {
      return page.posts.slice(this.start, this.end);
    },
  },
  components: {
    DashPostsFeedSortPanel,
    DashPostCard,
    Pagination,
  },
  beforeDestroy() {
    this.DASH_SET_PAGINATION_POSTS(1);
  },
  data() {
    return {
      currentPage: 1,
      busy: false,

      start: 0,
      end: 10,
    };
  },
};
</script>

<style>
</style>